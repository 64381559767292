<app-header-new></app-header-new>
<section class="courselisting-banner contact-banner-section">
    <div class="container">
        <div class="row align-items-center justify-content-between pt-0 mt-lg-5 pt-lg-5 contact-banner">
            <div class="col-md-8 col-lg-5">
                <div class="">
                    <h1 class="text-white"> Reach Out to Us </h1>
                    <p class="text-white fs-6"> Got a moment? We'd love to hear what you think & how we can make your experience even better. </p>
                    <div class="d-flex">
                        <div class="request_btn btn-style-one d-none d-lg-flex" (click)="highlightContactForm()">Enroll Here <i class="bi bi-arrow-right"></i>
                        </div>
                      </div>
                    <div class="star bottom-70">
                        <picture>
                          <source type="image/webp" srcset="assets/images/star.webp">
                          <source type="image/png" srcset="assets/images/star.png">
                          <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                        </picture>
                      </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="schedule-section contactus-section">
    <div class="container">
        <div class="banner-bottom-section position-relative">
            <div class="col col-lg-7 schedule-wrapper row justify-content-end ">
                <div class="star top-70">
                    <picture>
                      <source type="image/webp" srcset="assets/images/star.webp">
                      <source type="image/png" srcset="assets/images/star.png">
                      <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                    </picture>
                </div>
                <div class="col-12 bg-white interested-form" [ngClass]="{'highlight': isFormHighlighted}">
                    <div class="d-flex flex-column">
                        <h2 class="mb-1">Contact us</h2>
                        <p class="p-0 m-0">Share your thoughts and experiences with us</p>
                    </div>
                    <form [formGroup]="contactMail" (ngSubmit)="createContactDetail()"  >
                        <div class="row mt-3 mt-md-4 pt-2 px-0 pb-0 popup-content ">
                            <div class="col-12 col-md-6 mb-3 mb-md-4 popup-input">
                                <input type="text" class="interest-form-control" placeholder="" id="fname" formControlName="first_name" (focus)="onFocus('first_name')" (blur)="onBlur()">
                                <label for="fname">First Name</label>
                                <div *ngIf="contactMail. get('first_name')?.invalid && (contactMail.get ('first_name')?.dirty || contactMail.get('first_name') ?.touched) && focusedField !== 'first_name'" >
                                  <div *ngIf="contactMail.get('first_name') ?.errors?.['required']" class="text-danger">First Name is required.</div>
                                  <div *ngIf="contactMail.get('first_name')?.errors?.['minlength']" class="text-danger">First Name should be more than 3 letters!</div>
                              </div>
                            </div>
                            <div class="col-12 col-md-6 mb-3 mb-md-4 popup-input">
                                <input type="text" class="interest-form-control" placeholder="" id="lname" formControlName="last_name" (focus)="onFocus('last_name')" (blur)="onBlur()">
                                <label for="lname">Last Name</label>
                                <div *ngIf="contactMail. get('last_name')?.invalid && (contactMail.get ('last_name')?.dirty || contactMail.get('last_name') ?.touched) && focusedField !== 'last_name'">
                                  <div *ngIf="contactMail.get('last_name') ?.errors" class="text-danger">Last Name is required.</div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 mb-3 mb-md-4 popup-input">
                                <input type="number" class="interest-form-control" placeholder="" id="phone_number" formControlName="phone_number" (focus)="onFocus('phone_number')" (blur)="onBlur()">

                                <label for="phone_number">Mobile number</label>
                                <div *ngIf="contactMail.get('phone_number')?.invalid && (contactMail.get('phone_number')?.dirty || contactMail.get('phone_number')?.touched) && focusedField !== 'phone_number'">
                                <div *ngIf="contactMail.get('phone_number')?.errors?.['required']" class="text-danger">Phone Number is required.</div>
                                <div *ngIf="contactMail.get('phone_number')?.errors?.['minlength']" class="text-danger">Phone number must be at least 10 digits.</div>
                                <div *ngIf="contactMail.get('phone_number')?.errors?.['maxlength'] && contactMail.get('phone_number')?.value.length > 16" class="text-danger">Phone number must be between 10 and 16 digits.</div>
                                <div *ngIf="contactMail.get('phone_number')?.hasError('pattern') && contactMail.get('phone_number')?.value && contactMail.get('phone_number')?.invalid" class="text-danger">Invalid phone number format.</div>
                              </div>
                            </div>

                            <div class="col-12 col-md-6 mb-3 mb-md-4 popup-input">
                                <input type="email" class="interest-form-control" placeholder="" id="email" formControlName="email" (focus)="onFocus('email')" (blur)="onBlur()">

                                <label for="email">Email address</label>
                                <div *ngIf="contactMail. get('email')?.invalid && (contactMail.get ('email')?.dirty || contactMail.get('email') ?.touched) && focusedField !== 'email'" >
                                  <div *ngIf="contactMail.get('email') ?.hasError('required')" class="text-danger">Email is required.</div>
                                  <div *ngIf="contactMail.get('email')?.hasError('pattern') && contactMail.get('email')?.value && contactMail.get('email')?.invalid" class="text-danger">Invalid email format.</div>
                            </div>
                            </div>
                           
                            <div class="col-12 col-md-6 mb-3 mb-md-4">
                                <label for="select-enquiry" class="fs-0 visually-hidden">Select Enquiry</label>
                                <select id="select-enquiry" class="interest-form-control bg-white text-dark" formControlName="enquiry"  (change)="onSelectChange($event)" (focus)="onFocus('enquiry')" (blur)="onBlur()"
                                [style.color]="selectColor + ' !important'">
                                    <option value="" disabled selected>Select Enquiry</option>
                                    <option value="internship">Internship</option>
                                    <option value="placement"> Placement</option>
                                    <option value="certification course">Certification Course</option>
                                    <option value="others">Others</option>
                                 </select>
                                <div *ngIf="contactMail. get('enquiry')?.invalid && (contactMail.get ('enquiry')?.dirty || contactMail.get('enquiry') ?.touched) && focusedField !== 'enquiry'" >
                                    <div *ngIf="contactMail.get('enquiry') ?.errors" class="text-danger">Enquiry is required.</div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 mb-3 mb-md-4">
                                <label for="select-medium" class="fs-0 visually-hidden">Select Medium </label>
                                <select id="select-medium" class="interest-form-control bg-white text-dark "  formControlName="medium" (change)="onSelectSocial($event)" 
                                [style.color]="selctColorsecondary + ' !important'">
                                    <option value="" disabled selected>Select Medium (Optional)</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="LinkedIn">LinkedIn</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="Twitter">Twitter</option>
                                    <option value="Social media ads">Social media ads</option>
                                    <option value="Refferals">Refferals</option>
                                </select>
                                <!-- <div *ngIf="contactMail. get('medium')?.invalid && (contactMail.get ('medium')?.dirty || contactMail.get('medium') ?.touched)" >
                                    <div *ngIf="contactMail.get('medium') ?.errors" class="text-danger">Medium is required.</div>
                                </div> -->
                            </div>
                            <div class="col-12 mb-4">
                                <textarea rows="5" formControlName="message" placeholder="Enter your message"></textarea>
                            </div>
                            <div class="col-12">
                                <!-- Button initial disable function commented for  now-->
                                <button type="submit" *ngIf="!isProcessing" [disabled]="contactMail.invalid"
                                [ngClass]="{
                                    'opacity-25 cursor-not-allowed': contactMail.invalid ,
                                    'opacity-100': !contactMail.invalid }"  class="border-0">Submit</button>

                                    <!-- <button type="submit" *ngIf="!isProcessing" [disabled]="contactMail.invalid" class="border-0">Submit</button>
                                <button type="submit" *ngIf="isProcessing"  class="border-0">
                                    <svg aria-hidden="true" role="status" width="20px" class="inline  me-1 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                                    </svg> Submit</button> -->

                            </div>
                        </div>
                    </form>
                </div>
                <div class="star bottom-70">
                    <picture>
                      <source type="image/webp" srcset="assets/images/star.webp">
                      <source type="image/png" srcset="assets/images/star.png">
                      <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                    </picture>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="contact-career-section">
    <div class="container">
        <div class="title text-center">
            <h2 class="p-0 mb-2">We help students scale up their <span>Careers.</span></h2>
            <p class="pb-5">We provide students with the opportunity to explore and  succeed, so that they can grow and <br> achieve in their careers & goals. Here are some paths to follow:</p>
        </div>
        <div class="position-relative our-story">
            <div class="star top-70">
                <picture>
                  <source type="image/webp" srcset="assets/images/star.webp">
                  <source type="image/png" srcset="assets/images/star.png">
                  <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                </picture>
              </div>
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4 card d-flex flex-column align-items-center justify-content-center text-center border-0 my-2 my-md-4 py-1">
                    <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/engage.webp">
                    <source type="image/png" srcset="assets/images/course-listing/engage.png">
                    <img loading="lazy" src="assets/images/course-listing/engage.png" width="50" height="50" alt="Lack of awareness" class="img-fluid w-67">
                    </picture>
                    <!-- <img src="assets/images/icon-lack-awareness.png" alt="Lack of awareness" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                    <div class="d-flex flex-column wcalc50">
                    <h3 class="mt-2 py-2">Engage & <span>Participate </span>
                    </h3>
                    <p> We encourage students to take part in sessions, individual projects, class discussions, and so on. </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 card d-flex flex-column align-items-center justify-content-center text-center border-0 my-2 my-md-4 py-1">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/learners-expansion.webp">
                    <source type="image/png" srcset="assets/images/course-listing/learners-expansion.png">
                    <img loading="lazy" src="assets/images/course-listing/learners-expansion.png" width="50" height="50" alt="Knowledge Disconnect" class="img-fluid w-67">
                </picture>
                <!-- <img src="assets/images/icon-knowledge.png" alt="Knowledge Disconnect" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                <div class="d-flex flex-column wcalc50">
                    <h3 class="mt-2 py-2">
                    <span>Learners </span> Expansion
                    </h3>
                    <p> Help learners to grow in all niches of their lives not just academically but socially & emotionally </p>
                </div>
                </div>
                <div class="col-md-6 col-lg-4 card d-flex flex-column align-items-center text-center border-0 my-2 my-md-4 py-1">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/earn-money.webp">
                    <source type="image/png" srcset="assets/images/course-listing/earn-money.png">
                    <img loading="lazy" src="assets/images/course-listing/earn-money.png" width="50" height="50" alt="Learn of Exposure" class="img-fluid w-67">
                </picture>
                <!-- <img src="assets/images/icon-learn-exposure.png" alt="Learn of Exposure" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                <div class="d-flex flex-column wcalc50">
                    <h3 class="mt-2 py-2">Earn <span>Money </span>
                    </h3>
                    <p> Students can earn money in a variety of fields while studying, and we strongly recommend using it. </p>
                </div>
                </div>
            </div>
            <div class="star bottom-70">
                <picture>
                  <source type="image/webp" srcset="assets/images/star.webp">
                  <source type="image/png" srcset="assets/images/star.png">
                  <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                </picture>
              </div>
        </div>
    </div>
</section>

<app-footer-new></app-footer-new>
