import {
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import AOS from 'aos';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from '../services/canonical.service';
import { AlumniService } from '../services/alumni.service';
import { CourseService } from '../services/course.service';
import { EnquiryService } from '../services/enquiry.service';
import { Router } from '@angular/router';
import { HighlightService } from '../services/highlight.service';

@Component({
  selector: 'app-cs-acedemic',
  templateUrl: './cs-acedemic.component.html',
  styleUrls: ['./cs-acedemic.component.css']
})
export class CsAcedemicComponent implements OnInit {

  title = 'Empowering Developers with Cutting-Edge Skills';
  listAlumniValue:any;
  listCourseValue:any;
  isProcessing:boolean=false;
  currentPage=1;
   modalConfig: ModalOptions = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  show: boolean = false;
  fullScreen: boolean = true;
  template = ``;
  items!: GalleryItem[];
  showspinner: boolean = false;
  showspinnerPast: boolean = false;
  public eventsUpcoming: any[] = [];
  public eventsPast: any[] = [];
  focusedField: string | null = null; 


  events_upcoming = [
    {
      previewImg: 'assets/images/excel.png',
      PreviewAlt: 'Excel',
      partnerLogo: 'assets/images/logo-excel.png',
      eventDate: 'Fri, Mar 17, 11:30 AM',
      eventName: 'Decentralized Finance (DeFi)'
    },
    {
      previewImg: 'assets/images/krishna.png',
      PreviewAlt: 'krishna',
      partnerLogo: 'assets/images/logo-krishna.png',
      eventDate: 'Thu, Mar 23, 02:00 PM',
      eventName: 'Getting started with iOS Career'
    },
    {
      previewImg: 'assets/images/jansons.png',
      PreviewAlt: 'jansons',
      partnerLogo: 'assets/images/logo-jansons.png',
      eventDate: 'Tue, Apr 06, 10:30 AM',
      eventName: 'Artificial Intelligence in Testing'
    },
    {
      previewImg: 'assets/images/kg.png',
      PreviewAlt: 'kg',
      partnerLogo: 'assets/images/logo-kg.png',
      eventDate: 'Sat, Apr 15, 11:00 AM',
      eventName: 'Effective Email Marketing Techniques'
    },
    {
      previewImg: 'assets/images/excel.png',
      PreviewAlt: 'excel',
      partnerLogo: 'assets/images/logo-excel.png',
      eventDate: 'Fri, Mar 17, 11:30 AM',
      eventName: 'Decentralized Finance (DeFi)'
    },
    {
      previewImg: 'assets/images/krishna.png',
      PreviewAlt: 'krishna',
      partnerLogo: 'assets/images/logo-krishna.png',
      eventDate: 'Thu, Mar 23, 02:00 PM',
      eventName: 'Getting started with iOS Career'
    }
  ];
  events_pasts = [
    {
      previewImg: 'assets/images/excel.png',
      PreviewAlt: 'Excel',
      partnerLogo: 'assets/images/logo-excel.png',
      eventDate: 'Fri, Mar 17, 11:30 AM',
      eventName: 'Decentralized Finance (DeFi)'
    },
    {
      previewImg: 'assets/images/krishna.png',
      PreviewAlt: 'krishna',
      partnerLogo: 'assets/images/logo-krishna.png',
      eventDate: 'Thu, Mar 23, 02:00 PM',
      eventName: 'Getting started with iOS Career'
    },
    {
      previewImg: 'assets/images/jansons.png',
      PreviewAlt: 'jansons',
      partnerLogo: 'assets/images/logo-jansons.png',
      eventDate: 'Tue, Apr 06, 10:30 AM',
      eventName: 'Artificial Intelligence in Testing'
    },
    {
      previewImg: 'assets/images/kg.png',
      PreviewAlt: 'kg',
      partnerLogo: 'assets/images/logo-kg.png',
      eventDate: 'Sat, Apr 15, 11:00 AM',
      eventName: 'Effective Email Marketing Techniques'
    },
    {
      previewImg: 'assets/images/excel.png',
      PreviewAlt: 'excel',
      partnerLogo: 'assets/images/logo-excel.png',
      eventDate: 'Fri, Mar 17, 11:30 AM',
      eventName: 'Decentralized Finance (DeFi)'
    },
    {
      previewImg: 'assets/images/krishna.png',
      PreviewAlt: 'krishna',
      partnerLogo: 'assets/images/logo-krishna.png',
      eventDate: 'Thu, Mar 23, 02:00 PM',
      eventName: 'Getting started with iOS Career'
    }
  ];

  public contactMail!: FormGroup;
  submitted = false;
  countryCode: any;
  // country_code: any;
  // formdata = [];
    // Store subscription to manage it properly
    private submitFormSubscription: Subscription | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private toastr: ToastrService,
    public gallery: Gallery,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private alumniService:AlumniService,
    private courseService:CourseService,
    private enquiryService:EnquiryService,
    private router:Router,
    private highlightService: HighlightService

  ) { }

  ngOnInit(): void {
    AOS.init();
    this.eventsUpcoming = this.events_upcoming;
    this.contactMail = this.formBuilder.group({
      name: ['', {
        validators: [Validators.required, Validators.minLength(3)],
        updateOn: 'blur'
      }],
      medium: [''],
      email: ['', {
        validators: [Validators.required, Validators.email,Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ )],
        updateOn: 'blur'
      }],
      phone_number: [
        '', {
          validators: [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(16),
            Validators.pattern(/^\d{10,16}$/)
          ],
          updateOn: 'blur'
        }
      ]
      
    });

    this.items = data.map(item =>
      new ImageItem({ src: item.srcUrl, thumb: item.previewUrl })
    );

    // Load items into the lightbox
    this.basicLightboxExample();

    // Load item into different lightbox instance
    // With custom gallery config
    this.withCustomGalleryConfig();


    this.titleService.setTitle(this.title);
  this.meta.updateTag({ name: 'description', content: "Unlock your potential with Codespot Academy. Our comprehensive courses and expert-led training programs are designed to equip developers with the latest skills and knowledge. Join us to advance your career and stay ahead in the tech industry." });
  this.meta.updateTag({ name: 'keywords', content: 'Educational Foundation, Nonprofit Education Organization, Educational Initiatives, Education Support Programs, Learning Opportunities, Education Empowerment, Education Enrichment Programs, Education Awareness, Education and Skills Development, Education and Training Programs, technological skills for students' });
  this.meta.updateTag({ name: 'author', content: 'CodeSpot Foundation | Sparkouttech' });
  this.meta.updateTag({ name: 'robots', content: 'index, follow' });

  this.meta.updateTag({ property: 'og:title', content: 'Empowering Developers with Cutting-Edge Skills' });
  this.meta.updateTag({ property: 'og:site_name', content: 'CodeSpot Foundation | Sparkouttech' });
  this.meta.updateTag({ property: 'og:url', content: 'https://codespotfoundation.org/cs-academy/' });
  this.meta.updateTag({ property: 'og:description', content: "Unlock your potential with Codespot Academy. Our comprehensive courses and expert-led training programs are designed to equip developers with the latest skills and knowledge. Join us to advance your career and stay ahead in the tech industry." });
  this.meta.updateTag({ property: 'og:type', content: "Unlock your potential with Codespot Academy. Our comprehensive courses and expert-led training programs are designed to equip developers with the latest skills and knowledge. Join us to advance your career and stay ahead in the tech industry." });
  this.meta.updateTag({ property: 'og:image', content: 'https://www.codespotfoundation.org/assets/images/codespot-og.png' });

  this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
  this.meta.updateTag({ name: 'twitter:site', content: 'https://codespotfoundation.org/cs-academy/' });
  this.meta.updateTag({ name: 'twitter:title', content: 'Empowering Developers with Cutting-Edge Skills' });
  this.meta.updateTag({ name: 'twitter:description', content: "Unlock your potential with Codespot Academy. Our comprehensive courses and expert-led training programs are designed to equip developers with the latest skills and knowledge. Join us to advance your career and stay ahead in the tech industry." });
  this.meta.updateTag({ name: 'twitter:image', content: 'https://www.codespotfoundation.org/assets/images/codespot-og.png' });

  // Add Canonical tag
  this.canonicalService.setCanonicalURL("https://codespotfoundation.org/cs-academy/");

this.listAlumni();
this.courseListDetail();

  }

  /**
   * Stops video
   */
  public stopVideo() {
    var videos = document.querySelectorAll('iframe, video');
    Array.prototype.forEach.call(videos, function (video) {
      if (video.tagName.toLowerCase() === 'video') {
        video.pause([0]);
      } else {
        var src = video.src;
        video.src = src;
      }
    });
  }

  basicLightboxExample() {
    this.gallery.ref().load(this.items);
  }
  /**
   * Use custom gallery config with the lightbox
   */
  withCustomGalleryConfig() {

    // 2. Get a lightbox gallery ref
    const lightboxGalleryRef = this.gallery.ref('anotherLightbox');

    // (Optional) Set custom gallery config to this lightbox
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // 3. Load the items into the lightbox
    lightboxGalleryRef.load(this.items);
  }

  //easy to access form control value
  get f() {
    return this.contactMail.controls;
  }

  //for focussing the input field while form validation
  onFocus(fieldName: string){
    this.focusedField = fieldName;
  }

  onBlur() {
    this.focusedField = null;
  }

  onSubmit() {
    //console.log(this.contactmail)
    this.submitted = true;
    if (this.contactMail.valid) {
      let formData = {
        ...this.contactMail.value,
        country_code: this.countryCode
      };

       // Unsubscribe if there's a previous subscription to prevent memory leaks
       if (this.submitFormSubscription) {
        this.submitFormSubscription.unsubscribe();
      }
      //console.log(formdata);
      this.submitFormSubscription = this.commonService.submitcontactform(formData).subscribe({
        next: (response: any) => {
          this.submitted = false;
          if ((response.status = ['SUCCESS'])) {
            this.toastr.success('Mail Send Successfully!', 'Success!');
            this.contactMail.reset();
          }
        },
        error: (error: any) => {
          this.submitted = false;
          this.toastr.error('Failed to send mail. Please try again later.','Error!');
        }
    });
    }
  }
  numericOnly(event: any) {
    let pattern = /^([0-9])$/;
    let result = pattern.test(event.key);
    return result;
  }

  ngOnDestroy() {
    // Clean up subscription to prevent memory leaks
    if (this.submitFormSubscription) {
      this.submitFormSubscription.unsubscribe();
    }
  }

  onClickUpcoming() {
    this.showspinner = true;
    this.eventsUpcoming = [];
    setTimeout(() => {
      this.eventsUpcoming = this.events_upcoming.slice();
      this.showspinner = false
    }, 3000);
  }
  onClickPast() {
    this.showspinnerPast = true;
    this.eventsPast = []
    setTimeout(() => {
      this.eventsPast = this.events_pasts.slice();
      this.showspinnerPast = false
    }, 3000);
  }

  //ourAlumni owl carousel
  ourAlumni: OwlOptions = {
    loop: true,
    margin:20,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    autoplay:true,
    nav:false,
    navSpeed:3000,
    // navText: ['<i class="bi bi-arrow-left"></i>', '<i class="bi bi-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items:2
      },
      576: {
        items: 3
      },
      740: {
        items: 4
      },
      940: {
        items: 5
      }
    }
  }

  //ourAwards owl carousel
  ourAwards: OwlOptions = {
    loop: true,
    margin:20,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    autoplay:true,
    nav:false,
    navSpeed:3000,
    // navText: ['<i class="bi bi-arrow-left"></i>', '<i class="bi bi-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 2
      },
      940: {
        items: 3
      }
    }
  }
  
  /**
   * Courses list detail
   */
  public courseListDetail(){
  
    this.courseService.listCourseDetail().subscribe({ 
      next: (response: any) => {
        this.listCourseValue=response.data.docs;
    
      },
      error: (error: any) => {
        console.log(error)
     
      }
    })
  }
  /**
   * Lists alumni
   */
  public listAlumni()
  {
   
   this.alumniService.listAlumni().subscribe({
      next:(response:any)=>{
          this.listAlumniValue=response.data;
                
      },
      error:(error:any)=>{
          console.error('list alumni error',error.error.message);
         
      }
   })
  }
  /**
   * Creates contact detail  
   */
  public createCSContactDetail(){
    this.isProcessing=true;
    const params={
      name: this.contactMail.value.name,
      email: this.contactMail.value.email,
      phone_number: String(this.contactMail.value.phone_number),
     // medium: this.contactMail.value.medium,
     
  
    }
   
    this.enquiryService.createCsContact(params).subscribe({
   next:(response:any)=>{
    if(this.contactMail.invalid)
      {
        return;
      }
    this.isProcessing=false;
    this.toastr.success('Enquiry Sent');
    this.contactMail.reset();
    this.contactMail.patchValue({medium:''},
    )

      },
   
    error:(error:any)=>{
      this.isProcessing=false;
      this.toastr.error('Enquiry Failed');
     
  }
})
   
}
/**
 * Navigates course listing component
 * @param url 
 * @param id 
 */
public navigate(url:string, slug:string):void{
  this.router.navigateByUrl(`${url}/${slug}`)
}
/**
   * Highlights contact form
   */
highlightContactForm() {
  this.highlightService.triggerHighlight();
  this.router.navigate(['/contact-us']);
}
}

const data = [
  {
    srcUrl: 'assets/images/gallery1.png',
    previewUrl: 'assets/images/gallery1.png'
  },
  {
    srcUrl: 'assets/images/gallery2.png',
    previewUrl: 'assets/images/gallery2.png'
  },
  {
    srcUrl: 'assets/images/gallery3.png',
    previewUrl: 'assets/images/gallery3.png'
  },
  {
    srcUrl: 'assets/images/gallery4.png',
    previewUrl: 'assets/images/gallery4.png'
  },
  {
    srcUrl: 'assets/images/gallery5.png',
    previewUrl: 'assets/images/gallery5.png'
  },
  {
    srcUrl: 'assets/images/gallery6.png',
    previewUrl: 'assets/images/gallery6.png'
  },
  {
    srcUrl: 'assets/images/gallery7.png',
    previewUrl: 'assets/images/gallery7.png'
  },
  {
    srcUrl: 'assets/images/gallery8.png',
    previewUrl: 'assets/images/gallery8.png'
  },
  {
    srcUrl: 'assets/images/gallery9.png',
    previewUrl: 'assets/images/gallery9.png'
  }
];
