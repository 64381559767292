
<app-header-new></app-header-new>
<section class="courselisting-banner">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-7">
                <div class="">
                    <div class="star top-70">
                      <picture>
                        <source type="image/webp" srcset="assets/images/star.webp">
                        <source type="image/png" srcset="assets/images/star.png">
                        <img loading="lazy" data-src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                      </picture>
                      <!-- <img src="assets/images/star.png" alt="star" width="35" height="35" class="img-fluid animate-blink" loading="lazy" /> -->
                    </div>
                    <h1 class="text-white"> Unlock your potential with the Foundation Course </h1>
                    <p class="text-white"> Ready to take the first step towards a brighter future? Our Foundation Course helps you identify your potential and pave the way for success. </p>
                    <div class="d-flex">
                      <div class="request_btn btn-style-one" (click)="highlightContactForm()">Enroll Now <i class="bi bi-arrow-right"></i>
                      </div>
                    </div>
                    <div class="star bottom-70">
                        <picture>
                          <source type="image/webp" srcset="assets/images/star.webp">
                          <source type="image/png" srcset="assets/images/star.png">
                          <img loading="lazy" data-src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                        </picture>
                      </div>
                </div>
            </div>
            <div class="col-md-5 text-center">
                <picture>
                    <source type="image/png" srcset="assets/images/course-listing/student.webp">
                    <source type="image/png" srcset="assets/images/course-listing/student.png">
                    <img loading="lazy" data-src="assets/images/course-listing/student.png" width="439" height="479" alt="banner-pattern" class="img-fluid">
                </picture>
            </div>
        </div>
    </div>
</section>
<section class="course-wrap">
    <div class="container">
        <div class="row align-items-end">
            <div class="col-12 col-md-6">
                <div class="title">
                  <div class="h4">Courses</div>
                  <h2 class="p-0"> Accelerate your career</h2>
                </div>
            </div>
            <div class="col-12 col-md-6 position-relative">
                <div class="star top-70">
                    <picture>
                      <source type="image/webp" srcset="assets/images/star.webp">
                      <source type="image/png" srcset="assets/images/star.png">
                      <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                    </picture>
                </div>
                <div class="select-wrap d-flex flex-wrap justify-content-start justify-content-md-end mt-3 mt-md-0">
                    <!-- <select class="form-select" id="sortby" aria-label="Floating label select example">
                        <option>Sort By</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select> -->
                    <!-- <select class="form-select" id="explore" aria-label="Floating label select example">
                        <option>Explore</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select> -->
                    <!--
                    <div class="position-relative course-search">
                        <input type="text" placeholder="Search" class="border-0">
                        <button class="border-0 bg-transparent"><img src="assets/images/select-search.svg" width="14" height="14" alt="select-serch"></button>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="course-section">
            <div class="row" >
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 my-1 my-md-3" *ngFor="let data of listCourseValue">
                    <div (click)="navigate('course-detail', data.slug)" class="cursor-pointer">
                        <picture>
                            <source type="image/webp" [srcset]="data.image_url">
                            <source type="image/jpg" [srcset]="data.image_url_webp">
                            <img loading="lazy" [attr.data-src]="data.image_url_webp" [alt]="data.title" alt="Course Image" width="261" height="144" class="w-100 rounded-top">
                        </picture>
                    </div>
                    <div class="course-part">
                        <span class="d-block">3 Months</span>
                        <h3 (click)="navigate('course-detail', data.slug)" class="cursor-pointer">{{data.title}}</h3>
                        <ul class="p-0 m-0 d-flex  flex-column flex-lg-row justify-content-between">
                            <li class="d-flex align-items-center gap-1">
                                <img loading="lazy" src="assets/images/course-listing/icon-1.svg" alt="Blockchain Translate" width="20" height="20" class="d-inline-block">
                                <p class="p-0">Tamil & English</p>
                            </li>
                            <li class="d-flex align-items-center gap-1">
                                <img src="assets/images/course-listing/icon-2.svg" alt="Blockchain Enrolled" width="20" height="20" class="d-inline-block ">
                                <p class="p-0">{{data.enroll_count}} Enrolled</p>
                            </li>
                        </ul>
                    </div>
                </div>



            </div>
        </div>
    </div>
</section>
<app-footer-new></app-footer-new>
