import { Injectable } from '@angular/core';
import { Observable,BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterserviceService {
  changeLogo:any;

  toggleLogoObservable:Observable<any>;


  constructor() {
    this.changeLogo = new BehaviorSubject(false);
    this.toggleLogoObservable = this.changeLogo.asObservable();

  }
  public toggleLogo(data:boolean){
    this.changeLogo.next(data);
    }

}
