<app-header-new></app-header-new>
<section class="courselisting-banner course-detail-banner">
    <div class="container">
        <div class="mt-md-4 row align-items-center justify-content-between">
            <div class="col-md-9 col-lg-7 course-detail-banner-content">
                <div class="" >
                    <div class="star top-70">
                      <picture>
                        <source type="image/webp" srcset="assets/images/star.webp">
                        <source type="image/png" srcset="assets/images/star.png">
                        <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                      </picture>
                    </div>
                    <span class="d-inline-block">{{listCourseValue?.mode}}</span>
                    <h1 class="text-white"> {{listCourseValue?.title}} </h1>
                    <p class="text-white pe-0"> {{listCourseValue?.description}} </p>
                    <div class="star bottom-70">
                        <picture>
                          <source type="image/webp" srcset="assets/images/star.webp">
                          <source type="image/png" srcset="assets/images/star.png">
                          <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                        </picture>
                      </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="schedule-section">
    <div class="container">
        <div class="banner-bottom-section position-relative" >
            <div class="schedule-wrapper row justify-content-between align-items-end">
                <div class="col-lg-7 position-relative">
                    <div class="schedule-wrap bg-white row">
                        <div class="col-6 col-sm-3 text-center">
                            <p class="fw-semibold p-0 text-truncate">3 Months</p>
                            <span>Course Duration</span>
                        </div>
                        <div class="col-6 col-sm-3 text-center">
                            <p class="fw-semibold p-0 text-truncate">Beginner</p>
                            <span>Skill Level</span>
                        </div>
                        <div class="col-6 col-sm-3 text-center">
                            <p class="fw-semibold p-0 text-truncate">Tam, Eng</p>
                            <span>Language</span>
                        </div>
                        <div class="col-6 col-sm-3 text-center">
                            <p class="fw-semibold p-0 text-truncate"> {{ listCourseValue?.course_schedule[0]  | slice: 0 : 3  }} - {{ listCourseValue?.course_schedule[1] | slice: 0 : 3}}</p>
                            <span>Course Schedule</span>
                        </div>
                    </div>
                    <div class="star top-70">
                        <picture>
                          <source type="image/webp" srcset="assets/images/star.webp">
                          <source type="image/png" srcset="assets/images/star.png">
                          <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                        </picture>
                    </div>
                </div>
                <div class="col-lg-4 bg-white p-3 p-md-4 interested-form " [ngClass]="{'highlight': isFormHighlighted}" id="interested-form">
                    <div class="d-flex flex-column">
                        <h2 class="mb-1">Interested Form</h2>
                        <p class="p-0 m-0">Make your learning strides today!</p>
                    </div>
                    <form [formGroup]="enquiryForm" (ngSubmit)="createCourseEnroll()">
                        <div class="row gap-4 mt-4">
                            <div class="col-12">
                                <input type="text" class="interest-form-control" placeholder="Name" formControlName="name" (focus)="onFocus('name')" (blur)="onBlur()">
                                <div *ngIf="enquiryForm. get('name')?.invalid && (enquiryForm.get ('name')?.dirty || enquiryForm.get('name') ?.touched) && focusedField !== 'name'" >
                                    <div *ngIf="enquiryForm.get('name') ?.errors?.['required']" class="text-danger">Name is required.</div>
                                    <div *ngIf="enquiryForm.get('name')?.errors?.['minlength']" class="text-danger">Name should be more than 3 letters!</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <input type="email" class="interest-form-control" placeholder="Email address" formControlName="email" (focus)="onFocus('email')" (blur)="onBlur()">
                                <div *ngIf="enquiryForm. get('email')?.invalid && (enquiryForm.get ('email')?.dirty || enquiryForm.get('email') ?.touched) && focusedField !== 'email'" >
                                    <div *ngIf="enquiryForm.get('email') ?.hasError('required')" class="text-danger">Email is required.</div>
                                    <div *ngIf="enquiryForm.get('email')?.hasError('pattern') && enquiryForm.get('email')?.value && enquiryForm.get('email')?.invalid" class="text-danger">Invalid email format.</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <input type="number" class="interest-form-control" placeholder="Mobile number" formControlName="phone_number" (focus)="onFocus('phone_number')" (blur)="onBlur()">

                                  <div *ngIf="enquiryForm.get('phone_number')?.invalid && (enquiryForm.get('phone_number')?.dirty || enquiryForm.get('phone_number')?.touched) && focusedField !== 'phone_number'">
                                    <div *ngIf="enquiryForm.get('phone_number')?.errors?.['required']" class="text-danger">Phone Number is required.</div>
                                    <div *ngIf="enquiryForm.get('phone_number')?.errors?.['minlength']" class="text-danger">Phone number must be at least 10 digits.</div>
                                    <div *ngIf="enquiryForm.get('phone_number')?.hasError('pattern') && enquiryForm.get('phone_number')?.value && enquiryForm.get('phone_number')?.invalid"class="text-danger">Invalid phone number format.</div>
                                  </div>
                            </div>
                            <div class="col-12">
                                <label for="select-medium" class="fs-0 visually-hidden">Select Medium </label>
                                <select id="select-medium" class="interest-form-control bg-white " formControlName="medium" (change)="onSelectSocial($event)"                                [style.color]="selctColorsecondary + ' !important'">
                                    <option value="" disabled selected>Select Medium(optional) </option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="LinkedIn">LinkedIn</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="Twitter">Twitter</option>
                                    <option value="Social media ads">Social media ads</option>
                                    <option value="Refferals">Refferals</option>
                                </select>
                                <div *ngIf="enquiryForm. get('medium')?.invalid && (enquiryForm.get ('medium')?.dirty || enquiryForm.get('medium') ?.touched)" >
                                    <div *ngIf="enquiryForm.get('medium') ?.errors" class="text-danger">Medium is required.</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <button *ngIf="!isProcessing" [disabled]="enquiryForm.invalid"
                                [ngClass]="{
                                    'opacity-25 cursor-not-allowed': enquiryForm.invalid ,
                                    'opacity-100': !enquiryForm.invalid }"
                                    class="border-0">Submit</button>

                                    <button type="submit" *ngIf="isProcessing"  class="border-0">
                                        <svg aria-hidden="true" role="status" width="20px" class="inline  me-1 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                                        </svg> Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="course-detail-tabs">
    <div class="container">
        <ul class="d-flex flex-wrap p-0 m-0">
            <li>
              <p  class="a active d-inline-block" (click)="scrollTo('about')">About</p>
            </li>
            <li>
              <p  class="a d-inline-block" (click)="scrollTo('keyhighlights')">Key Highlights</p>
            </li>
            <li>
              <p  class="a d-inline-block" (click)="scrollTo('courseflow')">Course Flow</p>
            </li>
            <li>
                <p  class="a d-inline-block" (click)="scrollTo('testimonials')">Testimonials</p>
            </li>
            <li>
                <p  class="a d-inline-block" (click)="scrollTo('faq')">FAQs</p>
            </li>
        </ul>

    </div>
</section>
<section class="tab-content" id="about">
    <div class="container" >
        <div class="row overview-content" >
            <h3>Overview</h3>
            <p>{{listCourseValue?.overview}}</p>
        </div>
    </div>
    <div class="coursefree-section">
        <div class="container">
            <div class="blk-title">
                <h2>Course Fee Details</h2>
                <p class="p-0" >Affordable course fees with flexible payment options to help you enhance your skills without financial strain. </p>
            </div>
            <div class="coursefree-wrap">
                <div class="row align-items-center justify-content-between">
                    <div class="col-md-8">
                        <h3>Pre-Book your seat by paying just <span>Rs.3,000</span></h3>
                        <ul class="p-0 m-0 border-0" >
                            <li>EMI options available.</li>
                            <li>Prebootcamp fee for Rs 3,000.</li>
                            <li>Get maximum flexibility to learn at your own pace.</li>
                            <li>10 Days refund option available.</li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <div class="ms-lg-4 course-starts">
                            <div class="d-flex flex-column">
                                <span class="d-inline-block">Course starts on</span>
                                <h4 class="fw-semibold p-0">{{listCourseValue?.course_start | date: 'dd-MM-yyyy'}}</h4>
                            </div>
                            <div class="d-flex flex-column">
                                <span>Course Fee</span>

                                
                                <h5>
                                    {{ listCourseValue?.cost | currency }} 
                                    <span>{{ listCourseValue?.actual_fees | currency }}</span>
                                </h5>

                            </div>
                            <div class="enroll-now-btn d-flex">
                                <p
                                (click)="scrollFormTo('interested-form')"
                                 class="request_btn w-100">Enroll Now <i class="bi bi-arrow-right"></i></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="whatwill-section">
        <div class="container" >
            <div class="blk-title">
                <h2>What Will You Learn</h2>
                <p class="p-0" >You'll learn essential skills to thrive in the fast-evolving tech industry.</p>
            </div>
            <div class="row" >
                <div class="col-md-4" *ngFor="let data of listCourseValue?.course_plan">
                    <div class="whatwill-part" >
                        <span class="d-inline-block">{{data?.duration}}</span>
                        <h3>{{data?.heading}}</h3>
                        <ul class="d-flex flex-column p-0 m-0 border-0" >
                            <li *ngFor="let description of data?.description">{{ description }}</li>

                        </ul>
                    </div>
                </div>

            </div>
            <!-- Comment for now; it will be uncommented in the future.
            <div class="col-12 text-center mt-4 pt-2">
                <button class="fw-semibold border-0 download-btn" data-bs-toggle="modal" data-bs-target="#downloadModal"><img loading="lazy" src="../assets/images/course-listing/download.svg" alt="Download" width="24" height="24" class="me-1"> Download Syllabus</button>
            </div> -->
        </div>
    </div>
    <div class="techstacks-section">
        <div class="container">
            <div class="blk-title">
                <h2 >Tech Stacks</h2>
                <p class="p-0" >Master essential tech stacks quickly and build industry-ready skills for tomorrow’s challenges!</p>
            </div>
            <!-- <div class="row">
                <div class="col-lg-12">
                     <div class="techstacks-wrap">
                         <owl-carousel-o [options]="techStacks">
                             <ng-template carouselSlide >
                                 <div class="d-flex flex-column align-items-center techstacks-image">
                                     <picture>
                                         <source type="image/webp" srcset="assets/images/course-listing/javascript.webp">
                                         <source type="image/png" srcset="assets/images/course-listing/javascript.png">
                                         <img loading="lazy" data-src="assets/images/course-listing/javascript.png" alt="Javascript" width="80" height="80" class="img-fluid">
                                     </picture>
                                     <span>Java script</span>
                                 </div>
                             </ng-template>
                             <ng-template carouselSlide >
                                 <div class="d-flex flex-column align-items-center techstacks-image">
                                     <picture>
                                         <source type="image/webp" srcset="assets/images/course-listing/aws.webp">
                                         <source type="image/png" srcset="assets/images/course-listing/aws.png">
                                         <img loading="lazy" data-src="assets/images/course-listing/aws.png" alt="AWS" width="80" height="80" class="img-fluid">
                                     </picture>
                                     <span>AWS</span>
                                 </div>
                             </ng-template>
                             <ng-template carouselSlide >
                                 <div class="d-flex flex-column align-items-center techstacks-image">
                                     <picture>
                                         <source type="image/webp" srcset="assets/images/course-listing/node-js.webp">
                                         <source type="image/png" srcset="assets/images/course-listing/node-js.png">
                                         <img loading="lazy" data-src="assets/images/course-listing/node-js.png" alt="Node js" width="80" height="80" class="img-fluid">
                                     </picture>
                                     <span>Node JS</span>
                                 </div>
                             </ng-template>
                             <ng-template carouselSlide >
                                 <div class="d-flex flex-column align-items-center techstacks-image">
                                     <picture>
                                         <source type="image/webp" srcset="assets/images/course-listing/mysql.webp">
                                         <source type="image/png" srcset="assets/images/course-listing/mysql.png">
                                         <img loading="lazy" data-src="assets/images/course-listing/mysql.png" alt="My SQL" width="80" height="80" class="img-fluid">
                                     </picture>
                                     <span>My SQL</span>
                                 </div>
                             </ng-template>
                             <ng-template carouselSlide >
                                 <div class="d-flex flex-column align-items-center techstacks-image">
                                     <picture>
                                         <source type="image/webp" srcset="assets/images/course-listing/java.webp">
                                         <source type="image/png" srcset="assets/images/course-listing/java.png">
                                         <img loading="lazy" data-src="assets/images/course-listing/java.png" alt="Java" width="80" height="80" class="img-fluid">
                                     </picture>
                                     <span>Java</span>
                                 </div>
                             </ng-template>
                             <ng-template carouselSlide>
                                 <div class="d-flex flex-column align-items-center techstacks-image">
                                     <picture>
                                         <source type="image/webp" srcset="assets/images/course-listing/php.webp">
                                         <source type="image/png" srcset="assets/images/course-listing/php.png">
                                         <img loading="lazy" data-src="assets/images/course-listing/php.png" alt="PHP" width="80" height="80" class="img-fluid">
                                     </picture>
                                     <span>PHP</span>
                                 </div>
                             </ng-template>
                         </owl-carousel-o>
                     </div>
                </div>
            </div> -->

            <!-- tech stack integrated hidden for now -->
            <div class="row">
                <div class="col-lg-12">
                     <div class="techstacks-wrap">
                         <owl-carousel-o [options]="techStacks">
                             <ng-template carouselSlide *ngFor="let techStack of listCourseValue?.teck_stacks">
                                 <div class="d-flex flex-column align-items-center techstacks-image">
                                     <picture>
                                         <source type="image/webp" [srcset]="techStack.image_url" alt="tech stack image">
                                         <source type="image/png" [srcset]="techStack.image_url" alt="tech stack image">
                                         <img loading="lazy" [srcset]="techStack.image_url" alt="tech stack image" width="80" height="80" class="img-fluid">
                                     </picture>
                                     <span>{{techStack.description}}</span>
                                 </div>
                             </ng-template>
                          
                         </owl-carousel-o>
                     </div>
                </div>
             </div>

        </div>
    </div>
</section>

<section class="keyhighlights-section" id="keyhighlights">
    <div class="container">
        <div class="blk-title">
            <h2 id="keyhighlights">Key Highlights</h2>
            <p class="p-0" >Discover the core advantages of our offerings with concise, impactful highlights that make a difference.</p>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 mb-4 keyhighlights-part">
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/expert-instructors.svg" width="66" height="66" alt="Expert Highlights"></span>
                    <b>Expert Instructors</b>
                    <p>Learn technological advancements from industry experts with real-world experience.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-4 keyhighlights-part">
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/job-ready.svg" width="66" height="66" alt="Job Ready Highlights"></span>
                    <b>Job Ready Skills</b>
                    <p>We focus on developing technical and transferable skills to help you land your dream IT job.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-4 keyhighlights-part">
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/career-credential.svg" width="66" height="66" alt="Career Highlights"></span>
                    <b>Career Credential</b>
                    <p>Earn your course completion certificates that are valued and recognised by employers worldwide </p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 keyhighlights-part" >
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/career-support.svg" width="66" height="66" alt="Career Support Highlights"></span>
                    <b>Career Support</b>
                    <p>We provide career guidance with resume & interview prep tips, mock interviews & professional networking.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 keyhighlights-part">
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/affordable-pricing.svg" width="66" height="66" alt="Affordable Highlights"></span>
                    <b>Affordable Pricing</b>
                    <p>Competitive pricing with flexible payment options & 100% refund if not satisfied with the practice session.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 keyhighlights-part">
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/professional-network.svg" width="66" height="66" alt="Professional Highlights"></span>
                    <b>Professional Network</b>
                    <p>We are helping you to access a community of IT professionals networks through various social media. </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="courseflow-section" id="courseflow">
    <div class="container p-md-0">
        <div class="blk-title">
            <h2 id="courseflow">Course Flow</h2>
            <p class="p-0" >Transform your career with our structured course flow—comprehensive modules, hands-on projects, and expert guidance to ensure your success.
            </p>
        </div>
        <div class="row position-relative courseflow-wrap m-0">
            <div class="col-md-4 p-0">
                <div class="d-flex flex-column align-items-center justify-content-center rounded-circle courseflow-part">
                    <span class="d-flex align-items-center justify-content-center rounded-circle">01</span>
                    <img loading="lazy" src="assets/images/course-listing/book.svg" width="53" height="53" alt="Register">
                    <b>Register</b>
                    <p>Register now with Rs 3,000 and start your journey towards mastering essential skills!</p>
                </div>
            </div>
            <div class="col-md-4 p-0">
                <div class="d-flex flex-column align-items-center justify-content-center rounded-circle courseflow-part">
                    <span class="d-flex align-items-center justify-content-center rounded-circle">02</span>
                    <img loading="lazy" src="assets/images/course-listing/activity.svg" width="53" height="53" alt="Foundation">
                    <b>Foundation Course</b>
                    <p>Enhance your skills with the CodeSpot Foundation, Featuring 2  comprehensive sessions.</p>
                </div>
            </div>
            <div class="col-md-4 p-0">
                <div class="d-flex flex-column align-items-center justify-content-center rounded-circle courseflow-part">
                    <span class="d-flex align-items-center justify-content-center rounded-circle">03</span>
                    <img loading="lazy" src="assets/images/course-listing/clipboard.svg" width="53" height="53" alt="Take Assessment">
                    <b>Take Assessment</b>
                    <p>With expert trainers, If Not Selected. Enjoy a 100% immediate refund</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="refundguarantee-section">
    <div class="container">
        <div class="refundguarantee-wrapper">
            <div class="refundguarantee-wrap">
                <div class="col-lg-7 position-relative">
                    <h2 >100% Refund Guarantee</h2>
                    <p >If selected or interested, pay the remaining amount to continue your course. If not, enjoy a 100% immediate refund for complete peace of mind.</p>
                    <div class="d-flex justify-content-lg-start justify-content-center sition-relative">

                        <div class="request_btn btn-style-one cursor-pointer"
                        (click)="scrollFormTo('interested-form')"
                        >Enroll Now <i class="bi bi-arrow-right"></i>

                        </div>
                        <!-- <div class="star top-70">
                            <picture>
                              <source type="image/webp" srcset="../assets/images/star.webp">
                              <source type="image/png" srcset="../assets/images/star.png">
                              <img loading="lazy" src="../assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                            </picture>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="refundguarantee-image col-lg-5 text-center">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/refund-student.webp">
                    <source type="image/png" srcset="assets/images/course-listing/refund-student.png">
                    <img loading="lazy" src="assets/images/course-listing/refund-student.png" width="390" height="400" alt="Refund Guarantee" class="img-fluid">
                </picture>
                <div class="star top-70">
                    <picture>
                      <source type="image/webp" srcset="assets/images/star.webp">
                      <source type="image/png" srcset="assets/images/star.png">
                      <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                    </picture>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="certification-section">
    <div class="container">
        <div class="blk-title">
            <h2 >Certification</h2>
            <p class="p-0" >Boost your career with industry-recognized certification courses designed for real-world success!</p>
        </div>
        <div class="row align-items-center justify-content-between">
            <div class="col-md-6 col-lg-7">
                <ul class="p-0 m-0">
                    <li class="d-flex" >
                        <span><img loading="lazy" src="assets/images/course-listing/industry-certification.svg" alt="Industry Certification" width="32" height="32"></span>
                        <div class="d-flex flex-column ">
                            <b>Industry-Acclaimed Certification</b>
                            <p>Enhance your credibility and distinguish yourself among peers and industry professionals.</p>
                        </div>
                    </li>
                    <li class="d-flex" >
                        <span><img loading="lazy" src="assets/images/course-listing/easily-share.svg" alt="Easily Shareable" width="32" height="32"></span>
                        <div class="d-flex flex-column ">
                            <b>Easily Shareable</b>
                            <p>Add this credential to your LinkedIn profile, resume or CV, Share it on social media and in your performance review.</p>
                        </div>
                    </li>
                    <li class="d-flex">
                        <span><img loading="lazy" src="assets/images/course-listing/alumni-status.svg" alt="Alumni Status" width="32" height="32"></span>
                        <div class="d-flex flex-column ">
                            <b>Alumni Status</b>
                            <p>Forge lasting connections with a global network of tech experts and experienced peers.</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-md-6 col-lg-5 text-center">
                <picture>
                    <source type="image/webp" srcset="assets/images/certificates.webp">
                    <source type="image/png" srcset="assets/images/certificates.png">
                    <img loading="lazy" src="assets/images/certificates.webp" alt="Certification" width="451" height="319" class="w-100 img-fluid">
                </picture>
            </div>
        </div>
    </div>
</section>
<section class="ouralumni-section">
    <div class="container">
        <div class="blk-title">
            <h2>Alumini</h2>
            <p class="p-0">Our former attendees, now part of our alumni network, are making significant strides in the tech industry, shaping the future with their expertise and innovation.</p>
        </div>
        <div class="row">
            <!-- <owl-carousel-o [options]="ourAlumni"> -->
                <div class="scroller__wrapper">
                    <div class="scroller__container">
                      <div class="scroller">
                          <div class="scroller__item" carouselSlide *ngFor="let data of listAlumniValue" width="200">
                                <div class="col-12" >
                                    <div class="alumni-part" >
                                        <img loading="lazy" [srcset]="data.profile_url"  alt="Microsoft Alumni" width="90" height="90" class="rounded-circle">
                                        <p class="fw-semibold p-0">{{data.name}}</p>
                                        <span class="d-block">{{data.role}}</span>
                                        <img loading="lazy" [srcset]="data.company_url"  alt="Microsoft" width="95" height="23" class="company-logo">
                                    </div>
                                </div>
                          </div>
                        </div>
                    </div>
                </div>
            <!-- </owl-carousel-o> -->
        </div>
    </div>
</section>
<section class="course-detail-testimonials" id="testimonials">
    <div class="container">
        <div class="blk-title">
            <h2 id="testimonials">What Our Students Say About Us</h2>
            <p class="p-0">Our students rave about the transformative learning experience and the impactful career opportunities we provide!
            </p>
        </div>
        <div class="video-testimonials">
            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide *ngFor="let data of listTestimonyValue; let i= index">

                <!-- <p>{{data.videos}}</p> -->
                 <div  (click)="openModal(data.videos)" >
                    <div class="testimonial-wrap">
                      <!-- <div class="overlay"></div> -->
                      <figure class="thumbnail-img"><img [src]="data?.thumbnail" width="667" height="375"
                          class="img-fluid" alt="testimonials" loading="lazy" /></figure>

                      <div class="video-play">
                        <picture>
                          <source type="image/webp" srcset="assets/images/play-icon.webp">
                          <source type="image/svg" srcset="assets/images/play-icon.svg">
                          <img src="assets/images/play-icon.svg" width="200" height="200" alt="play"
                            class="img-fluid w-48 d-inline-block" loading="lazy">
                        </picture>
                      </div>
                      
                    </div>
                  </div>

              </ng-template>
            </owl-carousel-o>

        </div>
    </div>
</section>

<section class="faq-section" >
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-md-5 col-lg-4">
                <div class="faq-title">
                    <h2 id="faq">Frequently Asked Questions</h2>
                    <p>Our comprehensive services and mind-blowing designers dedicate.</p>
                    <div>
                        <div  (click)="scrollFormTo('interested-form')" class="a fw-semibold">Enquire Now <img loading="lazy" src="assets/images/course-listing/enquire-arrow-up.svg" alt="Enquire Arrow" width="25" height="25"></div>

                    </div>
                </div>
            </div>
            <div class="col-md-7">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Who all are eligible for the course?
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <p>College students, Passed out freshers and Working Professionals are eligible to take part in this course.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            How do I enroll in the course?
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <p>You can enroll by visiting our website, selecting the certification course, and completing the registration process.
                        </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            What are the prerequisites for this course?
                        </button>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p>Basic knowledge of the course you’re planning to enroll in is recommended but not required. The course begins with fundamental concepts and progresses to more advanced topics.
                            </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            How is the course structured?
                          </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                              <p>The course is divided into 5 modules, each focusing on a specific aspect of the topics and it
                                includes tasks and assessments.</p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFive">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            How can I contact customer support?
                          </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                              <p>You can contact our HR team via email or phone, and they will be happy to assist you.
                            </p>
                          </div>
                        </div>
                      </div>
                  </div>
            </div>
        </div>
    </div>
</section>
   <!--video popup-->
   <div class="modal" [ngClass]="showModal ? 'openModal' : 'hidden'" id="dataid" tabindex="-1" aria-labelledby="dataVideosLabel" aria-hidden="true"  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body" >
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="stopVideo(currentVideoId)" >
          </button>
          <iframe  [src]="currentUrl | safe" id="videoYT" width="100%" height="450" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

        </div>
      </div>
    </div>
    </div>
    <!--end-->


<!-- Download-Modal -->
<div class="modal fade" id="downloadModal" tabindex="-1" aria-labelledby="downloadModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="row m-0">
            <div class="col-md-5 d-flex align-items-center justify-content-center modal-image">
                <img loading="lazy" src="assets/images/course-listing/education.svg" alt="Download Education" width="258" height="314" class="img-fluid">
            </div>
            <div class="col-md-7 popup-content">
                <div *ngIf="!syllabusOtPFormShow">
                    <button type="button" id="downloadSyllabus" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <h2>Complete the Form to <br> Download the <span>Syllabus!</span></h2>
                    <p>Explore all available courses and curriculum</p>
                    <form [formGroup]="downloadSyllabusForm" (ngSubmit)="createDownloadSyllabus()">
                        <div class="col-12 popup-input">
                            <input type="text"  id="name" formControlName="name">
                            <label for="name">Name</label>
                            <div *ngIf="downloadSyllabusForm. get('name')?.invalid && (downloadSyllabusForm.get ('name')?.dirty || downloadSyllabusForm.get('name') ?.touched)" >
                                <div *ngIf="downloadSyllabusForm.get('name') ?.errors" class="text-danger">Name is required.</div>
                        </div>
                        </div>
                        <div class="col-12 popup-input">
                            <input type="email" id="inputField" formControlName="email">
                            <label for="inputField">Email Address</label>
                            <div *ngIf="downloadSyllabusForm. get('email')?.invalid && (downloadSyllabusForm.get ('email')?.dirty || downloadSyllabusForm.get('email') ?.touched)" >
                                <div *ngIf="downloadSyllabusForm.get('email') ?.errors" class="text-danger">Email is required.</div>
                        </div>
                        </div>

                        <div class="col-12 popup-select mb-0">
                            <select class="border-0 form-select">
                                <option>91</option>
                                <option>913</option>
                                <option>9134</option>
                            </select>
                            <input type="number" placeholder="Mobile number" id="inputField" inputmode="numeric" formControlName="phone_number" class="border-0">
                        </div>
                        <div *ngIf="downloadSyllabusForm. get('phone_number')?.invalid && (downloadSyllabusForm.get ('phone_number')?.dirty || downloadSyllabusForm.get('phone_number') ?.touched) && downloadSyllabusForm.get('phone_number') ?.errors" class="text-danger">Phone Number is required.</div>

                        <div class="col-12 mt-3">
                            <button type="submit" *ngIf="!isProcessing" [disabled]="downloadSyllabusForm.invalid"
                            [ngClass]="{
                                'opacity-25 cursor-not-allowed': downloadSyllabusForm.invalid ,
                                'opacity-100': !downloadSyllabusForm.invalid}"
                            class="fw-semibold border-0 getotp-btn">Get OTP</button>

                            <button type="submit" *ngIf="isProcessing"  class="fw-semibold border-0 getotp-btn">
                                <svg aria-hidden="true" role="status" width="20px" class="inline me-1 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                                </svg> Get OTP</button>


                        </div>
                    </form>
                </div>

                <div class="otp-edit" *ngIf="syllabusOtPFormShow">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <h2>Verify your number <br> to download <span>Syllabus!</span></h2>
                    <p class="mb-md-2 pb-3">Explore all available courses and curriculum</p>
                    <h3>OTP was sent to</h3>
                    <form [formGroup]="syllabusOtpForm" (ngSubmit)="getVerifyOtp(data?._id)">
                        <div class="d-flex flex-column flex-sm-row justify-content-between align-items-md-center mb-2 pb-1">
                            <h4>{{downloadSyllabusValue?.email}}</h4>
                            <!-- <a href="#" class="cursor-pointer">Edit</a> -->
                        </div>
                        <div class="col-12 mb-2 popup-input">
                            <input type="text" placeholder="" id="otp" formControlName="otp">
                            <label for="otp">Enter OTP</label>
                            <div *ngIf="syllabusOtpForm. get('otp')?.invalid && (syllabusOtpForm.get ('otp')?.dirty || syllabusOtpForm.get('otp') ?.touched)" >
                                <div *ngIf="syllabusOtpForm.get('otp') ?.errors" class="text-danger">Otp is required.</div>
                        </div>
                        </div>
                        <span class="d-block mb-3 pb-3 mb-md-4 pb-md-5" *ngIf="resendButton"><span (click)="resendOtp()" class="cursor-pointer">Retry</span></span>
                        <!-- <div class="timer-container">
                            <span class="timer">{{ timerText }}</span>
                            <button (click)="resendOtp()" [disabled]="isButtonDisabled" [ngClass]="{'enabled': !isButtonDisabled}" class="resend-button">
                              Resend OTP
                            </button>
                          </div> -->
                          <div class="timer-container" *ngIf="!resendButton">
                            <span class="timer" [innerHTML]="timerText"></span>
                          </div>

                        <div class="col-12 ">
                            <button  *ngIf="!isProcessing" class="fw-semibold border-0 getotp-btn"
                            [ngClass]="{
                                'opacity-25 cursor-not-allowed': syllabusOtpForm.invalid ,
                                'opacity-100': !syllabusOtpForm.invalid}" >Download Syllabus</button>

                                <button  *ngIf="isProcessing && downloadSyllabusForm" class="fw-semibold border-0 getotp-btn">
                                  <svg aria-hidden="true" role="status" width="20px" class="inline me-1 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                                </svg>
                                Download Syllabus</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>



<app-footer-new></app-footer-new>
