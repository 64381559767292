
<app-header></app-header>
<section class="main">
  <section class="banner-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 mb-3">
          <div class="banner_wrap" data-aos="fade-up">
            <div class="star top-70">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <source type="image/png" srcset="assets/images/star.png">
                <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
              </picture>
              <!-- <img src="assets/images/star.png" alt="star" width="35" height="35" class="img-fluid animate-blink" loading="lazy" /> -->
            </div>
            <h1> Empowering <br />
              <span>Young Minds</span> to Grow
            </h1>
            <p> We provide an equitable education to all students and work towards acquiring skills & knowledge that
              will prepare them for the future in technology. </p>
            <div class="d-flex">
              <a class="request_btn btn-style-one" href="#contact">Join Us <i class="bi bi-arrow-right"></i>
              </a>
              <!-- <a class="request_btn btn-style-two" href="#">See Our Podcasts <i class="bi bi-play-circle"></i>
              </a> -->
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="banner_images animate-float">
            <div class="banner-card">
              <div class="star left">
                <picture>
                  <source type="image/webp" srcset="assets/images/star.webp">
                  <source type="image/png" srcset="assets/images/star.png">
                  <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                </picture>
              </div>
              <div class="star bottom">
                <picture>
                  <source type="image/webp" srcset="assets/images/star.webp">
                  <source type="image/png" srcset="assets/images/star.png">
                  <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                </picture>
              </div>
              <div class="pattern bottom">
                <picture>
                  <source type="image/webp" srcset="assets/images/banner-pattern.webp">
                  <source type="image/png" srcset="assets/images/banner-pattern.png">
                  <img loading="lazy" src="assets/images/banner-pattern.png" width="79" height="46" alt="banner-pattern" class="img-fluid">
                </picture>
                <!-- <img src="assets/images/banner-pattern.png" alt="banner-pattern" class="img-fluid" loading="lazy" /> -->
              </div>
              <picture>
                <source type="image/webp" srcset="assets/images/banner1.webp">
                <source type="image/png" srcset="assets/images/banner1.png">
                <img loading="lazy" src="assets/images/banner1.png" width="213" height="212" alt="banner1" class="img-fluid">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets/images/banner3.webp">
                <source type="image/png" srcset="assets/images/banner3.png">
                <img loading="lazy" src="assets/images/banner3.png" width="213" height="235" alt="banner3" class="img-fluid">
              </picture>
              <!-- <img src="assets/images/banner1.png" alt="banner" class="img-fluid" loading="lazy" />
              <img src="assets/images/banner3.png" alt="banner" class="img-fluid" loading="lazy" /> -->
            </div>
            <div class="banner-card ptop100">
              <div class="star right">
                <picture>
                  <source type="image/webp" srcset="assets/images/star.webp">
                  <source type="image/png" srcset="assets/images/star.png">
                  <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                </picture>
              </div>
              <div class="pattern right">
                <picture>
                  <source type="image/webp" srcset="assets/images/banner-pattern.webp">
                  <source type="image/png" srcset="assets/images/banner-pattern.png">
                  <img loading="lazy" src="assets/images/banner-pattern.png" width="79" height="46" alt="banner-pattern" class="img-fluid">
                </picture>
                <!-- <img src="assets/images/banner-pattern.png" alt="banner-pattern" class="img-fluid" loading="lazy" /> -->
              </div>
              <picture>
                <source type="image/webp" srcset="assets/images/banner2.webp">
                <source type="image/png" srcset="assets/images/banner2.png">
                <img loading="lazy" src="assets/images/banner2.png" width="213" height="236" alt="banner2" class="img-fluid">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets/images/banner4.webp">
                <source type="image/png" srcset="assets/images/banner4.png">
                <img loading="lazy" src="assets/images/banner4.png" width="213" height="234" alt="banner4" class="img-fluid">
              </picture>
              <!-- <img src="assets/images/banner2.png" alt="banner" class="img-fluid" loading="lazy" />
              <img src="assets/images/banner4.png" alt="banner" class="img-fluid" loading="lazy" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="our-story pt-150" id="our-story">
    <div class="container">
      <div class="row">
        <div class="col-md-6 sm-pb-40">
          <div class="title">
            <div class="h4" data-aos="fade-up">Our Story</div>
            <h2 data-aos="fade-up" data-aos-delay="300"> A Thought For <span>Change <br /> & Inclusion </span>
            </h2>
            <p data-aos="fade-up" data-aos-delay="600"> Our journey with CodeSpot Foundation started early on, when we
              were searching for talent. We noticed a disconnect when we had to train freshers who we hired for various
              domains. We understood that students find it hard to be employable due to the lack of awareness about the
              IT Industry. Our initiative took birth from a desire to change this disconnect. </p>
          </div>
          <!-- <div class="d-flex" data-aos="fade-up" data-aos-delay="900"><a class="request_btn btn-style-one" href="#"
              >Our Journey
              <i class="bi bi-arrow-right"></i></a><a class="request_btn btn-style-two" href="#"
              >See Our Podcasts <i class="bi bi-play-circle"></i
            ></a></div> -->
        </div>
        <div class="col-md-6">
          <div class="card d-flex flex-row align-items-center" data-aos="fade-up" data-aos-delay="300">
            <picture>
              <!-- <source type="image/webp" srcset="assets/images/icon-lack-awareness.webp">
              <source type="image/png" srcset="assets/images/icon-lack-awareness.png"> -->
              <img loading="lazy" src="assets/images/icon-lack-awareness.svg" width="50" height="50" alt="Lack of awareness" class="img-fluid w-67 d-flex">
            </picture>
            <!-- <img src="assets/images/icon-lack-awareness.png" alt="Lack of awareness" class="img-fluid w-67 d-flex" loading="lazy" /> -->
            <div class="d-flex flex-column pl-25 wcalc50">
              <h3>Lack of <span>awareness </span>
              </h3>
              <p> India is diverse and tech awareness still hasn’t trickled down to the grass-root level. </p>
            </div>
          </div>
          <div class="card d-flex flex-row align-items-center" data-aos="fade-up" data-aos-delay="600">
            <picture>
              <!-- <source type="image/webp" srcset="assets/images/icon-knowledge.webp">
              <source type="image/png" srcset="assets/images/icon-knowledge.png"> -->
              <img loading="lazy" src="assets/images/icon-knowledge.svg" width="50" height="50" alt="Knowledge Disconnect" class="img-fluid w-67 d-flex">
            </picture>
            <!-- <img src="assets/images/icon-knowledge.png" alt="Knowledge Disconnect" class="img-fluid w-67 d-flex" loading="lazy" /> -->
            <div class="d-flex flex-column pl-25 wcalc50">
              <h3>
                <span>Knowledge </span> Disconnect
              </h3>
              <p> Students cannot learn contemporary skills through school curriculums alone. </p>
            </div>
          </div>
          <div class="card d-flex flex-row align-items-center" data-aos="fade-up" data-aos-delay="900">
            <picture>
              <!-- <source type="image/webp" srcset="assets/images/icon-learn-exposure.webp">
              <source type="image/png" srcset="assets/images/icon-learn-exposure.png"> -->
              <img loading="lazy" src="assets/images/icon-learn-exposure.svg" width="50" height="50" alt="Learn of Exposure" class="img-fluid w-67 d-flex">
            </picture>
            <!-- <img src="assets/images/icon-learn-exposure.png" alt="Learn of Exposure" class="img-fluid w-67 d-flex" loading="lazy" /> -->
            <div class="d-flex flex-column pl-25 wcalc50">
              <h3>
                <span>Learn </span> of Exposure
              </h3>
              <p> Students do not have exposure to industry standards, organizational structures, and domains. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="grow-student-careers text-center pt-150"><div class="container"><div class="row"><div class="col-sm-12"><div class="title d-inline-block"><h2 data-aos="fade-up" data-aos-delay="0">
              We help students scale up their  <span>careers.</span></h2><p class="text-center" data-aos="fade-up" data-aos-delay="300">
              We provide students with the opportunity to explore and succeed,
              so that they can grow and achieve <br />
              in their careers & goals. Here are some paths to follow:
            </p><div class="star left-bottom"><img
                src="assets/images/star.png"
                alt="star"
                class="img-fluid animate-blink"
              /></div></div></div></div><div class="row"><div class="col-md-4"><div class="card" data-aos="fade-up" data-aos-delay="0"><img
              src="assets/images/icon-teach.svg"
              alt="teach"
              class="img-fluid w-67 m-auto"
            /><h4>Engage & <span>participate</span></h4><p>
              We encourage students to take part in sessions, individual projects, class discussions, and so on.
 </p></div></div><div class="col-md-4"><div class="card" data-aos="fade-up" data-aos-delay="300"><img
              src="assets/images/icon-growth.svg"
              alt="growth"
              class="img-fluid w-67 m-auto"
            /><h4>Learners <span>expansion</span></h4><p>
              Help learners to grow in all niches of their lives not just academically but socially & emotionally
</p></div></div><div class="col-md-4"><div class="card" data-aos="fade-up" data-aos-delay="600"><img
              src="assets/images/icon-money.svg"
              alt="money"
              class="img-fluid w-67 m-auto"
            /><h4>Earn <span>money</span></h4><p>
              Students can earn money in a variety of fields while studying, and we strongly recommend using it.

            </p></div></div></div></div></section> -->
  <section class="mission-vision pt-150">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <figure class="sticky" data-aos="fade-up">
            <picture>
              <source type="image/webp" srcset="assets/images/mission.webp">
              <source type="image/png" srcset="assets/images/mission.png">
              <img loading="lazy" src="assets/images/mission.png" width="551" height="432" alt="mission" class="img-fluid">
            </picture>
            <!-- <img src="assets/images/mission.png" alt="mission" class="img-fluid" loading="lazy" /> -->
          </figure>
        </div>
        <div class="col-md-6">
          <div class="mission">
            <div class="title">
              <div class="h4" data-aos="fade-up">Our Mission</div>
              <h2 data-aos="fade-up" data-aos-delay="300"> Your <span>destination</span> for <br /> success &
                <span>creativity!</span>
              </h2>
            </div>
            <p data-aos="fade-up" data-aos-delay="600"> To bridge the gap between industry skills and education for 53%
              of Indian graduates who don’t have access to quality resources. </p>
            <p data-aos="fade-up" data-aos-delay="600"> To build a thriving talent pool in India for businesses to scale
              and grow. </p>
            <p data-aos="fade-up" data-aos-delay="600"> To contribute to India’s rapid development in education,
              technology, and infrastructure through universal inclusion. </p>
            <p data-aos="fade-up" data-aos-delay="600"> To accelerate this vision through Tamil Nadu, utilizing the
              state’s rich educational infrastructure. </p>
          </div>
          <div class="mission vision">
            <div class="title" data-aos="fade-up" data-aos-delay="300">
              <div class="h4">Our Vision</div>
              <h2>To empower minds that shape <span>the future</span>
              </h2>
            </div>
            <p data-aos="fade-up" data-aos-delay="600"> Empowering students early by collaborating with various
              educational institutions in India, starting with Tamil Nadu. </p>
            <p data-aos="fade-up" data-aos-delay="600"> Providing opportunities for hands-on industry training &
              placements. </p>
            <p data-aos="fade-up" data-aos-delay="600"> Inspire a generation of youngsters to strive for more. </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="programs pt-150" id="courses">
    <div class="container">
      <div class="row">
        <div class="col-md-6 sm-pb-40">
          <div class="title">
            <div class="h4" data-aos="fade-up">Courses</div>
            <h2 data-aos="fade-up" data-aos-delay="300"> Elevate Your Skills <br /> to the <span>Next Level</span>
            </h2>
            <div class="star right-bottom">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <source type="image/png" srcset="assets/images/star.png">
                <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
              </picture>
            </div>
          </div>
          <p data-aos="fade-up" data-aos-delay="600"> We offer a lot of sessions, seminars & workshops to students that
            can be a valuable part of their education as they can gain a deeper understanding of the subject matter,
            develop new skills, gain exposure to different perspectives & access resources that can supplement their
            learning. </p>
          <p data-aos="fade-up" data-aos-delay="600"> This is a convenient learning objective planned for students from
            all backgrounds. </p>
          <p class="pb-54" data-aos="fade-up" data-aos-delay="600"> Students open new opportunities & gain a competitive
            edge in their choosen field. </p>
          <div class="d-flex" data-aos="fade-up" data-aos-delay="900">
            <a class="request_btn btn-style-one" href="#">See All Courses <i class="bi bi-arrow-right"></i>
            </a>
            <!-- <a class="request_btn btn-style-two" href="#">See Our Podcasts <i class="bi bi-play-circle"></i>
            </a> -->
          </div>
        </div>
        <div class="col-md-6">
          <figure data-aos="fade-up" data-aos-delay="300">
            <picture>
              <source type="image/webp" srcset="assets/images/programs.webp">
              <source type="image/png" srcset="assets/images/programs.png">
              <img loading="lazy" src="assets/images/programs.png" width="547" height="575" alt="mission-bg" class="img-fluid">
            </picture>
            <!-- <img src="assets/images/programs.png" alt="mission-bg" class="img-fluid" loading="lazy" /> -->
          </figure>
        </div>
      </div>
    </div>
  </section>
  <!--video section-->
  <section class="pt-150" id="see-our-podcasts">
    <div class="container">
       <div class="row">
        <div class="col-sm-12 text-center">
          <div class="title">
            <h2 class="pb-6" data-aos="fade-up" data-aos-delay="100">Inspiring Testimonials From <span>Our Lads</span>
            </h2>
            <p class="text-center" data-aos="fade-up" data-aos-delay="200"> The motivation that we look up every day to seek and explore, here is the voice of our trainees, <br>sharing their experience at Codespot. </p>
            <div class="star left-bottom">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <source type="image/png" srcset="assets/images/star.png">
                <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
              </picture>
            </div>
            <div class="star right-top">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <source type="image/png" srcset="assets/images/star.png">
                <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
              </picture>
            </div>
          </div>
        </div>
      </div>

    </div>
     <div class="video-testimonials ">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal11">
            <div class="testimonial-wrap">
              <!-- <div class="overlay"></div> -->
              <figure><img src="https://img.youtube.com/vi/PAs2C3ZAVmY/maxresdefault.jpg" width="667" height="375" class="img-fluid" alt="testimonials" loading="lazy"/></figure>
              <div class="video-play">
                <picture>
                  <source type="image/webp" srcset="assets/images/play-icon.webp">
                  <source type="image/svg" srcset="assets/images/play-icon.svg">
                  <img loading="lazy" src="assets/images/play-icon.svg" width="200" height="200" alt="play" class="img-fluid w-48 d-inline-block">
                </picture>
                  <!-- <img src="assets/images/play-icon.svg" class="w-48 d-inline-block" alt="play" loading="lazy"/> -->
              </div>
              <div class="d-flex justify-content-between testimonial-caption">
                <div class="d-flex">
                    <div class="d-flex flex-column text-left">
                      <div class="h4">Gokul</div>
                      <div class="h6">Angular Team</div>
                    </div>
                </div>
                <figure class="d-flex ml-auto">
                  <picture>
                    <source type="image/webp" srcset="assets/images/quote.webp">
                    <source type="image/png" srcset="assets/images/quote.png">
                    <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="teach" class="img-fluid w-32">
                  </picture>
                </figure>
              </div>
            </div>
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal10">
            <div class="testimonial-wrap">
              <!-- <div class="overlay"></div> -->
              <figure><img src="https://img.youtube.com/vi/cmBq8rc9h6Y/maxresdefault.jpg" width="667" height="375" class="img-fluid" alt="testimonials" loading="lazy"/></figure>
              <div class="video-play">
                <picture>
                  <source type="image/webp" srcset="assets/images/play-icon.webp">
                  <source type="image/svg" srcset="assets/images/play-icon.svg">
                  <img loading="lazy" src="assets/images/play-icon.svg" width="200" height="200" alt="play" class="img-fluid w-48 d-inline-block">
                </picture>
              </div>
              <div class="d-flex justify-content-between testimonial-caption">
                <div class="d-flex">
                    <div class="d-flex flex-column text-left">
                      <div class="h4">Jeevanantham</div>
                      <div class="h6">Python Team</div>
                    </div>
                </div>
                <figure class="d-flex ml-auto">
                  <picture>
                    <source type="image/webp" srcset="assets/images/quote.webp">
                    <source type="image/png" srcset="assets/images/quote.png">
                    <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="teach" class="img-fluid w-32">
                  </picture></figure>
              </div>
            </div>
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">
            <div class="testimonial-wrap">
              <!-- <div class="overlay"></div> -->
              <figure><img src="https://img.youtube.com/vi/d-iPO-DmXCU/maxresdefault.jpg" width="667" height="375" class="img-fluid" alt="testimonials" loading="lazy"/></figure>
              <div class="video-play">
                <picture>
                  <source type="image/webp" srcset="assets/images/play-icon.webp">
                  <source type="image/svg" srcset="assets/images/play-icon.svg">
                  <img loading="lazy" src="assets/images/play-icon.svg" width="200" height="200" alt="play" class="img-fluid w-48 d-inline-block">
                </picture>
              </div>
              <div class="d-flex justify-content-between testimonial-caption">
                  <div class="d-flex">
                    <div class="d-flex flex-column text-left">
                        <div class="h4">Chandrikha</div>
                        <div class="h6">HR Department</div>
                    </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="teach" class="img-fluid w-32">
                    </picture></figure>
              </div>
            </div>
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal1">
            <div class="testimonial-wrap">
                <!-- <div class="overlay"></div> -->
                <figure><img src="https://img.youtube.com/vi/gsjERkyDrm8/maxresdefault.jpg" width="667" height="375" class="img-fluid" alt="testimonials" loading="lazy"/></figure>
                <div class="video-play">
                  <picture>
                    <source type="image/webp" srcset="assets/images/play-icon.webp">
                    <source type="image/svg" srcset="assets/images/play-icon.svg">
                    <img loading="lazy" src="assets/images/play-icon.svg" width="200" height="200" alt="play" class="img-fluid w-48 d-inline-block">
                  </picture>
                </div>
                <div class="d-flex justify-content-between testimonial-caption">
                  <div class="d-flex">
                      <div class="d-flex flex-column text-left">
                        <div class="h4">Naveen Kumar</div>
                        <div class="h6">Android Team</div>
                      </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="teach" class="img-fluid w-32">
                    </picture></figure>
                </div>
            </div>
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal2">
            <div class="testimonial-wrap">
              <!-- <div class="overlay"></div> -->
                <figure><img src="https://img.youtube.com/vi/78NdhmKybHk/maxresdefault.jpg" width="667" height="375" class="img-fluid" alt="testimonials" loading="lazy"/></figure>
                <div class="video-play">
                  <picture>
                    <source type="image/webp" srcset="assets/images/play-icon.webp">
                    <source type="image/svg" srcset="assets/images/play-icon.svg">
                    <img loading="lazy" src="assets/images/play-icon.svg" width="200" height="200" alt="play" class="img-fluid w-48 d-inline-block">
                  </picture>
                </div>
                <div class="d-flex justify-content-between testimonial-caption">
                  <div class="d-flex">
                      <div class="d-flex flex-column text-left">
                        <div class="h4">Jaswanth</div>
                        <div class="h6">UI/UX Team</div>
                      </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="teach" class="img-fluid w-32">
                    </picture></figure>
                </div>
            </div>
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal3">
            <div class="testimonial-wrap">
              <!-- <div class="overlay"></div> -->
                <figure><img src="https://img.youtube.com/vi/wsO9CtaLPxQ/maxresdefault.jpg" width="667" height="375" class="img-fluid" alt="testimonials" loading="lazy"/></figure>
                <div class="video-play">
                  <picture>
                    <source type="image/webp" srcset="assets/images/play-icon.webp">
                    <source type="image/svg" srcset="assets/images/play-icon.svg">
                    <img loading="lazy" src="assets/images/play-icon.svg" width="200" height="200" alt="play" class="img-fluid w-48 d-inline-block">
                  </picture>
                </div>
                <div class="d-flex justify-content-between testimonial-caption">
                  <div class="d-flex">
                      <div class="d-flex flex-column text-left">
                        <div class="h4">Melton</div>
                        <div class="h6">Node Team</div>
                      </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="teach" class="img-fluid w-32">
                    </picture></figure>
                </div>
            </div>
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal4">
            <div class="testimonial-wrap">
              <!-- <div class="overlay"></div> -->
                <figure><img src="https://img.youtube.com/vi/KpUHAzNoj_Y/maxresdefault.jpg" width="667" height="375" class="img-fluid" alt="testimonials" loading="lazy"/></figure>
                <div class="video-play">
                  <picture>
                    <source type="image/webp" srcset="assets/images/play-icon.webp">
                    <source type="image/svg" srcset="assets/images/play-icon.svg">
                    <img loading="lazy" src="assets/images/play-icon.svg" width="200" height="200" alt="play" class="img-fluid w-48 d-inline-block">
                  </picture>
                </div>
                <div class="d-flex justify-content-between testimonial-caption">
                  <div class="d-flex">
                      <div class="d-flex flex-column text-left">
                        <div class="h4">Ponkumar</div>
                        <div class="h6">Frontend Team</div>
                      </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="teach" class="img-fluid w-32">
                    </picture></figure>
                </div>
            </div>
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal5">
            <div class="testimonial-wrap">
              <!-- <div class="overlay"></div> -->
                <figure><img src="https://img.youtube.com/vi/MsiGRE56FMw/maxresdefault.jpg" width="667" height="375" class="img-fluid" alt="testimonials" loading="lazy"/></figure>
                <div class="video-play">
                  <picture>
                    <source type="image/webp" srcset="assets/images/play-icon.webp">
                    <source type="image/svg" srcset="assets/images/play-icon.svg">
                    <img loading="lazy" src="assets/images/play-icon.svg" width="200" height="200" alt="play" class="img-fluid w-48 d-inline-block">
                  </picture>
                </div>
                <div class="d-flex justify-content-between testimonial-caption">
                  <div class="d-flex">
                      <div class="d-flex flex-column text-left">
                        <div class="h4">Rajesh Kumar</div>
                        <div class="h6">Quality Analyst Team</div>
                      </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="teach" class="img-fluid w-32">
                    </picture></figure>
                </div>
            </div>
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal6">
            <div class="testimonial-wrap">
              <!-- <div class="overlay"></div> -->
                <figure><img src="https://img.youtube.com/vi/E-I1u6zXcwU/maxresdefault.jpg" width="667" height="375" class="img-fluid" alt="testimonials" loading="lazy"/></figure>
                <div class="video-play">
                  <picture>
                    <source type="image/webp" srcset="assets/images/play-icon.webp">
                    <source type="image/svg" srcset="assets/images/play-icon.svg">
                    <img loading="lazy" src="assets/images/play-icon.svg" width="200" height="200" alt="play" class="img-fluid w-48 d-inline-block">
                  </picture>
                </div>
                <div class="d-flex justify-content-between testimonial-caption">
                  <div class="d-flex">
                      <div class="d-flex flex-column text-left">
                        <div class="h4">Vadivel</div>
                        <div class="h6">Quality Analyst Team</div>
                      </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="teach" class="img-fluid w-32">
                    </picture></figure>
                </div>
            </div>
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal7">
            <div class="testimonial-wrap">
              <!-- <div class="overlay"></div> -->
              <figure><img src="https://img.youtube.com/vi/Kn3XqYPyrHs/maxresdefault.jpg" width="667" height="375" class="img-fluid" alt="testimonials" loading="lazy"/></figure>
              <div class="video-play">
                <picture>
                  <source type="image/webp" srcset="assets/images/play-icon.webp">
                  <source type="image/svg" srcset="assets/images/play-icon.svg">
                  <img loading="lazy" src="assets/images/play-icon.svg" width="200" height="200" alt="play" class="img-fluid w-48 d-inline-block">
                </picture>
              </div>
              <div class="d-flex justify-content-between testimonial-caption">
                <div class="d-flex">
                    <div class="d-flex flex-column text-left">
                      <div class="h4">Chandrikha</div>
                      <div class="h6">HR Department</div>
                    </div>
                </div>
                <figure class="d-flex ml-auto">
                  <picture>
                    <source type="image/webp" srcset="assets/images/quote.webp">
                    <source type="image/png" srcset="assets/images/quote.png">
                    <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="teach" class="img-fluid w-32">
                  </picture></figure>
              </div>
            </div>
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal8">
            <div class="testimonial-wrap">
              <!-- <div class="overlay"></div> -->
              <figure><img src="https://img.youtube.com/vi/EWyyU5_zF8g/maxresdefault.jpg" width="667" height="375" class="img-fluid" alt="testimonials" loading="lazy"/></figure>
              <div class="video-play">
                <picture>
                  <source type="image/webp" srcset="assets/images/play-icon.webp">
                  <source type="image/svg" srcset="assets/images/play-icon.svg">
                  <img loading="lazy" src="assets/images/play-icon.svg" width="200" height="200" alt="play" class="img-fluid w-48 d-inline-block">
                </picture>
              </div>
              <div class="d-flex justify-content-between testimonial-caption">
                <div class="d-flex">

                    <div class="d-flex flex-column text-left">
                      <div class="h4">Naveen Kumar</div>
                      <div class="h6">Android Team</div>
                    </div>
                </div>
                <figure class="d-flex ml-auto">
                  <picture>
                    <source type="image/webp" srcset="assets/images/quote.webp">
                    <source type="image/png" srcset="assets/images/quote.png">
                    <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="teach" class="img-fluid w-32">
                  </picture></figure>
              </div>
            </div>
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal9">
            <div class="testimonial-wrap">
              <!-- <div class="overlay"></div> -->
              <figure><img src="https://img.youtube.com/vi/P92zC3gNac0/maxresdefault.jpg" width="667" height="375" class="img-fluid" alt="testimonials" loading="lazy"/></figure>
              <div class="video-play">
                <picture>
                  <source type="image/webp" srcset="assets/images/play-icon.webp">
                  <source type="image/svg" srcset="assets/images/play-icon.svg">
                  <img loading="lazy" src="assets/images/play-icon.svg" width="200" height="200" alt="play" class="img-fluid w-48 d-inline-block">
                </picture>
              </div>
              <div class="d-flex justify-content-between testimonial-caption">
                <div class="d-flex">

                    <div class="d-flex flex-column text-left">
                      <div class="h4">Jaswanth</div>
                      <div class="h6">UI/UX Team</div>
                    </div>
                </div>
                <figure class="d-flex ml-auto">
                  <picture>
                    <source type="image/webp" srcset="assets/images/quote.webp">
                    <source type="image/png" srcset="assets/images/quote.png">
                    <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="teach" class="img-fluid w-32">
                  </picture></figure>
              </div>
            </div>
          </a>
        </ng-template>
      </owl-carousel-o>

    </div>
  </section>

  <!--end-->
  <section class="our-story tabs tabs-section pt-150" id="why-codespot">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="position-relative">
            <div class="star left-top">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <source type="image/png" srcset="assets/images/star.png">
                <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
              </picture>
            </div>
            <div class="star right-bottom">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <source type="image/png" srcset="assets/images/star.png">
                <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
              </picture>
            </div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="fw-semibold nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                  type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true"> #CodeSpot <h3>For College
                    Students</h3>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="fw-semibold nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                  type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false"> #CodeSpot <h3>For
                    School Students</h3>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="fw-semibold nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane"
                  type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false"> #CodeSpot <h3>For
                    Freshers</h3>
                </button>
              </li>
            </ul>
          </div>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
              tabindex="0">
              <div class="row">
                <div class="col-md-5 sm-pb-40 d-flex align-items-center justify-content-center">
                  <div class="star left-bottom">
                    <picture>
                      <source type="image/webp" srcset="assets/images/star.webp">
                      <source type="image/png" srcset="assets/images/star.png">
                      <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                    </picture>
                  </div>
                  <picture>
                    <source type="image/webp" srcset="assets/images/experts.webp">
                    <source type="image/png" srcset="assets/images/experts.png">
                    <img loading="lazy" src="assets/images/experts.png" width="413" height="461" alt="experts" class="img-fluid animate-float">
                  </picture>
                  <!-- <img src="assets/images/experts.png" alt="experts" class="img-fluid animate-float" loading="lazy" /> -->
                </div>
                <div class="col-md-7 pt-50 pl-50">
                  <div class="card d-flex flex-row align-items-center">
                    <picture>
                      <source type="image/webp" srcset="assets/images/icon-sessions.webp">
                      <source type="image/png" srcset="assets/images/icon-sessions.png">
                      <img loading="lazy" src="assets/images/icon-sessions.png" width="50" height="50" alt="Sessions" class="img-fluid w-67 d-flex">
                    </picture>
                    <!-- <img src="assets/images/icon-sessions.png" alt="Sessions" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                    <div class="d-flex flex-column pl-25 wcalc50">
                      <h4>Sessions, Workshops & Hackathon</h4>
                      <p class="pb-0"> Hands-on training programs and events designed to foster critical thinking,
                        teamwork, and skill building. </p>
                    </div>
                  </div>
                  <div class="card d-flex flex-row align-items-center">
                    <picture>
                      <source type="image/webp" srcset="assets/images/icon-interaction.webp">
                      <source type="image/png" srcset="assets/images/icon-interaction.png">
                      <img loading="lazy" src="assets/images/icon-interaction.png" width="50" height="50" alt="Interaction" class="img-fluid w-67 d-flex">
                    </picture>
                    <!-- <img src="assets/images/icon-interaction.png" alt="Interaction" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                    <div class="d-flex flex-column pl-25 wcalc50">
                      <h4>Interaction with industry leaders</h4>
                      <p class="pb-0"> Learn about the industry from experienced professionals and the stories they have
                        to tell. </p>
                    </div>
                  </div>
                  <div class="card d-flex flex-row align-items-center">
                    <picture>
                      <source type="image/webp" srcset="assets/images/icon-industry.webp">
                      <source type="image/png" srcset="assets/images/icon-industry.png">
                      <img loading="lazy" src="assets/images/icon-industry.png" width="50" height="50" alt="Industry Access" class="img-fluid w-67 d-flex">
                    </picture>
                    <!-- <img src="assets/images/icon-industry.png" alt="Industry Access" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                    <div class="d-flex flex-column pl-25 wcalc50">
                      <h4>Industry Access</h4>
                      <p class="pb-0"> Opportunity to enroll in CodeSpot’s internship & industry training programs. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
              <div class="row">
                <div class="col-md-5 sm-pb-40 d-flex align-items-center justify-content-center">
                  <picture>
                    <source type="image/webp" srcset="assets/images/for-school-students.webp">
                    <source type="image/png" srcset="assets/images/for-school-students.png">
                    <img loading="lazy" src="assets/images/for-school-students.png" width="454" height="462" alt="For School Students" class="img-fluid animate-float">
                  </picture>
                  <!-- <img src="assets/images/for-school-students.png" alt="For School Students"
                    class="img-fluid animate-float" loading="lazy" /> -->
                </div>
                <div class="col-md-7 pt-50 pl-50">
                  <div class="card d-flex flex-row align-items-center">
                    <picture>
                      <source type="image/webp" srcset="assets/images/icon-awareness-sessions.webp">
                      <source type="image/png" srcset="assets/images/icon-awareness-sessions.png">
                      <img loading="lazy" src="assets/images/icon-awareness-sessions.png" width="50" height="50" alt="Awareness Sessions" class="img-fluid w-67 d-flex">
                    </picture>
                    <!-- <img src="assets/images/icon-awareness-sessions.png" alt="Awareness Sessions"
                      class="img-fluid w-67 d-flex" loading="lazy" /> -->
                    <div class="d-flex flex-column pl-25 wcalc50">
                      <h4>Awareness Sessions</h4>
                      <p class="pb-0"> Students are introduced to the possibilities and opportunities that await them in
                        the tech industry. </p>
                    </div>
                  </div>
                  <div class="card d-flex flex-row align-items-center">
                    <picture>
                      <source type="image/webp" srcset="assets/images/icon-motivational-talks.webp">
                      <source type="image/png" srcset="assets/images/icon-motivational-talks.png">
                      <img loading="lazy" src="assets/images/icon-motivational-talks.png" width="51" height="51" alt="Motivational Talks" class="img-fluid w-67 d-flex">
                    </picture>
                    <!-- <img src="assets/images/icon-motivational-talks.png" alt="Motivational Talks"
                      class="img-fluid w-67 d-flex" loading="lazy" /> -->
                    <div class="d-flex flex-column pl-25 wcalc50">
                      <h4>Motivational Talks</h4>
                      <p class="pb-0"> Our thought leaders speak with students and help them understand how a career in
                        tech can help them succeed and grow as individuals. </p>
                    </div>
                  </div>
                  <!-- <div class="card d-flex flex-row"><img
                      src="assets/images/icon-money.svg"
                      alt="money"
                      class="img-fluid w-67 d-flex"
                    /><div class="d-flex flex-column pl-25 wcalc50"><h4>Mini workshops</h4><p class="pb-0">
                        Our mini workshops provide students with an engaging way to learn, enjoy & grow outside of the classroom that will help them succeed.
                      </p></div></div> -->
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
              <div class="row">
                <div class="col-md-5 sm-pb-40 d-flex align-items-center justify-content-center">
                  <picture>
                    <source type="image/webp" srcset="assets/images/for-freshers.webp">
                    <source type="image/png" srcset="assets/images/for-freshers.png">
                    <img loading="lazy" src="assets/images/for-freshers.png" width="413" height="448" alt="For Freshers" class="img-fluid animate-float">
                  </picture>
                  <!-- <img src="assets/images/for-freshers.png" alt="For Freshers" class="img-fluid animate-float" loading="lazy" /> -->
                </div>
                <div class="col-md-7 pt-50 pl-50">
                  <div class="card d-flex flex-row align-items-center">
                    <picture>
                      <source type="image/webp" srcset="assets/images/icon-level1.webp">
                      <source type="image/png" srcset="assets/images/icon-level1.png">
                      <img loading="lazy" src="assets/images/icon-level1.png" width="50" height="48" alt="Internship - Level" class="img-fluid w-67 d-flex">
                    </picture>
                    <!-- <img src="assets/images/icon-level1.png" alt="Internship - Level 1" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                    <div class="d-flex flex-column pl-25 wcalc50">
                      <h4>Internship - Level 1 (1 Month)</h4>
                      <p class="pb-0"> Introduction to basic concepts, hands-on training sessions, and direct access to
                        industry leaders at CodeSpot Foundation . </p>
                    </div>
                  </div>
                  <div class="card d-flex flex-row align-items-center">
                    <picture>
                      <source type="image/webp" srcset="assets/images/icon-level2.webp">
                      <source type="image/png" srcset="assets/images/icon-level2.png">
                      <img loading="lazy" src="assets/images/icon-level2.png" width="50" height="44" alt="Internship - Level 2" class="img-fluid w-67 d-flex">
                    </picture>
                    <!-- <img src="assets/images/icon-level2.png" alt="Internship - Level 2" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                    <div class="d-flex flex-column pl-25 wcalc50">
                      <h4>Internship - Level 2 (2 Months)</h4>
                      <p class="pb-0"> Skill development and industry training by working on real-world projects,
                        monitors and assessed by technical leads. </p>
                    </div>
                  </div>
                  <div class="card d-flex flex-row align-items-center">
                    <picture>
                      <source type="image/webp" srcset="assets/images/icon-placement.webp">
                      <source type="image/png" srcset="assets/images/icon-placement.png">
                      <img loading="lazy" src="assets/images/icon-placement.png" width="50" height="50" alt="Placement" class="img-fluid w-67 d-flex">
                    </picture>
                    <!-- <img src="assets/images/icon-placement.png" alt="Placement" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                    <div class="d-flex flex-column pl-25 wcalc50">
                      <h4>Placement & Certification</h4>
                      <p class="pb-0"> Students who complete the internship successfully get a certificate and best
                        performers get placement opportunities at CodeSpot Foundation . </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="student-testimonials text-center pt-150">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="title d-inline-block">
            <h2 class="pb-6" data-aos="fade-up" data-aos-delay="100"> What our students say <span>about us</span>
            </h2>
            <p class="text-center" data-aos="fade-up" data-aos-delay="200"> Students and their abilities help us grow &
              develop and their excellence, success inspire us to <br /> inculcate more in our walks of lives. </p>
            <div class="star left-bottom">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <source type="image/png" srcset="assets/images/star.png">
                <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
              </picture>
            </div>
            <div class="star right-top">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <source type="image/png" srcset="assets/images/star.png">
                <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="mb-32 slicktestmonials">
        <div slickContainer #slickController="slick" [slickConfig]="studenttestimonials" class="studenttestimonials"
          pauseOnHover="true">
          <ng-container>
            <div slickItem>
              <div class="testimonial-wrap">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <figure>
                      <picture>
                        <source type="image/webp" srcset="assets/images/testimonial-chandrikha.webp">
                        <source type="image/png" srcset="assets/images/testimonial-chandrikha.png">
                        <img loading="lazy" src="assets/images/testimonial-chandrikha.png" width="48" height="48" alt="teach" class="img-fluid w-48">
                      </picture>
                      <!-- <img src="assets/images/testimonial-chandrikha.png" class="w-48" alt="teach" loading="lazy" /> -->
                    </figure>
                    <div class="d-flex flex-column text-left">
                      <div class="h4">Chandrikha Shanmugam</div>
                      <div class="h6">HR Assistant Manager</div>
                    </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="quote" class="img-fluid w-32">
                    </picture>
                    <!-- <img src="assets/images/quote.png" class="w-32" alt="teach"  loading="lazy"/> -->
                  </figure>
                </div>
                <p> CodeSpot Foundation has been a turning point in my life. Joining the CodeSpot Foundation team has
                  been a stepping stone toward my goal of creating products, as well as an incredible journey of
                  learning, networking, and positive change. I got the right guidance from professionals to find a great
                  career & got an opportunity to better position myself as a leading professional in the software
                  industry. </p>
              </div>
            </div>
            <div slickItem>
              <div class="testimonial-wrap">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <figure>
                      <picture>
                        <source type="image/webp" srcset="assets/images/testimonial-mythili.webp">
                        <source type="image/png" srcset="assets/images/testimonial-mythili.png">
                        <img loading="lazy" src="assets/images/testimonial-mythili.png" width="48" height="48" alt="mythili" class="img-fluid w-48">
                      </picture>
                      <!-- <img src="assets/images/testimonial-mythili.png" class="w-48" alt="teach" loading="lazy" /> -->
                    </figure>
                    <div class="d-flex flex-column text-left">
                      <div class="h4">Mythili Sivakumar</div>
                      <div class="h6">Associate Content Writer</div>
                    </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="quote" class="img-fluid w-32">
                    </picture>
                  </figure>
                </div>
                <p> To be in an Understanding, Valuing and Encouraging space is a dream to many. CodeSpot Foundation,
                  despite me being a new addition, I am experiencing all of that with wider opportunities to learn and
                  explore. And the biggest plus here is, it's a hub of energetic enthusiasts. </p>
              </div>
            </div>
            <div slickItem>
              <div class="testimonial-wrap">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <figure>
                      <picture>
                        <source type="image/webp" srcset="assets/images/testimonial-aravindan.webp">
                        <source type="image/png" srcset="assets/images/testimonial-aravindan.png">
                        <img loading="lazy" src="assets/images/testimonial-aravindan.png" width="48" height="48" alt="aravindan" class="img-fluid w-48">
                      </picture>
                      <!-- <img src="assets/images/testimonial-aravindan.png" class="w-48" alt="teach"  loading="lazy"/> -->
                    </figure>
                    <div class="d-flex flex-column text-left">
                      <div class="h4">Aravindan</div>
                      <div class="h6">Jr. Web Developer</div>
                    </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="quote" class="img-fluid w-32">
                    </picture>
                  </figure>
                </div>
                <p> CodeSpot Foundation is the place where I started my career. It helps me build a strong career
                  foundation through a lot of opportunities to explore and learn new technologies. I'm glad to work
                  around such cool ambience and friendly nature management. CodeSpot Foundation is a place where you can
                  pursue your passion, grow, and develop your skills technically. </p>
              </div>
            </div>
            <div slickItem>
              <div class="testimonial-wrap">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <figure>
                      <picture>
                        <source type="image/webp" srcset="assets/images/testimonial-ramseena.webp">
                        <source type="image/png" srcset="assets/images/testimonial-ramseena.png">
                        <img loading="lazy" src="assets/images/testimonial-ramseena.png" width="48" height="48" alt="ramseena" class="img-fluid w-48">
                      </picture>
                      <!-- <img src="assets/images/testimonial-ramseena.png" class="w-48" alt="teach" loading="lazy" /> -->
                    </figure>
                    <div class="d-flex flex-column text-left">
                      <div class="h4">Ramseena</div>
                      <div class="h6">Jr. Technical Content Writer</div>
                    </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="quote" class="img-fluid w-32">
                    </picture>
                  </figure>
                </div>
                <p> CodeSpot Foundation brought my career journey to life, gave me an identity, and propelled me toward
                  recognition. Do you want to stand out from the rest? Then CodeSpot Foundation will level you up and
                  help you discover your self-identity with good acknowledgment for the work that you do. </p>
              </div>
            </div>
            <div slickItem>
              <div class="testimonial-wrap">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <figure>
                      <picture>
                        <source type="image/webp" srcset="assets/images/testimonial-jegan.webp">
                        <source type="image/png" srcset="assets/images/testimonial-jegan.png">
                        <img loading="lazy" src="assets/images/testimonial-jegan.png" width="48" height="48" alt="jegan" class="img-fluid w-48">
                      </picture>
                      <!-- <img src="assets/images/testimonial-jegan.png" class="w-48" alt="teach" loading="lazy" /> -->
                    </figure>
                    <div class="d-flex flex-column text-left">
                      <div class="h4">Jegan</div>
                      <div class="h6">Jr. Web Developer</div>
                    </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="quote" class="img-fluid w-32">
                    </picture>
                  </figure>
                </div>
                <p> Having started my career with CodeSpot Foundation, I've been working under sociable management for 2
                  years now. I'm happy to work in such a hassle-free environment. My experiences with CodeSpot
                  Foundation have given me numerous opportunities to turn struggles into new possibilities, which has
                  helped me achieve my goals. </p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="mb-0 testimonial-post2-rtl">
        <div dir="rtl" slickContainer #slickController="slick" [slickConfig]="studenttestimonialsrtl"
          class="studenttestimonialsrtl" pauseOnHover="true">
          <ng-container>
            <div slickItem>
              <div class="testimonial-wrap">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <figure>
                      <picture>
                        <source type="image/webp" srcset="assets/images/testimonial-shanmugarajeshwaran.webp">
                        <source type="image/png" srcset="assets/images/testimonial-shanmugarajeshwaran.png">
                        <img loading="lazy" src="assets/images/testimonial-shanmugarajeshwaran.png" width="48" height="48" alt="shanmugarajeshwaran" class="img-fluid w-48">
                      </picture>
                      <!-- <img src="assets/images/testimonial-shanmugarajeshwaran.png" class="w-48" alt="teach" loading="lazy" /> -->
                    </figure>
                    <div class="d-flex flex-column text-left">
                      <div class="h4">Shanmugarajeshwaran</div>
                      <div class="h6">IOS Team Lead</div>
                    </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="quote" class="img-fluid w-32">
                    </picture>
                  </figure>
                </div>
                <p> I have been working at CodeSpot Foundation Solutions for over a year now. I am happy to say that I'm
                  a part of CodeSpot Foundation family - a company that is not only supportive, but also peaceful and
                  full of opportunities for technical skill and personal growth. </p>
              </div>
            </div>
            <div slickItem>
              <div class="testimonial-wrap">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <figure>
                      <picture>
                        <source type="image/webp" srcset="assets/images/testimonial-udhayakumar.webp">
                        <source type="image/png" srcset="assets/images/testimonial-udhayakumar.png">
                        <img loading="lazy" src="assets/images/testimonial-udhayakumar.png" width="48" height="48" alt="udhayakumar" class="img-fluid w-48">
                      </picture>
                      <!-- <img src="assets/images/testimonial-udhayakumar.png" class="w-48" alt="teach" loading="lazy" /> -->
                    </figure>
                    <div class="d-flex flex-column text-left">
                      <div class="h4">Udhayakumar</div>
                      <div class="h6">Associate Android Developer</div>
                    </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="quote" class="img-fluid w-32">
                    </picture>
                  </figure>
                </div>
                <p> I have worked at CodeSpot Foundation Solutions for over a year and it has been an absolute pleasure.
                  The work environment is supportive and pleasant, the people are good and the work is meaningful. I
                  have made considerable strides in my career and the only thing I would say about this place is that
                  it's a great place to work </p>
              </div>
            </div>
            <div slickItem>
              <div class="testimonial-wrap">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <figure>
                      <picture>
                        <source type="image/webp" srcset="assets/images/testimonial-boobalan.webp">
                        <source type="image/png" srcset="assets/images/testimonial-boobalan.png">
                        <img loading="lazy" src="assets/images/testimonial-boobalan.png" width="48" height="48" alt="boobalan" class="img-fluid w-48">
                      </picture>
                      <!-- <img src="assets/images/testimonial-boobalan.png" class="w-48" alt="teach" loading="lazy" /> -->
                    </figure>
                    <div class="d-flex flex-column text-left">
                      <div class="h4">Boobalan</div>
                      <div class="h6">Associate Web Developer</div>
                    </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="quote" class="img-fluid w-32">
                    </picture>
                  </figure>
                </div>
                <p> Since I started working here, CodeSpot Foundation has given me the autonomy to learn and work at my
                  own pace. It has shown me how a workplace should really be. Respect, trust, willingness to help, and
                  growth have all been experiences for me here. These qualities are what help a company shine as a great
                  organization. It’s all about creating a pleasant work culture. </p>
              </div>
            </div>
            <div slickItem>
              <div class="testimonial-wrap">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <figure>
                      <picture>
                        <source type="image/webp" srcset="assets/images/testimonial-saranraj.webp">
                        <source type="image/png" srcset="assets/images/testimonial-saranraj.png">
                        <img loading="lazy" src="assets/images/testimonial-saranraj.png" width="48" height="48" alt="saranraj" class="img-fluid w-48">
                      </picture>
                      <!-- <img src="assets/images/testimonial-saranraj.png" class="w-48" alt="teach" loading="lazy" /> -->
                    </figure>
                    <div class="d-flex flex-column text-left">
                      <div class="h4">Saran Raj</div>
                      <div class="h6">Jr. DevOps Engineer</div>
                    </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="quote" class="img-fluid w-32">
                    </picture>
                  </figure>
                </div>
                <p> A great team, great values, and unmatched spirit. That’s what I have experienced working as a part
                  of CodeSpot Foundation and everyone here has helped me cultivate these values within me. Everyday you
                  get a new experience and get to solve problems in the world. </p>
              </div>
            </div>
            <div slickItem>
              <div class="testimonial-wrap">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <figure>
                      <picture>
                        <source type="image/webp" srcset="assets/images/testimonial-boopathi.webp">
                        <source type="image/png" srcset="assets/images/testimonial-boopathi.png">
                        <img loading="lazy" src="assets/images/testimonial-boopathi.png" width="48" height="48" alt="boopathi" class="img-fluid w-48">
                      </picture>
                      <!-- <img src="assets/images/testimonial-boopathi.png" class="w-48" alt="teach" loading="lazy" /> -->
                    </figure>
                    <div class="d-flex flex-column text-left">
                      <div class="h4">Boopathi Krishnan</div>
                      <div class="h6">Jr. SEO Analyst</div>
                    </div>
                  </div>
                  <figure class="d-flex ml-auto">
                    <picture>
                      <source type="image/webp" srcset="assets/images/quote.webp">
                      <source type="image/png" srcset="assets/images/quote.png">
                      <img loading="lazy" src="assets/images/quote.png" width="32" height="32" alt="quote" class="img-fluid w-32">
                    </picture>
                  </figure>
                </div>
                <p> Coming to the office does not feel like you’re coming to finish tasks. It’s like you’re part of
                  something bigger. You’re always trying to solve different relevant problems and you’re always on your
                  toes to learn new things. Each day is a whole new experience at CodeSpot Foundation. </p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </div>
  </section>

  <!-- <section class="roadmap pt-150" id="roadmap">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="title pb-70 pr-50 sticky">
            <div class="star right-bottom">
              <img src="assets/images/star.png" alt="star" width="35" height="35" class="img-fluid animate-blink" />
            </div>
            <h4>Journey</h4>
            <h2>
              <span>CodeSpot</span> Roadmap
            </h2>
            <p> We equip students in under-resourced high schools with the skills, experiences, and connections that
              together create access to careers in technology. </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="roadmap-sec active d-flex">
            <div class="d-flex flex-column pr-115">
              <h2>Q1</h2>
              <h4>2023</h4>
            </div>
            <div class="d-flex">
              <p> 1. CodeSpot Foundation Founded <br />
                <br /> 2. Community Outreach to Schools & Colleges across Coimbatore. <br />
                <br /> 3. Internship Program for Freshers-20-Person Batch.
              </p>
            </div>
          </div>
          <div class="roadmap-sec d-flex">
            <div class="d-flex flex-column pr-115">
              <h2>Q2</h2>
              <h4>2023</h4>
            </div>
            <div class="d-flex">
              <p> 1. Seminars & Awareness Programs at institutions across Coimbatore. <br />
                <br /> 2. Skill-based educational sessions for college students. <br />
                <br /> 3. Empowering 10,000+ students for a career in technology.
              </p>
            </div>
          </div>
          <div class="roadmap-sec d-flex">
            <div class="d-flex flex-column pr-115">
              <h2>Q3</h2>
              <h4>2023</h4>
            </div>
            <div class="d-flex">
              <p> 1. Talks & Seminars across institutions all over Tamil Nadu <br />
                <br /> 2. Upskilling programs for students across Tamil Nadu. <br />
                <br /> 3. Hackathons, Thought Leadership Talks & Community Events.
              </p>
            </div>
          </div>
          <div class="roadmap-sec d-flex">
            <div class="d-flex flex-column pr-115">
              <h2>Q4</h2>
              <h4>2023</h4>
            </div>
            <div class="d-flex">
              <p> 1. CodeSpot Online Learning Portal Launch <br />
                <br /> 2. Statewide outreach to 75 institutions, empowering 1,00,000+ students. <br />
                <br /> 3. Increase Tamil Nadu's talent pool by at least 3% in 2023.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- <section class="team-creators-section pt-150 text-center"><div class="container"><div class="row"><div class="col-sm-12"><div class="title d-inline-block"><h2 class="pb-6" >
              Meet our Team of <span>creators.</span></h2><p
              class="text-center pb-50"
            >
              We equip students in under-resourced high schools with the skills,
              experiences, and connections <br />
              that together create access to careers in technology.
            </p><div class="star left-bottom"><img
                src="assets/images/star.png"
                alt="star" width="35" height="35"
                class="img-fluid animate-blink"
              /></div><div class="star right-top"><img
                src="assets/images/star.png"
                alt="star" width="35" height="35"
                class="img-fluid animate-blink"
              /></div></div></div></div></div><div class="team-creators"><div class="container"><div class="row"><div class="col-md-3 sm-pb-40"><div class="title position-absolute"><h3>
                  "l love <span>Code Spot,</span><br />
                  learning is easier without <br />
                  any obstacles”
                </h3><a
                  href="#"
                  class="request_btn btn-style-one d-inline-block"
                  >View Teams
                  <i class="bi bi-arrow-right"></i></a></div></div></div></div><div class="container-fluid"><div class="row"><div class="offset-md-3 col-md-9 align-self-end"><div class="mb-32 video-testimonials"><div
                  slickContainer
                  #slickController="slick"
                  [slickConfig]="config"
                  class="ourteamcreators"
                ><ng-container><div slickItem><div class="testimonial-wrap"><figure><img
                            src="assets/images/team1.png"
                            class="img-fluid"
                            alt="testimonials"
                          /></figure><div
                          class="d-flex justify-content-center testimonial-caption flex-column"
                        ><h2>Vinayak Mahadev</h2><h4>Head of Content @ CodeSpot Foundation</h4></div><div class="social d-flex"><a href="#">Linked In</a><a href="#">Instagram</a></div></div></div><div slickItem><div class="testimonial-wrap"><figure><img
                            src="assets/images/team2.png"
                            class="img-fluid"
                            alt="testimonials"
                          /></figure><div
                          class="d-flex justify-content-center testimonial-caption flex-column"
                        ><h2>Vinayak Mahadev</h2><h4>Head of Content @ CodeSpot Foundation</h4></div><div class="social d-flex"><a href="#">Linked In</a><a href="#">Instagram</a></div></div></div><div slickItem><div class="testimonial-wrap"><figure><img
                            src="assets/images/team3.png"
                            class="img-fluid"
                            alt="testimonials"
                          /></figure><div
                          class="d-flex justify-content-center testimonial-caption flex-column"
                        ><h2>Vinayak Mahadev</h2><h4>Head of Content @ CodeSpot Foundation</h4></div><div class="social d-flex"><a href="#">Linked In</a><a href="#">Instagram</a></div></div></div><div slickItem><div class="testimonial-wrap"><figure><img
                            src="assets/images/team1.png"
                            class="img-fluid"
                            alt="testimonials"
                          /></figure><div
                          class="d-flex justify-content-center testimonial-caption flex-column"
                        ><h2>Vinayak Mahadev</h2><h4>Head of Content @ CodeSpot Foundation</h4></div><div class="social d-flex"><a href="#">Linked In</a><a href="#">Instagram</a></div></div></div><div slickItem><div class="testimonial-wrap"><figure><img
                            src="assets/images/team2.png"
                            class="img-fluid"
                            alt="testimonials"
                          /></figure><div
                          class="d-flex justify-content-center testimonial-caption flex-column"
                        ><h2>Vinayak Mahadev</h2><h4>Head of Content @ CodeSpot Foundation</h4></div><div class="social d-flex"><a href="#">Linked In</a><a href="#">Instagram</a></div></div></div><div slickItem><div class="testimonial-wrap"><figure><img
                            src="assets/images/team3.png"
                            class="img-fluid"
                            alt="testimonials"
                          /></figure><div
                          class="d-flex justify-content-center testimonial-caption flex-column"
                        ><h2>Vinayak Mahadev</h2><h4>Head of Content @ CodeSpot Foundation</h4></div><div class="social d-flex"><a href="#">Linked In</a><a href="#">Instagram</a></div></div></div></ng-container></div></div></div></div></div></div></section> -->
  <section class="gallery pt-150">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="title pb-70 pr-50">
            <div class="star left-top">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <source type="image/png" srcset="assets/images/star.png">
                <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
              </picture>
            </div>
            <div class="h4">Our Gallery</div>
            <h2>Explore our learning <br /> journey <span>today!</span>
            </h2>
            <p> We equip students in under-resourced high schools with the skills, experiences, and connections that
              together create access to careers in technology. </p>
          </div>
          <div class="row">
            <div class="column">
              <picture>
                <source type="image/webp" srcset="assets/images/gallery3.webp">
                <source type="image/png" srcset="assets/images/gallery3.png">
                <img loading="lazy" src="assets/images/gallery3.png" width="262" height="289" alt="gallery3" class="img-fluid w-100">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets/images/gallery4.webp">
                <source type="image/png" srcset="assets/images/gallery4.png">
                <img loading="lazy" src="assets/images/gallery4.png" width="261" height="187" alt="gallery4" class="img-fluid w-100">
              </picture>

              <!-- <img src="assets/images/gallery3.png" alt="gallery3" width="262" height="289" class="w-100" loading="lazy"/> -->
              <!-- <img src="assets/images/gallery4.png" alt="gallery4" width="261" height="187" class="w-100" loading="lazy"/> -->
            </div>
            <div class="column">
              <picture>
                <source type="image/webp" srcset="assets/images/gallery5.webp">
                <source type="image/png" srcset="assets/images/gallery5.png">
                <img loading="lazy" src="assets/images/gallery5.png" width="261" height="187" alt="gallery5" class="img-fluid w-100">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets/images/gallery6.webp">
                <source type="image/png" srcset="assets/images/gallery6.png">
                <img loading="lazy" src="assets/images/gallery6.png" width="261" height="240" alt="gallery6" class="img-fluid w-100">
              </picture>
              <!-- <img src="assets/images/gallery5.png" alt="gallery5" width="261" height="187" class="w-100" loading="lazy"/>
              <img src="assets/images/gallery6.png" alt="gallery6" width="261" height="240" class="w-100" loading="lazy"/> -->
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row align-items-end">
            <div class="column">
              <picture>
                <source type="image/webp" srcset="assets/images/gallery1.webp">
                <source type="image/png" srcset="assets/images/gallery1.png">
                <img loading="lazy" src="assets/images/gallery1.png" width="262" height="261" alt="gallery1" class="img-fluid w-100">
              </picture>
              <!-- <img src="assets/images/gallery1.png" alt="gallery1" width="262" height="261" class="w-100" loading="lazy" /> -->
            </div>
            <div class="column">
              <picture>
                <source type="image/webp" srcset="assets/images/gallery2.webp">
                <source type="image/png" srcset="assets/images/gallery2.png">
                <img loading="lazy" src="assets/images/gallery2.png" width="261" height="188" alt="gallery1" class="img-fluid w-100">
              </picture>
              <!-- <img src="assets/images/gallery2.png" alt="gallery2" width="261" height="188" class="w-100" loading="lazy" /> -->
            </div>
          </div>
          <div class="row">
            <div class="column column-full">
              <picture>
                <source type="image/webp" srcset="assets/images/gallery7.webp">
                <source type="image/png" srcset="assets/images/gallery7.png">
                <img loading="lazy" src="assets/images/gallery7.png" width="546" height="288" alt="gallery7" class="img-fluid w-100">
              </picture>
              <!-- <img src="assets/images/gallery7.png" alt="gallery7" width="546" height="288" class="w-100" loading="lazy" /> -->
            </div>
          </div>
          <div class="row">
            <div class="column">
              <picture>
                <source type="image/webp" srcset="assets/images/gallery8.webp">
                <source type="image/png" srcset="assets/images/gallery8.png">
                <img loading="lazy" src="assets/images/gallery8.png" width="262" height="289" alt="gallery8" class="img-fluid w-100">
              </picture>
              <!-- <img src="assets/images/gallery8.png" alt="gallery8" width="262" height="289" class="w-100" loading="lazy" /> -->
            </div>
            <div class="column">
              <picture>
                <source type="image/webp" srcset="assets/images/gallery9.webp">
                <source type="image/png" srcset="assets/images/gallery9.png">
                <img loading="lazy" src="assets/images/gallery9.png" width="261" height="187" alt="gallery9" class="img-fluid w-100">
              </picture>
              <!-- <img src="assets/images/gallery9.png" alt="gallery9" width="261" height="187" class="w-100" loading="lazy" /> -->
            </div>
          </div>
          <!-- <div class="gallery-item"><img *ngFor="let item of items; index as i"
            [lightbox]="i"
            [src]="item.data.thumb"></div> -->
        </div>
      </div>
    </div>
  </section>
  <section class="events-section pt-150" id="why-codespot">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="title">
            <div class="star right-top">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <source type="image/png" srcset="assets/images/star.png">
                <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
              </picture>
            </div>
            <div class="h4">Events</div>
            <h2>Upcoming <span>Events</span>
            </h2>
          </div>
        </div>
        <div class="col-md-5 d-flex justify-content-end">
          <ul class="nav nav-tabs" id="eventsTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button (click)="onClickUpcoming()" class="fw-semibold nav-link active" id="upcoming-tab" data-bs-toggle="tab"
                data-bs-target="#upcoming-tab-pane" type="button" role="tab" aria-controls="upcoming-tab-pane"
                aria-selected="true"> Upcoming Events </button>
            </li>
            <li class="nav-item" role="presentation">
              <button (click)="onClickPast()" class="fw-semibold nav-link" id="past-tab" data-bs-toggle="tab"
                data-bs-target="#past-tab-pane" type="button" role="tab" aria-controls="past-tab-pane"
                aria-selected="false"> Past Events </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="tab-content" id="eventsTabContent">
            <div class="tab-pane fade show active" id="upcoming-tab-pane" role="tabpanel" aria-labelledby="upcoming-tab"
              tabindex="0">
              <div class="" *ngIf="showspinner">
                <div class="showbox">
                  <div class="loader">
                    <svg class="circular" viewBox="25 25 50 50">
                      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                    </svg>
                  </div>
                </div>
              </div>
              <div slickContainer #slickController="slick" [slickConfig]="upcomingEvents" class="upcoming-events"
                pauseOnHover="true">

                <ng-container *ngIf="!showspinner && eventsUpcoming.length>0 ">

                  <div slickItem *ngFor="let upcoming of eventsUpcoming">
                    <div class="events-card">
                      <figure>
                        <img src="{{upcoming.previewImg}}" alt="{{upcoming.PreviewAlt}}" class="img-fluid" loading="lazy"  />
                        <div class="logo-events">
                          <img src="{{upcoming.partnerLogo}}" alt="{{upcoming.PreviewAlt}}" class="img-fluid" loading="lazy"  />
                        </div>
                      </figure>
                      <div class="h5">{{upcoming.eventDate}}</div>
                      <h3>{{upcoming.eventName}}</h3>
                    </div>
                  </div>
                </ng-container>

              </div>
            </div>


            <div class="tab-pane fade" id="past-tab-pane" role="tabpanel" aria-labelledby="past-tab" tabindex="0">
              <div class="" *ngIf="showspinnerPast">
                <div class="showbox">
                  <div class="loader">
                    <svg class="circular" viewBox="25 25 50 50">
                      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                    </svg>
                  </div>
                </div>
              </div>
              <div slickContainer #slickController="slick" [slickConfig]="pastEvents" class=" past-events"
                pauseOnHover="true">
                <ng-container *ngIf="!showspinnerPast && eventsPast.length>0">
                  <div slickItem *ngFor="let past of eventsPast">
                    <div class="events-card">
                      <figure>
                        <img src="{{past.previewImg}}" alt="{{past.PreviewAlt}}" class="img-fluid" loading="lazy"  />
                        <div class="logo-events">
                          <img src="{{past.partnerLogo}}" alt="{{past.PreviewAlt}}" class="img-fluid" loading="lazy"  />
                        </div>
                      </figure>
                      <div class="h5">{{past.eventDate}}</div>
                      <h3>{{past.eventName}}</h3>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="join-team text-center pt-150"><div class="container-fluid"><div class="row"><div class="col-sm-12"><div class="title d-inline-block"><div class="star right-top"><img
                src="assets/images/star.png"
                alt="star"
                class="img-fluid animate-blink"
              /></div><div class="star left-bottom"><img
                src="assets/images/star.png"
                alt="star"
                class="img-fluid animate-blink"
              /></div><h2>
              Join our <span>Team.</span></h2><p class="text-center">
              We equip students in under-resourced high schools with the skills,
              experiences, <br />
              and connections that together create access.
            </p><a
              class="request_btn d-inline"
              href="#"

              >Partner with us</a
            ></div><figure
            class="d-flex justify-content-center join-team-img"

          ><img
              src="assets/images/join-team.png"
              class="img-fluid"
              alt="Join our Team"
            /></figure></div></div></div></section> -->
  <section class="contact pt-150 pb-150" id="contact">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="title d-inline-block">
            <h2>Contact <span>.</span>
            </h2>
            <p class="pb-6"> We equip students in under-resourced <br /> high schools with the skills. </p>
            <div class="star">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <source type="image/png" srcset="assets/images/star.png">
                <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
              </picture>
              <!-- <img src="assets/images/star.png" alt="star" width="35" height="35" class="img-fluid animate-blink" loading="lazy"  /> -->
            </div>
          </div>
          <figure class="d-flex">
            <picture>
              <source type="image/webp" srcset="assets/images/contact.webp">
              <source type="image/png" srcset="assets/images/contact.png">
              <img loading="lazy" src="assets/images/contact.png" width="445" height="420" alt="Join our Team" class="img-fluid contact-img">
            </picture>
            <!-- <img src="assets/images/contact.png" class="img-fluid contact-img" alt="Join our Team" loading="lazy"  /> -->
          </figure>
        </div>
        <div class="col-lg-7">
          <div class="contact-form">
            <div class="getintouch getintochform">
              <form [formGroup]="contactmail" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-floating">
                      <input type="text" class="form-control" id="floatingInput" formControlName="name"
                        placeholder="Name" [ngClass]="{
                          'is-invalid': submitted && f['name'].errors
                        }" />
                      <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
                        <div *ngIf="f['name'].errors['required']"> Name is required </div>
                      </div>
                      <label for="floatingInput">Your Name</label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-floating">
                      <input type="text" class="form-control" id="floatingInput" formControlName="organization"
                        placeholder="Organization/College" [ngClass]="{
                          'is-invalid': submitted && f['organization'].errors
                        }" />
                      <div *ngIf="submitted && f['organization'].errors" class="invalid-feedback">
                        <div *ngIf="f['organization'].errors['required']"> Organization/College is required </div>
                      </div>
                      <label for="floatingInput">Organization/College</label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-floating">
                      <input type="email" class="form-control" id="floatingemail" placeholder="Email"
                        formControlName="email" [ngClass]="{
                          'is-invalid': submitted && f['email'].errors
                        }" />
                      <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                        <div *ngIf="f['email'].errors['required']"> Email is required </div>
                        <div *ngIf="f['email'].errors['email']"> Email must be a valid email address </div>
                      </div>
                      <label for="floatingemail">Email</label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-floating">
                      <input type="number" placeholder="Phone Number" (keypress)="numericOnly($event)"
                        id="floatingphone_number" formControlName="phone_number" class="form-control" [ngClass]="{
                          'is-invalid': submitted && f['phone_number'].errors
                        }" />
                      <div *ngIf="submitted && f['phone_number'].errors" class="invalid-feedback">
                        <div *ngIf="f['phone_number'].errors['required']"> Phone is required </div>
                        <div *ngIf="f['phone_number'].errors['minlength']"> Phone must be at least 10 numbers. </div>
                        <div *ngIf="f['phone_number'].errors['maxlength']"> Phone must be 10 numbers. </div>
                      </div>
                      <label for="floatingphone_number">Phone Number</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-checkbox">
                      <div class="h4">Enquiring for ?</div>
                      <div class="d-flex justify-content-between" [ngClass]="{
                          'is-invalid': submitted && f['enquiry'].errors
                        }">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" formControlName="enquiry" id="inlineCheckbox1"
                            value="Internship" checked />
                          <label class="form-check-label" for="inlineCheckbox1">Internship</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" formControlName="enquiry" id="inlineCheckbox2"
                            value="Placement" />
                          <label class="form-check-label" for="inlineCheckbox2">Placement</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" formControlName="enquiry" id="inlineCheckbox3"
                            value="Certification Course" />
                          <label class="form-check-label" for="inlineCheckbox3">Certification Course</label>
                        </div>
                        <!-- <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" formControlName="enquiry" id="inlineCheckbox4"
                            value="Enquiry Details" />
                          <label class="form-check-label" for="inlineCheckbox4">Enquiry</label>
                        </div> -->
                      </div>
                      <div *ngIf="submitted && f['enquiry'].errors" class="invalid-feedback">
                        <div *ngIf="f['enquiry'].errors['required']"> Please select one </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating">
                      <textarea class="form-control" placeholder="Your Message" id="floatingTextarea2"
                        style="height: 70px" formControlName="message" [ngClass]="{
                          'is-invalid': submitted && f['message'].errors
                        }"></textarea>
                      <div *ngIf="submitted && f['message'].errors" class="invalid-feedback">
                        <div *ngIf="f['message'].errors['required']"> Message is required </div>
                      </div>
                      <label for="floatingTextarea2">Your Message</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="text-right">
                      <button class="request_btn">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</section>

<!--chanrikha-->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="stopVideo()"></button>
        <lite-youtube videoid="d-iPO-DmXCU"></lite-youtube>
        <!-- <iframe width="100%" height="450" src="https://www.youtube.com/embed/d-iPO-DmXCU?rel=0" title="Codespot ல் எனது பயணம் #1- MNC வாய்ப்பை விட்டு நான் ஏன் Startup-ஐ தேர்ந்தெடுத்தேன்? | Feedback" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
      </div>
    </div>
  </div>
</div>
<!--end-->
<!--Naveenkumar-->
<div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="stopVideo()"></button>
        <lite-youtube videoid="gsjERkyDrm8"></lite-youtube>
        <!-- <iframe width="100%" height="450" src="https://www.youtube.com/embed/gsjERkyDrm8?rel=0" title="Furniture Company-யில் பணிபுரிந்த நான், இன்று ஒரு IT நிறுவனத்தில் Andriod Developer!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
      </div>
    </div>
  </div>
</div>
<!--end-->
<!--Jaswanth-->
<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="stopVideo()"></button>
        <lite-youtube videoid="78NdhmKybHk"></lite-youtube>
        <!-- <iframe width="100%" height="450" src="https://www.youtube.com/embed/78NdhmKybHk?rel=0" title="என் Passion, இப்போ என்னோட Profession..! - Codespot-ல் என் பயணம்" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
      </div>
    </div>
  </div>
</div>
<!--end-->
<!--brito-->
<div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="stopVideo()"></button>
        <lite-youtube videoid="wsO9CtaLPxQ"></lite-youtube>
        <!-- <iframe width="100%" height="450" src="https://www.youtube.com/embed/wsO9CtaLPxQ?rel=0" title="Codespot என்னோட IT கனவை நிறைவேற்றியது ! | Feedback #backenddeveloper #technology  #frontend" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
      </div>
    </div>
  </div>
</div>
<!--end-->
<!--Ponkumar-->
<div class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="stopVideo()"></button>
        <lite-youtube videoid="KpUHAzNoj_Y"></lite-youtube>
        <!-- <iframe width="100%" height="450" src="https://www.youtube.com/embed/KpUHAzNoj_Y?rel=0" title="என் career-ஐ build பண்ண Codespot எனக்கு உதவியது.| Feed back" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
      </div>
    </div>
  </div>
</div>
<!--end-->
<!--Rajeshkumar-->
<div class="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="stopVideo()"></button>
        <lite-youtube videoid="MsiGRE56FMw"></lite-youtube>
        <!-- <iframe width="100%" height="450" src="https://www.youtube.com/embed/MsiGRE56FMw?rel=0" title="10% knowledge உடன் வந்த நான், Codespot-ஆல் IT துறையில் நிறைய Achieve பண்ணீருக்கேன் | feedback" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
      </div>
    </div>
  </div>
</div>
<!--end-->
<!--Vadivel-->
<div class="modal fade" id="exampleModal6" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="stopVideo()"></button>
        <lite-youtube videoid="E-I1u6zXcwU"></lite-youtube>
        <!-- <iframe width="100%" height="450" src="https://www.youtube.com/embed/E-I1u6zXcwU?rel=0" title="இனி Career Switch பண்ண தயக்கமே வேண்டாம், CodeSpot இருக்க பயம் ஏன்? | Feedback | Quality analyst" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
      </div>
    </div>
  </div>
</div>
<!--end-->

<!--shorts-->
<div class="modal fade" id="exampleModal7" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center mx-auto">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="stopVideo()"></button>
          <lite-youtube videoid="Kn3XqYPyrHs"></lite-youtube>
          <!-- <iframe width="100%" height="655" src="https://www.youtube.com/embed/Kn3XqYPyrHs?rel=0" title="MNC வாய்ப்பை விட்டு நான் ஏன் Startup-ஐ தேர்ந்தெடுத்தேன்? Codespot ல் எனது பயணம் |Feedback" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal8" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="stopVideo()"></button>
        <div class="text-center mx-auto">
          <lite-youtube videoid="EWyyU5_zF8g"></lite-youtube>
          <!-- <iframe width="100%" height="655" src="https://www.youtube.com/embed/EWyyU5_zF8g?rel=0" title="Furniture Company-யில் பணிபுரிந்த நான், இன்று ஒரு IT நிறுவனத்தில் Andriod Developer! | #education" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal9" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="stopVideo()"></button>
        <div class="text-center mx-auto">
          <lite-youtube videoid="P92zC3gNac0"></lite-youtube>
          <!-- <iframe width="100%" height="655" src="https://www.youtube.com/embed/P92zC3gNac0?rel=0" title="Codespot-ல் Design பயிற்சி பெற்றவர், Junior UI/UX Designer-ஆக பணிபுரிகிறார் | feedback" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal10" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="stopVideo()"></button>
        <div class="text-center mx-auto">
          <lite-youtube videoid="cmBq8rc9h6Y"></lite-youtube>
          <!-- <iframe width="100%" height="450" src="https://www.youtube.com/embed/cmBq8rc9h6Y?rel=0" title="Codespot-ல மட்டும் தான் Advanced Technologies-ல Free Training கொடுக்குறாங்க | Feedback" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal11" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="stopVideo()"></button>
        <div class="text-center mx-auto">
          <lite-youtube videoid="PAs2C3ZAVmY"></lite-youtube>
          <!-- <iframe width="100%" height="450" src="https://www.youtube.com/embed/PAs2C3ZAVmY?rel=0" title="நீங்களும் Frontend Developer ஆக வேண்டுமா? அப்போ Codespot 'ல Join பண்ணுங்க!!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!--ednd-->
