import {
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
// import { FooterserviceService } from 'src/app/service/footerservice.service';
import AOS from 'aos';
import { Slick } from 'ngx-slickjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../services/common.service';
import { ToastrService } from 'ngx-toastr';
// import { NgxSpinnerService } from "ngx-spinner";
import { ModalOptions } from 'ngx-bootstrap/modal';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  modalConfig: ModalOptions = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  show: boolean = false;
  fullScreen: boolean = true;
  template = ``;
  items!: GalleryItem[];
  showspinner: boolean = false;
  showspinnerPast: boolean = false;
  public eventsUpcoming: any[] = [];
  public eventsPast: any[] = [];


  events_upcoming = [
    {
      previewImg: 'assets/images/excel.png',
      PreviewAlt: 'Excel',
      partnerLogo: 'assets/images/logo-excel.png',
      eventDate: 'Fri, Mar 17, 11:30 AM',
      eventName: 'Decentralized Finance (DeFi)'
    },
    {
      previewImg: 'assets/images/krishna.png',
      PreviewAlt: 'krishna',
      partnerLogo: 'assets/images/logo-krishna.png',
      eventDate: 'Thu, Mar 23, 02:00 PM',
      eventName: 'Getting started with iOS Career'
    },
    {
      previewImg: 'assets/images/jansons.png',
      PreviewAlt: 'jansons',
      partnerLogo: 'assets/images/logo-jansons.png',
      eventDate: 'Tue, Apr 06, 10:30 AM',
      eventName: 'Artificial Intelligence in Testing'
    },
    {
      previewImg: 'assets/images/kg.png',
      PreviewAlt: 'kg',
      partnerLogo: 'assets/images/logo-kg.png',
      eventDate: 'Sat, Apr 15, 11:00 AM',
      eventName: 'Effective Email Marketing Techniques'
    },
    {
      previewImg: 'assets/images/excel.png',
      PreviewAlt: 'excel',
      partnerLogo: 'assets/images/logo-excel.png',
      eventDate: 'Fri, Mar 17, 11:30 AM',
      eventName: 'Decentralized Finance (DeFi)'
    },
    {
      previewImg: 'assets/images/krishna.png',
      PreviewAlt: 'krishna',
      partnerLogo: 'assets/images/logo-krishna.png',
      eventDate: 'Thu, Mar 23, 02:00 PM',
      eventName: 'Getting started with iOS Career'
    }
  ];
  events_pasts = [
    {
      previewImg: 'assets/images/excel.png',
      PreviewAlt: 'Excel',
      partnerLogo: 'assets/images/logo-excel.png',
      eventDate: 'Fri, Mar 17, 11:30 AM',
      eventName: 'Decentralized Finance (DeFi)'
    },
    {
      previewImg: 'assets/images/krishna.png',
      PreviewAlt: 'krishna',
      partnerLogo: 'assets/images/logo-krishna.png',
      eventDate: 'Thu, Mar 23, 02:00 PM',
      eventName: 'Getting started with iOS Career'
    },
    {
      previewImg: 'assets/images/jansons.png',
      PreviewAlt: 'jansons',
      partnerLogo: 'assets/images/logo-jansons.png',
      eventDate: 'Tue, Apr 06, 10:30 AM',
      eventName: 'Artificial Intelligence in Testing'
    },
    {
      previewImg: 'assets/images/kg.png',
      PreviewAlt: 'kg',
      partnerLogo: 'assets/images/logo-kg.png',
      eventDate: 'Sat, Apr 15, 11:00 AM',
      eventName: 'Effective Email Marketing Techniques'
    },
    {
      previewImg: 'assets/images/excel.png',
      PreviewAlt: 'excel',
      partnerLogo: 'assets/images/logo-excel.png',
      eventDate: 'Fri, Mar 17, 11:30 AM',
      eventName: 'Decentralized Finance (DeFi)'
    },
    {
      previewImg: 'assets/images/krishna.png',
      PreviewAlt: 'krishna',
      partnerLogo: 'assets/images/logo-krishna.png',
      eventDate: 'Thu, Mar 23, 02:00 PM',
      eventName: 'Getting started with iOS Career'
    }
  ];

  config: Slick.Config = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  videotestimonials: Slick.Config = {
    infinite: true,
    initialSlide: 1,
    slidesToShow: 1,
    slidesToScroll: 2,
    dots: false,
    arrows: true,
    autoplay: false,
    variableWidth: true,
    autoplaySpeed: 0,
    speed: 3000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 991, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  studenttestimonials: Slick.Config = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 0,
    focusOnSelect: true,
    speed: 20000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 991, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  studenttestimonialsrtl: Slick.Config = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 4,
    //dragToSlide: auto,
    dots: false,
    arrows: false,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 45000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 991, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  upcomingEvents: Slick.Config = {
    //infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplay: true,
    //variableWidth: true,
    autoplaySpeed: 3000,
    //speed: 10000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 991, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  pastEvents: Slick.Config = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplay: true,
    //variableWidth: true,
    autoplaySpeed: 3000,
    //speed: 10000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 991, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  public contactmail!: FormGroup;
  submitted = false;
  countryCode: any;
  // country_code: any;
  // formdata = [];
    // Store subscription to manage it properly
    private submitFormSubscription: Subscription | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private toastr: ToastrService,
    public gallery: Gallery,
  ) { }

  ngOnInit(): void {
    AOS.init();
    this.eventsUpcoming = this.events_upcoming;
    this.contactmail = this.formBuilder.group({
      name: ['', [Validators.required]],
      organization: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone_number: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      enquiry: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });

    this.items = data.map(item =>
      new ImageItem({ src: item.srcUrl, thumb: item.previewUrl })
    );

    // Load items into the lightbox
    this.basicLightboxExample();

    // Load item into different lightbox instance
    // With custom gallery config
    this.withCustomGalleryConfig();


  }

  /**
   * Stops video
   */
  public stopVideo() {
    var videos = document.querySelectorAll('iframe, video');
    Array.prototype.forEach.call(videos, function (video) {
      if (video.tagName.toLowerCase() === 'video') {
        video.pause([0]);
      } else {
        var src = video.src;
        video.src = src;
      }
    });
  }

  basicLightboxExample() {
    this.gallery.ref().load(this.items);
  }
  /**
   * Use custom gallery config with the lightbox
   */
  withCustomGalleryConfig() {

    // 2. Get a lightbox gallery ref
    const lightboxGalleryRef = this.gallery.ref('anotherLightbox');

    // (Optional) Set custom gallery config to this lightbox
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // 3. Load the items into the lightbox
    lightboxGalleryRef.load(this.items);
  }

  //easy to access form control value
  get f() {
    return this.contactmail.controls;
  }

  onSubmit() {
    //console.log(this.contactmail)
    this.submitted = true;
    if (this.contactmail.valid) {
      let formData = {
        ...this.contactmail.value,
        country_code: this.countryCode
      };

       // Unsubscribe if there's a previous subscription to prevent memory leaks
       if (this.submitFormSubscription) {
        this.submitFormSubscription.unsubscribe();
      }
      //console.log(formdata);
      this.submitFormSubscription = this.commonService.submitcontactform(formData).subscribe({
        next: (response: any) => {
          this.submitted = false;
          if ((response.status = ['SUCCESS'])) {
            this.toastr.success('Mail Send Successfully!', 'Success!');
            this.contactmail.reset();
          }
        },
        error: (error: any) => {
          this.submitted = false;
          this.toastr.error('Failed to send mail. Please try again later.','Error!');
        }
    });
    }
  }
  numericOnly(event: any) {
    let pattern = /^([0-9])$/;
    let result = pattern.test(event.key);
    return result;
  }

  ngOnDestroy() {
    // Clean up subscription to prevent memory leaks
    if (this.submitFormSubscription) {
      this.submitFormSubscription.unsubscribe();
    }
  }

  onClickUpcoming() {
    this.showspinner = true;
    this.eventsUpcoming = [];
    setTimeout(() => {
      this.eventsUpcoming = this.events_upcoming.slice();
      this.showspinner = false
    }, 3000);
  }
  onClickPast() {
    this.showspinnerPast = true;
    this.eventsPast = []
    setTimeout(() => {
      this.eventsPast = this.events_pasts.slice();
      this.showspinnerPast = false
    }, 3000);
  }
  //video owl carousel
    customOptions: OwlOptions = {
    loop: true,
    margin:20,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoplay:true,
    nav:true,
    navSpeed:3000,
    navText: ['<i class="bi bi-arrow-left"></i>', '<i class="bi bi-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1.2
      },
      740: {
        items: 2.3
      },
      940: {
        items: 3
      }
    }
  }
}

const data = [
  {
    srcUrl: 'assets/images/gallery1.png',
    previewUrl: 'assets/images/gallery1.png'
  },
  {
    srcUrl: 'assets/images/gallery2.png',
    previewUrl: 'assets/images/gallery2.png'
  },
  {
    srcUrl: 'assets/images/gallery3.png',
    previewUrl: 'assets/images/gallery3.png'
  },
  {
    srcUrl: 'assets/images/gallery4.png',
    previewUrl: 'assets/images/gallery4.png'
  },
  {
    srcUrl: 'assets/images/gallery5.png',
    previewUrl: 'assets/images/gallery5.png'
  },
  {
    srcUrl: 'assets/images/gallery6.png',
    previewUrl: 'assets/images/gallery6.png'
  },
  {
    srcUrl: 'assets/images/gallery7.png',
    previewUrl: 'assets/images/gallery7.png'
  },
  {
    srcUrl: 'assets/images/gallery8.png',
    previewUrl: 'assets/images/gallery8.png'
  },
  {
    srcUrl: 'assets/images/gallery9.png',
    previewUrl: 'assets/images/gallery9.png'
  }
];
