<app-header-new></app-header-new>
<section class="courselisting-banner achivements-banner">
    <div class="container">
        <div class="mt-md-4 row align-items-center justify-content-between">
            <div class="col-md-12">
                <div class="">
                    <div class="star top-70">
                      <picture>
                        <source type="image/webp" srcset="assets/images/star.webp">
                        <source type="image/png" srcset="assets/images/star.png">
                        <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                      </picture>
                    </div>
                    <h1 class="text-white"> <span class="border-0">Nandha College: </span> A Harmonious Visit for <br>the Memorandum of Understanding </h1>
                    <p class="text-white"> Welcome to our blog page where we delve into diverse topics and share insights. Discover compelling stories, useful resources, and enriching content for lifelong. </p>
                    <div class="star bottom-70">
                        <picture>
                          <source type="image/webp" srcset="assets/images/star.webp">
                          <source type="image/png" srcset="assets/images/star.png">
                          <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                        </picture>
                      </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="achivements-image">
    <div class="container">
        <picture>
            <source type="image/webp" srcset="assets/images/course-listing/achievement-detail.webp">
            <source type="image/jpg" srcset="assets/images/course-listing/achievement-detail.jpg">
            <img loading="lazy" src="assets/images/course-listing/achievement-detail.jpg" alt="Achievement Detail banner" width="1296" height="673" class="w-100 img-fluid">
        </picture>
    </div>
</section>
<section class="pt-50 achievement-detail-wrap">
    <div class="container">
        <div class="title">
            <div class="h4">Visit Glossary</div>
            <h2 class="mb-2"><span>Sign the Memorandum </span> of Understanding (MoU)
                was a remarkable experience</h2>
        </div>
        <p class="mb-lg-2">Visiting Nandha College to sign the Memorandum of Understanding (MoU) was a remarkable experience, blending the worlds of academia and music. The campus, known for its vibrant culture and commitment to excellence, provided the perfect backdrop for this significant occasion. As we formalized our collaboration, the atmosphere was filled with excitement and promise, echoing the harmonious synergy we aim to achieve together.</p>
        <p class="mb-lg-2">The ceremony was more than just a formal agreement; it was a celebration of shared goals and mutual respect. Faculty members and students alike gathered to witness this pivotal moment, their enthusiasm palpable as we outlined the future initiatives. The MoU signifies not just a partnership, but a shared vision of fostering talent and creating opportunities for students to excel in their chosen fields.</p>
        <p class="mb-lg-2"> Our visit to Nandha College was not only about signing papers but also about building relationships. The warm welcome we received and the insightful discussions we had with the college's leadership underscored the potential of our collaboration. As we move forward, we are excited about the innovative projects and joint ventures that this MoU will bring to life, enriching the educational landscape and empowering the next generation of leaders.</p>
    </div>
</section>
<section class="achievement-detail-gallery py-30 mb-3">
    <div class="container">
        <div class="title">
            <div class="h4">Media</div>
            <h2 class="mb-2">Our <span>Gallery</span> </h2>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="row">
                    <div class="column">
                        <picture>
                            <source type="image/webp" srcset="assets/images/course-listing/achievement-gallery1.webp">
                            <source type="image/jpg" srcset="assets/images/course-listing/achievement-gallery1.jpg">
                            <img loading="lazy" src="assets/images/course-listing/achievement-gallery1.jpg" width="306" height="210" alt="gallery3" class="img-fluid w-100">
                        </picture>
                    </div>
                    <div class="column">
                        <picture>
                            <source type="image/webp" srcset="assets/images/course-listing/achievement-gallery2.webp">
                            <source type="image/jpg" srcset="assets/images/course-listing/achievement-gallery2.jpg">
                            <img loading="lazy" src="assets/images/course-listing/achievement-gallery2.jpg" width="306" height="210" alt="gallery3" class="img-fluid w-100">
                        </picture>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="column-full">
                    <picture>
                        <source type="image/webp" srcset="assets/images/course-listing/achievement-gallery3.webp">
                        <source type="image/jpg" srcset="assets/images/course-listing/achievement-gallery3.jpg">
                        <img loading="lazy" src="assets/images/course-listing/achievement-gallery3.jpg" width="636" height="450" alt="gallery3" class="img-fluid w-100">
                    </picture>
                </div>
            </div>
            <div class="col-md-3">
                <div class="row">
                    <div class="column">
                        <picture>
                            <source type="image/webp" srcset="assets/images/course-listing/achievement-gallery4.webp">
                            <source type="image/jpg" srcset="assets/images/course-listing/achievement-gallery4.jpg">
                            <img loading="lazy" src="assets/images/course-listing/achievement-gallery4.jpg" width="306" height="210" alt="gallery3" class="img-fluid w-100">
                        </picture>
                    </div>
                    <div class="column">
                        <picture>
                            <source type="image/webp" srcset="assets/images/course-listing/achievement-gallery5.webp">
                            <source type="image/jpg" srcset="assets/images/course-listing/achievement-gallery5.jpg">
                            <img loading="lazy" src="assets/images/course-listing/achievement-gallery5.jpg" width="306" height="210" alt="gallery3" class="img-fluid w-100">
                        </picture>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/achievement-gallery6.webp">
                    <source type="image/jpg" srcset="assets/images/course-listing/achievement-gallery6.jpg">
                    <img loading="lazy" src="assets/images/course-listing/achievement-gallery6.jpg" width="416" height="279" alt="gallery3" class="img-fluid w-100">
                </picture>
            </div>
            <div class="col-md-4">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/achievement-gallery7.webp">
                    <source type="image/jpg" srcset="assets/images/course-listing/achievement-gallery7.jpg">
                    <img loading="lazy" src="assets/images/course-listing/achievement-gallery7.jpg" width="416" height="279" alt="gallery3" class="img-fluid w-100">
                </picture>
            </div>
            <div class="col-md-4">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/achievement-gallery8.webp">
                    <source type="image/jpg" srcset="assets/images/course-listing/achievement-gallery8.jpg">
                    <img loading="lazy" src="assets/images/course-listing/achievement-gallery8.jpg" width="416" height="279" alt="gallery3" class="img-fluid w-100">
                </picture>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="column-full">
                    <picture>
                        <source type="image/webp" srcset="assets/images/course-listing/achievement-gallery9.webp">
                        <source type="image/jpg" srcset="assets/images/course-listing/achievement-gallery9.jpg">
                        <img loading="lazy" src="assets/images/course-listing/achievement-gallery9.jpg" width="636" height="450" alt="gallery3" class="img-fluid w-100">
                    </picture>
                </div>
            </div>
            <div class="col-md-3">
                <div class="row">
                    <div class="column">
                        <picture>
                            <source type="image/webp" srcset="assets/images/course-listing/achievement-gallery10.webp">
                            <source type="image/jpg" srcset="assets/images/course-listing/achievement-gallery10.jpg">
                            <img loading="lazy" src="assets/images/course-listing/achievement-gallery10.jpg" width="306" height="210" alt="gallery3" class="img-fluid w-100">
                        </picture>
                    </div>
                    <div class="column">
                        <picture>
                            <source type="image/webp" srcset="assets/images/course-listing/achievement-gallery11.webp">
                            <source type="image/jpg" srcset="assets/images/course-listing/achievement-gallery11.jpg">
                            <img loading="lazy" src="assets/images/course-listing/achievement-gallery11.jpg" width="306" height="210" alt="gallery3" class="img-fluid w-100">
                        </picture>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="row">
                    <div class="column">
                        <picture>
                            <source type="image/webp" srcset="assets/images/course-listing/achievement-gallery12.webp">
                            <source type="image/jpg" srcset="assets/images/course-listing/achievement-gallery12.jpg">
                            <img loading="lazy" src="assets/images/course-listing/achievement-gallery12.jpg" width="306" height="210" alt="gallery3" class="img-fluid w-100">
                        </picture>
                    </div>
                    <div class="column">
                        <picture>
                            <source type="image/webp" srcset="assets/images/course-listing/achievement-gallery13.webp">
                            <source type="image/jpg" srcset="assets/images/course-listing/achievement-gallery13.jpg">
                            <img loading="lazy" src="assets/images/course-listing/achievement-gallery13.jpg" width="306" height="210" alt="gallery3" class="img-fluid w-100">
                        </picture>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-new></app-footer-new>
