import {
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import AOS from 'aos';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from '../services/canonical.service';
@Component({
  selector: 'app-achivements',
  templateUrl: './achivements.component.html',
  styleUrls: ['./achivements.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AchivementsComponent implements OnInit {

  title = 'Celebrating Developer Success and Milestones';
  modalConfig: ModalOptions = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };
  show: boolean = false;
  fullScreen: boolean = true;
  template = ``;
  items!: GalleryItem[];
  showspinner: boolean = false;
  showspinnerPast: boolean = false;
  public eventsUpcoming: any[] = [];
  public eventsPast: any[] = [];


  events_upcoming = [
    {
      previewImg: 'assets/images/excel.png',
      PreviewAlt: 'Excel',
      partnerLogo: 'assets/images/logo-excel.png',
      eventDate: 'Fri, Mar 17, 11:30 AM',
      eventName: 'Decentralized Finance (DeFi)'
    },
    {
      previewImg: 'assets/images/krishna.png',
      PreviewAlt: 'krishna',
      partnerLogo: 'assets/images/logo-krishna.png',
      eventDate: 'Thu, Mar 23, 02:00 PM',
      eventName: 'Getting started with iOS Career'
    },
    {
      previewImg: 'assets/images/jansons.png',
      PreviewAlt: 'jansons',
      partnerLogo: 'assets/images/logo-jansons.png',
      eventDate: 'Tue, Apr 06, 10:30 AM',
      eventName: 'Artificial Intelligence in Testing'
    },
    {
      previewImg: 'assets/images/kg.png',
      PreviewAlt: 'kg',
      partnerLogo: 'assets/images/logo-kg.png',
      eventDate: 'Sat, Apr 15, 11:00 AM',
      eventName: 'Effective Email Marketing Techniques'
    },
    {
      previewImg: 'assets/images/excel.png',
      PreviewAlt: 'excel',
      partnerLogo: 'assets/images/logo-excel.png',
      eventDate: 'Fri, Mar 17, 11:30 AM',
      eventName: 'Decentralized Finance (DeFi)'
    },
    {
      previewImg: 'assets/images/krishna.png',
      PreviewAlt: 'krishna',
      partnerLogo: 'assets/images/logo-krishna.png',
      eventDate: 'Thu, Mar 23, 02:00 PM',
      eventName: 'Getting started with iOS Career'
    }
  ];
  events_pasts = [
    {
      previewImg: 'assets/images/excel.png',
      PreviewAlt: 'Excel',
      partnerLogo: 'assets/images/logo-excel.png',
      eventDate: 'Fri, Mar 17, 11:30 AM',
      eventName: 'Decentralized Finance (DeFi)'
    },
    {
      previewImg: 'assets/images/krishna.png',
      PreviewAlt: 'krishna',
      partnerLogo: 'assets/images/logo-krishna.png',
      eventDate: 'Thu, Mar 23, 02:00 PM',
      eventName: 'Getting started with iOS Career'
    },
    {
      previewImg: 'assets/images/jansons.png',
      PreviewAlt: 'jansons',
      partnerLogo: 'assets/images/logo-jansons.png',
      eventDate: 'Tue, Apr 06, 10:30 AM',
      eventName: 'Artificial Intelligence in Testing'
    },
    {
      previewImg: 'assets/images/kg.png',
      PreviewAlt: 'kg',
      partnerLogo: 'assets/images/logo-kg.png',
      eventDate: 'Sat, Apr 15, 11:00 AM',
      eventName: 'Effective Email Marketing Techniques'
    },
    {
      previewImg: 'assets/images/excel.png',
      PreviewAlt: 'excel',
      partnerLogo: 'assets/images/logo-excel.png',
      eventDate: 'Fri, Mar 17, 11:30 AM',
      eventName: 'Decentralized Finance (DeFi)'
    },
    {
      previewImg: 'assets/images/krishna.png',
      PreviewAlt: 'krishna',
      partnerLogo: 'assets/images/logo-krishna.png',
      eventDate: 'Thu, Mar 23, 02:00 PM',
      eventName: 'Getting started with iOS Career'
    }
  ];

  public contactmail!: FormGroup;
  submitted = false;
  countryCode: any;
  // country_code: any;
  // formdata = [];
    // Store subscription to manage it properly
    private submitFormSubscription: Subscription | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private toastr: ToastrService,
    public gallery: Gallery,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
  ) { }

  ngOnInit(): void {
    AOS.init();
    this.eventsUpcoming = this.events_upcoming;
    this.contactmail = this.formBuilder.group({
      name: ['', [Validators.required]],
      organization: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone_number: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      enquiry: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });

    this.items = data.map(item =>
      new ImageItem({ src: item.srcUrl, thumb: item.previewUrl })
    );

    // Load items into the lightbox
    this.basicLightboxExample();

    // Load item into different lightbox instance
    // With custom gallery config
    this.withCustomGalleryConfig();


    this.titleService.setTitle(this.title);
  this.meta.updateTag({ name: 'description', content: "Codespot is a nonprofit educational organization, that facilitates upskilling and learning opportunities for youths. As an educational foundation, we aim at providing technological skills for students, for which we host and conduct different levels of programs and training as a part of our Education empowerment. As a part of our Educational and skill development programs, we offer internships to upskill and hand the roots of technology to everyone!" });
  this.meta.updateTag({ name: 'keywords', content: 'Educational Foundation, Nonprofit Education Organization, Educational Initiatives, Education Support Programs, Learning Opportunities, Education Empowerment, Education Enrichment Programs, Education Awareness, Education and Skills Development, Education and Training Programs, technological skills for students' });
  this.meta.updateTag({ name: 'author', content: 'CodeSpot Foundation | Sparkouttech' });
  this.meta.updateTag({ name: 'robots', content: 'index, follow' });

  this.meta.updateTag({ property: 'og:title', content: 'Celebrating Developer Success and Milestones' });
  this.meta.updateTag({ property: 'og:site_name', content: 'CodeSpot Foundation | Sparkouttech' });
  this.meta.updateTag({ property: 'og:url', content: 'https://codespotfoundation.org/achievements/' });
  this.meta.updateTag({ property: 'og:description', content: "Codespot is a nonprofit educational organization, that facilitates upskilling and learning opportunities for youths. As an educational foundation, we aim at providing technological skills for students, for which we host and conduct different levels of programs and training as a part of our Education empowerment. As a part of our Educational and skill development programs, we offer internships to upskill and hand the roots of technology to everyone!" });
  this.meta.updateTag({ property: 'og:type', content: "Codespot is a nonprofit educational organization, that facilitates upskilling and learning opportunities for youths. As an educational foundation, we aim at providing technological skills for students, for which we host and conduct different levels of programs and training as a part of our Education empowerment. As a part of our Educational and skill development programs, we offer internships to upskill and hand the roots of technology to everyone!" });
  this.meta.updateTag({ property: 'og:image', content: 'https://www.codespotfoundation.org/assets/images/codespot-og.png' });

  this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
  this.meta.updateTag({ name: 'twitter:site', content: 'https://codespotfoundation.org/achievements/' });
  this.meta.updateTag({ name: 'twitter:title', content: 'Celebrating Developer Success and Milestones' });
  this.meta.updateTag({ name: 'twitter:description', content: "Codespot is a nonprofit educational organization, that facilitates upskilling and learning opportunities for youths. As an educational foundation, we aim at providing technological skills for students, for which we host and conduct different levels of programs and training as a part of our Education empowerment. As a part of our Educational and skill development programs, we offer internships to upskill and hand the roots of technology to everyone!" });
  this.meta.updateTag({ name: 'twitter:image', content: 'https://www.codespotfoundation.org/assets/images/codespot-og.png' });

  // Add Canonical tag
  this.canonicalService.setCanonicalURL("https://codespotfoundation.org/achievements/");



  }

  /**
   * Stops video
   */
  public stopVideo() {
    var videos = document.querySelectorAll('iframe, video');
    Array.prototype.forEach.call(videos, function (video) {
      if (video.tagName.toLowerCase() === 'video') {
        video.pause([0]);
      } else {
        var src = video.src;
        video.src = src;
      }
    });
  }

  basicLightboxExample() {
    this.gallery.ref().load(this.items);
  }
  /**
   * Use custom gallery config with the lightbox
   */
  withCustomGalleryConfig() {

    // 2. Get a lightbox gallery ref
    const lightboxGalleryRef = this.gallery.ref('anotherLightbox');

    // (Optional) Set custom gallery config to this lightbox
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // 3. Load the items into the lightbox
    lightboxGalleryRef.load(this.items);
  }

  //easy to access form control value
  get f() {
    return this.contactmail.controls;
  }

  onSubmit() {
    //console.log(this.contactmail)
    this.submitted = true;
    if (this.contactmail.valid) {
      let formData = {
        ...this.contactmail.value,
        country_code: this.countryCode
      };

       // Unsubscribe if there's a previous subscription to prevent memory leaks
       if (this.submitFormSubscription) {
        this.submitFormSubscription.unsubscribe();
      }
      //console.log(formdata);
      this.submitFormSubscription = this.commonService.submitcontactform(formData).subscribe({
        next: (response: any) => {
          this.submitted = false;
          if ((response.status = ['SUCCESS'])) {
            this.toastr.success('Mail Send Successfully!', 'Success!');
            this.contactmail.reset();
          }
        },
        error: (error: any) => {
          this.submitted = false;
          this.toastr.error('Failed to send mail. Please try again later.','Error!');
        }
    });
    }
  }
  numericOnly(event: any) {
    let pattern = /^([0-9])$/;
    let result = pattern.test(event.key);
    return result;
  }

  ngOnDestroy() {
    // Clean up subscription to prevent memory leaks
    if (this.submitFormSubscription) {
      this.submitFormSubscription.unsubscribe();
    }
  }

  onClickUpcoming() {
    this.showspinner = true;
    this.eventsUpcoming = [];
    setTimeout(() => {
      this.eventsUpcoming = this.events_upcoming.slice();
      this.showspinner = false
    }, 3000);
  }
  onClickPast() {
    this.showspinnerPast = true;
    this.eventsPast = []
    setTimeout(() => {
      this.eventsPast = this.events_pasts.slice();
      this.showspinnerPast = false
    }, 3000);
  }
  

}

const data = [
  {
    srcUrl: 'assets/images/gallery1.png',
    previewUrl: 'assets/images/gallery1.png'
  },
  {
    srcUrl: 'assets/images/gallery2.png',
    previewUrl: 'assets/images/gallery2.png'
  },
  {
    srcUrl: 'assets/images/gallery3.png',
    previewUrl: 'assets/images/gallery3.png'
  },
  {
    srcUrl: 'assets/images/gallery4.png',
    previewUrl: 'assets/images/gallery4.png'
  },
  {
    srcUrl: 'assets/images/gallery5.png',
    previewUrl: 'assets/images/gallery5.png'
  },
  {
    srcUrl: 'assets/images/gallery6.png',
    previewUrl: 'assets/images/gallery6.png'
  },
  {
    srcUrl: 'assets/images/gallery7.png',
    previewUrl: 'assets/images/gallery7.png'
  },
  {
    srcUrl: 'assets/images/gallery8.png',
    previewUrl: 'assets/images/gallery8.png'
  },
  {
    srcUrl: 'assets/images/gallery9.png',
    previewUrl: 'assets/images/gallery9.png'
  }
];