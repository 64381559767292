import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadSyllabusService {

  constructor(private http: HttpClient) { }

  public getVerifyDownloadSyllabus(data: any) {
    return this.http.post(`${environment.API_BASE_URL}user/verify-otp`, data);

  }
  public createDownloadSyllabus(params: any) {
    return this.http.post(`${environment.API_BASE_URL}user`, params);

  }
}
