import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CourseListingComponent } from './course-listing/course-listing.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { CsAcedemicComponent } from './cs-acedemic/cs-acedemic.component';
import { AchivementsComponent } from './achivements/achivements.component';
import { AchivementsDetailComponent } from './achivements-detail/achivements-detail.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeNewComponent } from './home-new/home-new.component';
import { AboutUsComponent } from './about-us/about-us.component';

const routes: Routes = [
  { path:'', pathMatch: 'full', component:HomeNewComponent },
  { path:'home', component:HomeNewComponent },
  { path:'contact', component:ContactFormComponent },
  { path:'course-listing', component:CourseListingComponent },
  { path:'course-detail/:slug', component:CourseDetailComponent },
  { path:'cs-academy', component:CsAcedemicComponent },
  { path:'achievements', component:AchivementsComponent },
  { path:'achievement-detail', component:AchivementsDetailComponent },
  { path:'contact-us', component:ContactUsComponent },
  { path:'about-us', component:AboutUsComponent},
  { path:'**', pathMatch: 'full', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled',anchorScrolling:'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
