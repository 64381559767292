import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.css']
})
export class FooterNewComponent implements OnInit {
  subscriptionForm!:FormGroup
  constructor(private _fb : FormBuilder,private commonService:CommonService,private toastr : ToastrService){ }

  ngOnInit(): void {
    this.subscriptionForm = this._fb.group({
      email: ['', [Validators.required, Validators.email,Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ )]],

    })
  }
  
  /**
   * Handles the submission of the newsletter subscription form.
   * Subscribes the user to the newsletter using the email provided in the form.
   *
   * @returns {void}
   */
  onSubmit(){
    this.commonService.subscribeToNewsletter({email:this.subscriptionForm.value['email']}).subscribe({
      next:(response:any)=>{
        this.subscriptionForm.reset();
        this.toastr.success(response?.message);
      },
      error:(error:any)=>{
        console.log(error);
      }
    })
  }

  backtotop() {
    window.scrollTo(0, 0);
  }

}
