import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { FooterserviceService } from 'src/app/service/footerservice.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public launchAppUrl = environment.launchAppUrl;
  constructor(@Inject(DOCUMENT) private document:Document, private renderer:Renderer2, private footservice:FooterserviceService, private router: Router){}
  ngOnInit(): void {
  }


}

