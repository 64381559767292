import { Component, HostListener, ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import  AOS  from 'aos'; //AOS - 1


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  top:any;
  left:any;
  expand=false;



  @HostListener('document:click', ['$event'])
  onClick() {
     this.expand=true;
     setTimeout(() => {
      this.expand=false;
     }, 500)
 }

@HostListener('document:mousemove', ['$event'])
  onMousemove($event: { pageY: number; pageX: number; }) {
    this.top=($event.pageY - 10)+ "px";
    this.left= ($event.pageX - 10)+ "px";
 }

  title = 'codespot';
  ngOnInit() {
    AOS.init({disable: 'mobile'});
    AOS.refresh();
    this.loader();
  }

  show = false;
  fullScreen = true;
  template = ``;

  loader(){
    this.show = true;
    this.fullScreen = true;
    this.template = ``
    setTimeout(() => {
        this.show = false
    }, 2000);
  }

}


