<header>
  <nav class="navbar navbar-expand-lg navbar-dark" id="navbar_top">
    <div class="container d-flex align-items-center justify-content-between">
      <a class="navbar-brand m-0 p-0" [routerLink]="['/']">
        <img src="../assets/images/logo-white.svg" alt="Logo" width="148" height="58" class="img-fluid" loading="lazy" />
      </a>
      <a class="navbar-brand fixed-logo m-0 p-0" [routerLink]="['/']">
        <img src="../assets/images/logo.svg" alt="Logo" width="148" height="58" class="img-fluid" loading="lazy" />
      </a>
        <div class="collapse navbar-collapse" id="navbarsExample06">
          <ul class="navbar-nav main_menu">
            <li routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"><a [routerLink]="['/']" >Home</a></li>
            <li routerLinkActive="active-link"><a [routerLink]="['/course-listing']">Courses</a></li>
            <li routerLinkActive="active-link"><a [routerLink]="['/cs-academy']">CS Academy</a></li>
            <li routerLinkActive="active-link"><a [routerLink]="['/achievements']">Achievements</a></li>
            <li routerLinkActive="active-link"><a [routerLink]="['/about-us']">About Us</a></li>
            <li routerLinkActive="active-link"><a [routerLink]="['/contact-us']" class="request_btn">Contact us</a></li>
          </ul>
        </div>
  
         <!-- <div class="m-menu-btn"> -->
  
        <!-- <div class="d-flex justify-content-center align-items-center"> -->
          <!-- <a class="request_btn login-btn" href="https://codespotfoundation.org/app/user-sign-in" target="_blank">Login</a>
          <a class="request_btn" href="https://codespotfoundation.org/app/user-sign-up" target="_blank">Register </a>-->
          <a class="request_btn" [routerLink]="['/contact-us']">Contact us</a>
        <!-- </div> -->
  
  
        <!-- <div class="d-flex justify-content-center align-items-center gap-4">
          <a class="icon-admin"><img src="assets/images/icon-help.svg" alt="Help" /></a>
          <a class="icon-admin"><img src="assets/images/icon-notification.svg" alt="notification" /></a>
  
          <div class="dropdown user-profile-dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              LM
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
              <li class="d-flex align-items-start gap-4 justify-content-start profile-li">
                <div class="profile d-flex align-items-center justify-content-center">LM</div>
                <div class="d-flex flex-column">
                  <h4>Lokesh Murali</h4>
                  <p>lokeshmurali420@gmail.com</p>
                </div>
              </li>
              <li><a class="dropdown-item" href="https://codespotfoundation.org/app/user-profile"><img src="assets/images/icon-user.svg" alt="user" /> Profile</a></li>
              <li><a class="dropdown-item" href="https://codespotfoundation.org/app/user-profile/certification"><img src="assets/images/icon-diploma.svg" alt="diploma" /> Certificate</a></li>
              <li><a class="dropdown-item" href="https://codespotfoundation.org/app/your-events"><img src="assets/images/icon-invoice.svg" alt="user" /> Your Events</a></li>
              <li><a class="dropdown-item"><img src="assets/images/icon-notificationprofile.svg" alt="user" /> Notification</a></li>
              <li><a class="dropdown-item"><img src="assets/images/icon-clipboard.svg" alt="user" /> FAQ</a></li>
              <li><a class="dropdown-item" href="https://codespotfoundation.org/app/user-sign-in"><img src="assets/images/icon-logout.svg" alt="logout" /> Logout</a></li>
            </ul>
          </div>
  
        </div> -->
  
      <!-- </div> -->
      <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsExample06"
          aria-controls="navbarsExample06"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
      </button>
      </div>
  </nav>
  
</header>
  

<section class="main">
  <section class="banner-section  page-not-found">
    <div class="container">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-md-6 mb-3">
          <div class="banner_wrap" >
            <!-- <div class="star top-70">
              <img src="assets/images/star.png" alt="star" class="img-fluid animate-blink" />
            </div> -->
            <h2> 404 <br />
              <span>Page </span> Not Found
            </h2>
            <div class="d-flex justify-content-center">
              <a class="request_btn d-flex btn-style-one" [routerLink]="['/']">Back to Home <i class="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

<app-footer-new></app-footer-new>
</section>
