<app-header-new></app-header-new>
<section class="courselisting-banner course-detail-banner">
    <div class="container">
        <div class="mt-md-4 row align-items-center justify-content-between">
            <div class="col-md-9 col-lg-7">
                <div class="">
                    <div class="star top-70">
                      <picture>
                        <source type="image/webp" srcset="assets/images/star.webp">
                        <source type="image/png" srcset="assets/images/star.png">
                        <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                      </picture>
                    </div>
                    <h1 class="text-white"> Kickstart your journey to become IT Expert <span class="border-0">Today</span> </h1>
                    <p class="text-white pe-0"> Are you a beginner and looking to enhance your skills in the world of IT? Start mastering IT with Codespot's expert guidance and shine in your career. </p>
                    <div class="d-flex">
                        <div class="request_btn btn-style-one" (click)="highlightContactForm()">Enroll Now <i class="bi bi-arrow-right"></i>
                        </div>
                    </div>
                    <div class="star bottom-70">
                        <picture>
                          <source type="image/webp" srcset="assets/images/star.webp">
                          <source type="image/png" srcset="assets/images/star.png">
                          <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                        </picture>
                      </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="schedule-section">
    <div class="container">
        <div class="banner-bottom-section position-relative">
            <div class="schedule-wrapper row justify-content-end align-items-end">
                <div class="col-lg-4 bg-white p-3 p-md-4 interested-form ">
                    <div class="d-flex gap-1 flex-column">
                        <p class="p-0 mb-1">Don't Know Which Program to choose?</p>
                        <h2>Talk to our Experts</h2>
                    </div>


                    <form [formGroup]="contactMail" (ngSubmit)="createCSContactDetail()">
                        <div class="row gap-4 mt-4 pt-2">
                            <div class="col-12">
                                <input type="text" class="interest-form-control" placeholder="Name" formControlName="name" (focus)="onFocus('name')" (blur)="onBlur()">
                                <div *ngIf="contactMail. get('name')?.invalid && (contactMail.get ('name')?.dirty || contactMail.get('name') ?.touched) && focusedField !== 'name'" >
                                    <div *ngIf="contactMail.get('name') ?.errors?.['required']" class="text-danger">Name is required.</div>
                                    <div *ngIf="contactMail.get('name')?.errors?.['minlength']" class="text-danger">Name should be more than3 letters!</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <input type="email" class="interest-form-control" placeholder="Email address" formControlName="email" (focus)="onFocus('email')" (blur)="onBlur()">
                                <!-- <div *ngIf="contactMail. get('email')?.invalid && (contactMail.get ('email')?.dirty || contactMail.get('email') ?.touched)" >
                                    <div *ngIf="contactMail.get('email') ?.errors" class="text-danger">Email is required.</div>
                                </div> -->
                                <div *ngIf="contactMail. get('email')?.invalid && (contactMail.get ('email')?.dirty || contactMail.get('email') ?.touched) && focusedField !== 'email'" >
                                    <div *ngIf="contactMail.get('email') ?.hasError('required')" class="text-danger">Email is required.</div>
                                    <div *ngIf="contactMail.get('email')?.hasError('pattern') && contactMail.get('email')?.value && contactMail.get('email')?.invalid" class="text-danger">Invalid email format.</div>
                            </div>
                            </div>
                            <div class="col-12">
                                <input type="number" class="interest-form-control" placeholder="Mobile number" formControlName="phone_number" (focus)="onFocus('phone_number')" (blur)="onBlur()">
                                <div *ngIf="contactMail.get('phone_number')?.invalid && (contactMail.get('phone_number')?.dirty || contactMail.get('phone_number')?.touched) && focusedField !== 'phone_number'">
                                    <div *ngIf="contactMail.get('phone_number')?.errors?.['required']" class="text-danger">Phone Number is required.</div>
                                    <div *ngIf="contactMail.get('phone_number')?.errors?.['minlength']" class="text-danger">Phone number must be at least 10 digits.</div>
                                    <div *ngIf="contactMail.get('phone_number')?.hasError('pattern') && contactMail.get('phone_number')?.value && contactMail.get('phone_number')?.invalid"class="text-danger">Invalid phone number format.</div>
                                  </div>
                            </div>
                            <div class="col-12">
                                <label for="select-medium" class="fs-0 visually-hidden">Select Medium</label>
                                <select id="select-medium" class="interest-form-control bg-white " formControlName="medium">
                                    <option value="" disabled selected>Select Medium(Optional)</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="LinkedIn">LinkedIn</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="Twitter">Twitter</option>
                                    <option value="Social media ads">Social media ads</option>
                                    <option value="Refferals">Refferals</option>
                                </select>
                                <div *ngIf="contactMail. get('medium')?.invalid && (contactMail.get ('medium')?.dirty || contactMail.get('medium') ?.touched)" >
                                    <div *ngIf="contactMail.get('medium') ?.errors" class="text-danger">Medium is required.</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <!-- disable button is commented for now-->
                                 <button type="submit" *ngIf="!isProcessing" [disabled]="contactMail.invalid"
                                [ngClass]="{
                                  'opacity-25 cursor-not-allowed': contactMail.invalid ,
                                  'opacity-100': !contactMail.invalid }"class="border-0">Submit</button>
                                  <!-- <button type="submit" *ngIf="!isProcessing" [disabled]="contactMail.invalid"
                                  class="border-0">Submit</button>
                                  <button class="request_btn" *ngIf="isProcessing" >
                                    <svg aria-hidden="true" role="status" width="20px" class="inline  me-1 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                                  </svg>  Submit</button> -->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="cs-aboutus-section my-80 pt-90">
    <div class="container">
        <div class="row flex-lg-nowrap justify-content-between">
            <div class="col-lg-6 d-md-flex justify-content-md-between flex-md-column position-relative">
                <div class="star bottom-70">
                    <picture>
                        <source type="image/webp" srcset="assets/images/star.webp">
                        <source type="image/png" srcset="assets/images/star.png">
                        <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                    </picture>
                </div>
                <div class="title">
                    <div class="h4">About Us</div>
                    <h2> Become Master in Coding with Codespot <span>Experts </span>
                    </h2>
                    <p> Are you a beginner and looking to enhance your skills in the world of IT? Start mastering IT with Codespot's expert guidance and shine in your career. </p>
                </div>
                <div class="refundguarantee-wrap">
                    <div class="col-12 position-relative">
                        <h2>100% Refund Guarantee</h2>
                        <p>If selected or interested, pay the remaining amount to continue your course. If not, enjoy a 100% immediate refund for complete peace of mind.</p>
                        <!-- <div class="star top-70">
                            <picture>
                                <source type="image/webp" srcset="assets/images/star.webp">
                                <source type="image/png" srcset="assets/images/star.png">
                                <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                            </picture>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div>
                    <h3><span>Decide Your Focus</span> Choose Courses Aligned with Your Career Goals</h3>
                    <p>Fundamental technologies that match with your interests and goals should be identified and mastered. A solid foundation should be set for advanced skills development and career growth. </p>
                    <hr>
                </div>
                <div>
                    <h3><span>Latest Technology</span> Courses for All Levels of Coding Enthusiasts</h3>
                    <p>Courses in the latest technology are offered by CodeSpot experts to align with your interests and career goals. Discover and enroll in top-quality courses that enhances your skills and knowledge. </p>
                    <hr>
                </div>
                <div>
                    <h3><span>Hands-on experiences</span> and Active participation foster growth and practical skills.</h3>
                    <p>Gain hands-on experience by applying coding skills to real-world applications, enhancing your technical abilities. This practical approach deepens your understanding and prepares you for industry demands. </p>
                </div>

            </div>
        </div>
    </div>
</section>
<section class="listfoundation-section my-80">
    <div class="container">
        <div class="title">
            <div class="h4">Courses</div>
            <h2> List of <span>Foundation Courses </span>
            </h2>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 my-1 my-md-3" *ngFor="let data of listCourseValue">
                <div (click)="navigate('course-detail', data.slug)">
                    <picture>
                        <source type="image/webp"[srcset]="data.image_url">
                        <source type="image/jpg" [srcset]="data.image_url">
                        <img loading="lazy" [attr.data-src]="data.image_url_webp" alt="course Image" width="261" height="144" class="w-100 rounded-top">
                    </picture>
                </div>
                <div class="course-part">
                    <span class="d-block">3 Months</span>
                    <h3 (click)="navigate('course-detail', data.slug)">{{data.title}}</h3>
                    <ul class="p-0 m-0 d-flex  flex-column flex-lg-row justify-content-between">
                        <li class="d-flex align-items-center gap-1">
                            <img loading="lazy" src="assets/images/course-listing/icon-1.svg" alt="Blockchain Translate" width="20" height="20" class="d-inline-block">
                            <p class="p-0">Tamil & English</p>
                        </li>
                        <li class="d-flex align-items-center gap-1">
                            <img src="assets/images/course-listing/icon-2.svg" alt="Blockchain Enrolled" width="20" height="20" class="d-inline-block ">
                            <p class="p-0">{{ data.enroll_count }} Enrolled</p>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="keyhighlights-section my-80 pt-0">
    <div class="container">
        <div class="title">
            <div class="star center-70">
                <picture>
                  <source type="image/webp" srcset="assets/images/star.webp">
                  <source type="image/png" srcset="assets/images/star.png">
                  <img data-src="assets/images/star.png" width="35" height="35" alt="Star"
                    class="img-fluid animate-blink" loading="lazy">
                </picture>
            </div>
            <div class="h4">Offerings</div>
            <h2><span>Inbound </span>Program</h2>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 mb-md-4 keyhighlights-part">
                <div class="text-center">
                    <span class="text-center"><img loading="lazy" src="assets/images/course-listing/tailored-training.svg" width="66" height="66" alt="Inbound Tailored"></span>
                    <b>Tailored Training</b>
                    <p>We offer tailored training in all domains <br> aligned with our certification course</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-md-4 keyhighlights-part">
                <div class="text-center">
                    <span class="text-center"><img loading="lazy" src="assets/images/course-listing/website-material.svg" width="66" height="66" alt="Inbound Material"></span>
                    <b>Website Material</b>
                    <p>Reference Website Material <br> Document/Road Map.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-md-4 keyhighlights-part">
                <div class="text-center">
                    <span class="text-center"><img loading="lazy" src="assets/images/course-listing/task-assignment.svg" width="66" height="66" alt="Inbound Assignment"></span>
                    <b>Task Assignment</b>
                    <p>Tasks will be assigned upon the <br>completion of each topic. </p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 keyhighlights-part">
                <div class="text-center">
                    <span class="text-center"><img loading="lazy" src="assets/images/course-listing/certificate-awarded.svg" width="66" height="66" alt="Inbound Certificate"></span>
                    <b>Certificate Awarded</b>
                    <p>Receive an internship certificate upon <br>program completion and assessment.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 keyhighlights-part">
                <div class="text-center">
                    <span class="text-center"><img loading="lazy" src="assets/images/course-listing/expert-assistance.svg" width="66" height="66" alt="Inbound Expert"></span>
                    <b>Expert Assistance</b>
                    <p>Students will have access to experienced expert <br>who will provide guidance and support</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 keyhighlights-part">
                <div class="text-center">
                    <span class="text-center"><img loading="lazy" src="assets/images/course-listing/flexible-training.svg" width="66" height="66" alt="Inbound Flexible"></span>
                    <b>Flexible Training</b>
                    <p>Can opt for online or offline <br>training methods. </p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="ouralumni-section my-80">
    <div class="container">
        <div class="title">
            <div class="h4">Success Stories</div>
            <h2><span>Alumni </span> Students
            </h2>
        </div>

        <div class="row mt-3">
            <!-- <owl-carousel-o [options]="ourAlumni"> -->
                <div class="scroller__wrapper">
                    <div class="scroller__container">
                      <div class="scroller">
                          <div class="scroller__item" carouselSlide *ngFor="let data of listAlumniValue" width="200">
                            <div class="col-12" >
                                <div class="alumni-part" >
                                    <img loading="lazy" [srcset]="data.profile_url"  alt="Microsoft Alumni" width="90" height="90" class="rounded-circle">
                                    <p class="fw-semibold p-0">{{data.name}}</p>
                                    <span class="d-block">{{data.role}}</span>
                                    <img loading="lazy" [srcset]="data.company_url"  alt="Microsoft" width="95" height="23" class="company-logo">
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>

            <!-- </owl-carousel-o> -->
        </div>
    </div>
</section>
<section class="keyhighlights-section my-80">
    <div class="container">
        <div class="title">
            <div class="h4">Advantages</div>
            <h2> Benefits of <span>Codespot </span>Class</h2>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 mb-4 keyhighlights-part">
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/career-support.svg" width="66" height="66" alt="Career Credential"></span>
                    <b>Career Credential</b>
                    <p>Earn your course completion certificates that are valued and recognised by employers worldwide.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-4 keyhighlights-part">
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/coding-practices.svg" width="66" height="66" alt="Coding Practices"></span>
                    <b>Coding Practices</b>
                    <p>We will conduct regular code reviews to enhance code quality, performance and efficiency.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-4 keyhighlights-part">
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/mock-interviews.svg" width="66" height="66" alt="Mock Interviews"></span>
                    <b>Mock Interviews</b>
                    <p>We cover all the technical aspects, dress code and etiquette during the mock interview process. </p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 keyhighlights-part">
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/flexible-learning.svg" width="66" height="66" alt="Flexible Learning"></span>
                    <b>Flexible Learning</b>
                    <p>Choose your own pace of learning online, offline or hybrid available on weekdays or weekends.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 keyhighlights-part">
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/real-time-projects.svg" width="66" height="66" alt="Projects"></span>
                    <b>Real Time Projects</b>
                    <p>Hands-on practice in real-time projects for practical skill application and enhanced exposure.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 keyhighlights-part">
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/career-guidance.svg" width="66" height="66" alt="Guidance"></span>
                    <b>Career Guidance</b>
                    <p>Career guidance resume building, free mock interviews, interview prep, and network building. </p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 keyhighlights-part">
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/affordable-pricing.svg" width="66" height="66" alt="Pricing"></span>
                    <b>Affordable Pricing</b>
                    <p>Competitive pricing with flexible payment options & 100% refund if not satisfied with the practice session. </p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 keyhighlights-part">
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/comprehensive-curriculum.svg" width="66" height="66" alt="Comprehensive"></span>
                    <b>Comprehensive Curriculum</b>
                    <p>Continuous curriculum updates to match industry standards and technological advancements.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 keyhighlights-part">
                <div class="text-center">
                    <span class="d-inline-block text-center"><img loading="lazy" src="assets/images/course-listing/job-ready.svg" width="66" height="66" alt="Query"></span>
                    <b>Job Ready skills</b>
                    <p>We focus on developing technical and transferable skills to help you land your dream IT job.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="ourawards-section my-80">
    <div class="container">
        <div class="title">
            <div class="h4">Recognitions</div>
            <h2> Our <span>Awards & Achievements </span>
            </h2>
        </div>
        <div class="row">
            <owl-carousel-o [options]="ourAwards">
                <ng-template carouselSlide>
                    <div>
                        <a [routerLink]="['/achievement-detail']">
                            <picture>
                                <source type="image/webp" srcset="assets/images/course-listing/ourawards-1.webp">
                                <source type="image/jpg" srcset="assets/images/course-listing/ourawards-1.jpg">
                                <img loading="lazy" src="assets/images/course-listing/ourawards-1.jpg" width="418" height="210" alt="Outstanding Achievement">
                            </picture>
                        </a>
                        <p>April 17, 2020</p>
                        <div class="h4"><a [routerLink]="['/achievement-detail']">Outstanding achievement and innovation in education</a></div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div>
                        <a [routerLink]="['/achievement-detail']">
                            <picture>
                                <source type="image/webp" srcset="assets/images/course-listing/ourawards-2.webp">
                                <source type="image/jpg" srcset="assets/images/course-listing/ourawards-2.jpg">
                                <img loading="lazy" src="assets/images/course-listing/ourawards-2.jpg" width="418" height="210" alt="Honouring Exceptional">
                            </picture>
                        </a>
                        <div>
                            <p>April 17, 2020</p>
                            <div class="h4"><a [routerLink]="['/achievement-detail']">Honouring exceptional for Community Engagement</a></div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div>
                        <a [routerLink]="['/achievement-detail']">
                            <picture>
                                <source type="image/webp" srcset="assets/images/course-listing/ourawards-3.webp">
                                <source type="image/jpg" srcset="assets/images/course-listing/ourawards-3.jpg">
                                <img loading="lazy" src="assets/images/course-listing/ourawards-3.jpg" width="418" height="210" alt="Curriculum Development">
                            </picture>
                        </a>
                        <p>April 17, 2020</p>
                        <div class="h4"><a [routerLink]="['/achievement-detail']">Outstanding achievement in Curriculum Development</a></div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div>
                        <a [routerLink]="['/achievement-detail']">
                            <picture>
                                <source type="image/webp" srcset="assets/images/course-listing/ourawards-4.webp">
                                <source type="image/jpg" srcset="assets/images/course-listing/ourawards-4.jpg">
                                <img loading="lazy" src="assets/images/course-listing/ourawards-4.jpg" width="418" height="210" alt="Outstanding Achievement">
                            </picture>
                        </a>
                        <p>April 17, 2020</p>
                        <div class="h4"><a [routerLink]="['/achievement-detail']">Outstanding achievement and innovation in education</a></div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>
<section class="gallery-section my-80 pb-60">
    <div class="container">
        <div class="title">
            <div class="h4">Media</div>
            <h2> Our <span>Gallery </span></h2>
        </div>
        <div class="row mt-3">
            <div class="col-sm-6 col-md-6 mb-3 mb-md-4">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/gallery-1.webp">
                    <source type="image/jpg" srcset="assets/images/course-listing/gallery-1.jpg">
                    <img loading="lazy" src="assets/images/course-listing/gallery-1.jpg" width="636" height="261" alt="gallery1" class="img-fluid w-100">
                  </picture>
            </div>
            <div class="col-sm-6 col-md-3 mb-3 mb-md-4">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/gallery-2.webp">
                    <source type="image/jpg" srcset="assets/images/course-listing/gallery-2.jpg">
                    <img loading="lazy" src="assets/images/course-listing/gallery-2.jpg" width="306" height="261" alt="gallery2" class="img-fluid w-100">
                  </picture>
            </div>
            <div class="col-sm-6 col-md-3 mb-3 mb-md-4">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/gallery-3.webp">
                    <source type="image/jpg" srcset="assets/images/course-listing/gallery-3.jpg">
                    <img loading="lazy" src="assets/images/course-listing/gallery-3.jpg" width="306" height="261" alt="gallery3" class="img-fluid w-100">
                  </picture>
            </div>
            <div class="col-sm-6 col-md-3 mb-3 mb-md-4">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/gallery-4.webp">
                    <source type="image/jpg" srcset="assets/images/course-listing/gallery-4.jpg">
                    <img loading="lazy" src="assets/images/course-listing/gallery-4.jpg" width="306" height="261" alt="gallery4" class="img-fluid w-100">
                  </picture>
            </div>
            <div class="col-sm-6 col-md-6 mb-3 mb-md-4">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/gallery-7.webp">
                    <source type="image/jpg" srcset="assets/images/course-listing/gallery-7.jpg">
                    <img loading="lazy" src="assets/images/course-listing/gallery-7.jpg" width="636" height="261" alt="gallery5" class="img-fluid w-100">
                  </picture>
            </div>
            <div class="col-sm-6 col-md-3 mb-3 mb-md-4">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/gallery-8.webp">
                    <source type="image/jpg" srcset="assets/images/course-listing/gallery-8.jpg">
                    <img loading="lazy" src="assets/images/course-listing/gallery-8.jpg" width="306" height="261" alt="gallery6" class="img-fluid w-100">
                  </picture>
            </div>
            <div class="col-sm-6 col-md-3 mb-3">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/gallery-5.webp">
                    <source type="image/jpg" srcset="assets/images/course-listing/gallery-5.jpg">
                    <img loading="lazy" src="assets/images/course-listing/gallery-5.jpg" width="306" height="261" alt="gallery7" class="img-fluid w-100">
                  </picture>
            </div>
            <div class="col-sm-6 col-md-3 mb-3">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/gallery-6.webp">
                    <source type="image/jpg" srcset="assets/images/course-listing/gallery-6.jpg">
                    <img loading="lazy" src="assets/images/course-listing/gallery-6.jpg" width="306" height="261" alt="gallery8" class="img-fluid w-100">
                  </picture>
            </div>
            <div class="col-sm-6 col-md-6 mb-2">
                <picture>
                    <source type="image/webp" srcset="assets/images/course-listing/gallery-9.webp">
                    <source type="image/jpg" srcset="assets/images/course-listing/gallery-9.jpg">
                    <img loading="lazy" src="assets/images/course-listing/gallery-9.jpg" width="636" height="261" alt="gallery9" class="img-fluid w-100">
                  </picture>
            </div>
        </div>
    </div>
</section>
<app-footer-new></app-footer-new>
