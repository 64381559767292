import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.css']
})
export class HeaderNewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
