<app-header-new></app-header-new>
<section class="courselisting-banner">
    <div class="container">
      <div class="row align-items-end align-items-lg-center">
        <div class="col-md-7 position-relative">
          <div class="">
            <div class="star center-70">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <source type="image/png" srcset="assets/images/star.png">
                <img data-src="assets/images/star.png" width="35" height="35" alt="Star"
                  class="img-fluid animate-blink" loading="lazy">
              </picture>
            </div>
            <h1 class="text-white"> Building Tomorrow’s Leaders, Shaping Futures Through <span class="border-0">Education</span> </h1>
            <p class="text-white fs-6">Technical Education for All" is our motto. We are committed to empowering school and college students, as well as professionals, by upskilling them through our diverse programs, ensuring they thrive in the ever-evolving world of technology.
            </p> 
            <div class="star bottom-70 text-end">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <source type="image/png" srcset="assets/images/star.png">
                <img data-src="assets/images/star.png" width="35" height="35" alt="Star"
                  class="img-fluid animate-blink" loading="lazy">
              </picture>
            </div>
          </div>
        </div>
        <div class="col-md-5 position-relative text-center">
          <picture>
            <source type="image/webp" srcset="assets/images/course-listing/student.webp">
            <source type="image/png" srcset="assets/images/course-listing/student.png">
            <img src="assets/images/course-listing/student.png" width="439" height="479" alt="banner-pattern"
              class="img-fluid">
          </picture>
        </div>
      </div>
    </div>
  </section>
<section class="main">
    <section class="our-story pt-150" id="our-story">
        <div class="container">
          <div class="row">
            <div class="col-md-6 sm-pb-40">
              <div class="title">
                <div class="star top-70">
                  <picture>
                    <source type="image/svg" srcset="assets/images/star.svg">
                    <source type="image/png" srcset="assets/images/star.png">
                    <img src="assets/images/star.png" width="35" height="35" alt="Star"
                      class="img-fluid animate-blink" loading="lazy">
                  </picture>
                </div>
                <div class="h4 mt-3 mt-lg-0">Our Story</div>
                <h2> A Thought For <span>Change <br /> & Inclusion </span>
                </h2>
                <p> Our journey with Codespot Foundation started early on, when we
                  were searching for talent. We noticed a disconnect when we had to train freshers who we hired for various
                  domains. We understood that students find it hard to be employable due to the lack of awareness about the
                  IT Industry. Our initiative took birth from a desire to change this disconnect. </p>
              </div>
              <!-- <div class="d-flex" data-aos="fade-up" data-aos-delay="900"><a class="request_btn btn-style-one" href="#"
                  >Our Journey
                  <i class="bi bi-arrow-right"></i></a><a class="request_btn btn-style-two" href="#"
                  >See Our Podcasts <i class="bi bi-play-circle"></i
                ></a></div> -->
            </div>
            <div class="col-md-6">
              <div class="card d-flex flex-row align-items-center">
                <picture>
                  <source type="image/svg" srcset="assets/images/icon-lack-awareness.svg">
                  <!-- <source type="image/png" srcset="assets/images/icon-lack-awareness.png"> -->
                  <img src="assets/images/icon-lack-awareness.svg" width="50" height="50"
                    alt="Lack of awareness" class="img-fluid w-67 d-flex" loading="lazy">
                </picture>
                <!-- <img src="assets/images/icon-lack-awareness.png" alt="Lack of awareness" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                <div class="d-flex flex-column pl-25 wcalc50">
                  <h3>Lack of <span>awareness </span>
                  </h3>
                  <p> India is diverse and tech awareness still hasn’t trickled down to the grass-root level. </p>
                </div>
              </div>
              <div class="card d-flex flex-row align-items-center">
                <picture>
                  <source type="image/svg" srcset="assets/images/icon-knowledge.svg">
                  <!-- <source type="image/png" srcset="assets/images/icon-knowledge.png"> -->
                  <img src="assets/images/icon-knowledge.svg" width="50" height="50"
                    alt="Knowledge Disconnect" class="img-fluid w-67 d-flex" loading="lazy">
                </picture>
                <!-- <img src="assets/images/icon-knowledge.png" alt="Knowledge Disconnect" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                <div class="d-flex flex-column pl-25 wcalc50">
                  <h3>
                    <span>Knowledge </span> Disconnect
                  </h3>
                  <p> Students cannot learn contemporary skills through school curriculums alone. </p>
                </div>
              </div>
              <div class="card d-flex flex-row align-items-center">
                <picture>
                  <source type="image/svg" srcset="assets/images/icon-learn-exposure.svg">
                  <!-- <source type="image/png" srcset="assets/images/icon-learn-exposure.png"> -->
                  <img src="assets/images/icon-learn-exposure.svg" width="50" height="50"
                    alt="Learn of Exposure" class="img-fluid w-67 d-flex" loading="lazy">
                </picture>
                <!-- <img src="assets/images/icon-learn-exposure.png" alt="Learn of Exposure" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                <div class="d-flex flex-column pl-25 wcalc50">
                  <h3>
                    <span>Learn </span> of Exposure
                  </h3>
                  <p> Students do not have exposure to industry standards, organizational structures, and domains. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="mission-vision pt-150">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <figure class="sticky">
                <picture>
                  <source type="image/webp" srcset="assets/images/mission.webp">
                  <source type="image/png" srcset="assets/images/mission.png">
                  <img src="assets/images/mission.png" width="551" height="432" alt="mission"
                    class="img-fluid animate-float" loading="lazy">
                </picture>
                <!-- <img src="assets/images/mission.png" alt="mission" class="img-fluid" loading="lazy" /> -->
              </figure>
            </div>
            <div class="col-md-6">
              <div class="mission">
                <div class="title">
                  <div class="h4">Our Mission</div>
                  <h2> Your <span>destination</span> for <br /> success &
                    <span>creativity!</span>
                  </h2>
                </div>
                <p> To bridge the gap between industry skills and education for 53%
                  of Indian graduates who don’t have access to quality resources. </p>
                <p> To build a thriving talent pool in India for businesses to scale, grow and innovate in the competitive global market.</p>
                <p> To contribute to India’s rapid development in education,
                  technology, and infrastructure through universal inclusion. </p>
                <p> To accelerate this vision through Tamil Nadu, utilizing the
                  state’s rich educational infrastructure. </p>
              </div>
              <div class="mission vision">
                <div class="title">
                  <div class="h4">Our Vision</div>
                  <h2>To empower minds that shapes <span>the future</span>
                  </h2>
                </div>
                <p> Empowering students early by collaborating with various
                  educational institutions in India, starting with Tamil Nadu. </p>
                <p> Providing diverse opportunities for hands-on industry training and practical
                  placements. </p>
                <p> Inspire a generation of youngsters to continuosly strive for more and achieve greater heights. </p>
                <p> Encourage students to upskill with essential tools, empowering the next generation of leaders and change-makers.
                 </p>
              </div>
            </div>
          </div>
        </div>
      </section>
</section>
<app-footer-new />