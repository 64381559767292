<app-header></app-header>
<section class="main">
  <section class="contact pb-150 pt-150">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="title d-inline-block">
            <h2 data-aos="fade-up" data-aos-delay="200">
              Contact<span>.</span>
            </h2>
            <p class="pb-6" data-aos="fade-up" data-aos-delay="400">
              We equip students in under-resourced <br />
              high schools with the skills.
            </p>

            <div class="star">
              <img
                src="assets/images/star.png"
                alt="star"
                class="img-fluid animate-blink"
              />
            </div>
          </div>
          <figure class="d-flex" data-aos="fade-up" data-aos-delay="200">
            <img
              src="assets/images/contact.png"
              class="img-fluid contact-img"
              alt="Join our Team"
            />
          </figure>
        </div>
        <div class="col-lg-7">
          <div class="contact-form" data-aos="fade-up" data-aos-delay="200">
            <div class="getintouch getintochform">

              <form [formGroup]="contactmail" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        formControlName="name"
                        placeholder="Name"
                        [ngClass]="{
                          'is-invalid': submitted && f['name'].errors
                        }"
                      />
                      <div
                        *ngIf="submitted && f['name'].errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f['name'].errors['required']">
                          Name is required
                        </div>
                      </div>
                      <label for="floatingInput">Your Name</label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        formControlName="organization"
                        placeholder="Organization/Collage"
                        [ngClass]="{
                          'is-invalid': submitted && f['organization'].errors
                        }"
                      />
                      <div
                        *ngIf="submitted && f['organization'].errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f['organization'].errors['required']">
                          Organization/Collage is required
                        </div>
                      </div>
                      <label for="floatingInput">Organization/Collage</label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-floating">
                      <input
                        type="email"
                        class="form-control"
                        id="floatingemail"
                        placeholder="Email"
                        formControlName="email"
                        [ngClass]="{
                          'is-invalid': submitted && f['email'].errors
                        }"
                      />
                      <div
                        *ngIf="submitted && f['email'].errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f['email'].errors['required']">
                          Email is required
                        </div>
                        <div *ngIf="f['email'].errors['email']">
                          Email must be a valid email address
                        </div>
                      </div>
                      <label for="floatingemail">Email</label>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6">
                    <div class="form-floating">
                      <input
                        type="number"
                        placeholder="Phone Number"
                        (keypress)="numericOnly($event)"
                        id="floatingphone_number"
                        formControlName="phone_number"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid': submitted && f['phone_number'].errors
                        }"
                      />
                      <div
                        *ngIf="submitted && f['phone_number'].errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f['phone_number'].errors['required']">
                          Phone is required
                        </div>
                        <div *ngIf="f['phone_number'].errors['minlength']">
                          Phone must be at least 10 numbers.
                        </div>
                        <div *ngIf="f['phone_number'].errors['maxlength']">
                          Phone must be 10 numbers.
                        </div>
                      </div>
                      <label for="floatingphone_number">Phone Number</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-checkbox">
                      <h4>Enquiring for ?</h4>
                      <div class="d-flex justify-content-between">
                        <!-- <input
                    type="text" placeholder="Phone Number"
                   id="floatingenquiring"
                    formControlName="enquiring" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f['enquiring'].errors }"
                    />
                    <div *ngIf="submitted && f['enquiring'].errors"  class="invalid-feedback">
                      <div *ngIf="f['enquiring'].errors['required']">Phone is required</div>
                    </div> -->

                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            id="inlineRadio1"
                            value="option1"
                            name="inlineRadioOptions"
                            checked
                          />
                          <label class="form-check-label" for="inlineRadio1"
                            >Student</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            id="inlineRadio2"
                            value="option2"
                            name="inlineRadioOptions"
                          />
                          <label class="form-check-label" for="inlineRadio2"
                            >Fresher</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            id="inlineRadio3"
                            value="option3"
                            name="inlineRadioOptions"
                          />
                          <label class="form-check-label" for="inlineRadio3"
                            >Join us</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            id="inlineRadio4"
                            value="option4"
                            name="inlineRadioOptions"
                          />
                          <label class="form-check-label" for="inlineRadio4"
                            >Enquiry</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        placeholder="Your Message"
                        id="floatingTextarea2"
                        style="height: 70px"
                        formControlName="message"
                        [ngClass]="{
                          'is-invalid': submitted && f['message'].errors
                        }"
                      ></textarea>
                      <div
                        *ngIf="submitted && f['message'].errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f['message'].errors['required']">
                          Message is required
                        </div>
                      </div>
                      <label for="floatingTextarea2">Your Message</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="text-right">
                      <button class="request_btn">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="container pb-150">
    <div class="row">
      <div class="col-lg-12">

  <form  [formGroup]="form" >
    <input type='text' style='display:none;' name='xnQsjsdp'
      value='9c5f03d3584d5f5d235381b7affd44f55478fd35ea7e734cc1e3f04e84058d09'>
    <input type='hidden' name='zc_gad' id='zc_gad' value=''>
    <input type='text' style='display:none;' name='xmIwtLD'
      value='3fb1343a6196a5912a5bd9fac1fd0138ed9a0d91444ca98f5a7e055f878f2bb1'>
    <input type='text' style='display:none;' name='actionType' value='TGVhZHM='>
    <input type='text' style='display:none;' name='returnURL' value='https&#x3a;&#x2f;&#x2f;www.sparkouttech.com&#x2f;'>

    <div class='zcwf_title' style='max-width: 600px;color: black;'>Sparkout Tech Website</div>
    <div class='zcwf_row'>
      <div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'><label for='Last_Name'>Name<span
            style='color:red;'>*</span></label></div>
      <div class='zcwf_col_fld'><input [ngClass]="{'is-invalid': submitted && formControlls['last_name'].errors}" type='text' id='Last_Name' formControlName="last_name" name='Last Name' maxlength='80'>
        <span *ngIf="submitted && formControlls['last_name'].errors">Name is required</span>
        <div class='zcwf_col_help'></div>
      </div>
    </div>
    <div class='zcwf_row'>
      <div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'><label for='Email'>Email</label></div>
      <div class='zcwf_col_fld'><input type='text' ftype='email' id='Email' name='Email' formControlName="email" maxlength='100'>
        <div class='zcwf_col_help'></div>
      </div>
    </div>
    <div class='zcwf_row'>
      <div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'><label for='Phone'>Phone</label></div>
      <div class='zcwf_col_fld'><input type='text' id='Phone' name='Phone' formControlName="phone" maxlength='30'>
        <div class='zcwf_col_help'></div>
      </div>
    </div>
    <div class='zcwf_row'>
      <div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'><label for='Skype_ID'>Skype ID</label></div>
      <div class='zcwf_col_fld'><input type='text' id='Skype_ID' name='Skype ID' formControlName="skybe_id" maxlength='50'>
        <div class='zcwf_col_help'></div>
      </div>
    </div>
    <div class='zcwf_row'>
      <div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'><label for='Description'>Description</label>
      </div>
      <div class='zcwf_col_fld'><textarea id='Description' name='Description' formControlName="description"></textarea>
        <div class='zcwf_col_help'></div>
      </div>
    </div>
    <div class='zcwf_row'>
      <div class='zcwf_col_lab'></div>
      <div class='zcwf_col_fld'><input (click)="formSubmit()" id='formsubmit' class='formsubmit zcwf_button' value='Send&#x20;Now'
          title='Send&#x20;Now'><input type='reset' class='zcwf_button' name='reset' value='Reset' title='Reset'></div>
    </div>
  </form>
  </div>
  </div>
  </div>


  <!-- <iframe src="https://codepen.io/kuppusamysiva/pen/yLxKgEN" title="Zoho CRM form">
  </iframe> -->
  <iframe src="assets/zoho-crm.html" title="Zoho CRM form" width="100%" height="500" style="border:0;">
  </iframe>
  <app-footer></app-footer>
</section>
