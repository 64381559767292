<section class="footer-new">
    <span class="ellipse-1"></span>
    <span class="ellipse-2"></span>
    <div class="container">
        <div class="row justify-content-between position-relative">
            <div class="col-md-6 position-relative footer-left">
                <a class="footer-logo d-block" [routerLink]="['/']">
                    <img src="../assets/images/logo.svg" alt="Logo"  width="148" height="58" class="img-fluid" loading="lazy" />
                </a>
                <div class="star top-70">
                    <picture>
                      <source type="image/webp" srcset="../assets/images/star.webp">
                      <source type="image/png" srcset="../assets/images/star.png">
                      <img loading="lazy" src="../assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                    </picture>
                </div>
                <div class="col-12 col-md-10">
                    <span>By the way</span>
                    <h2>Subscribe for our news letters</h2>
                    <div class="position-relative">
                        <form [formGroup]="subscriptionForm">
                            <input type="text" placeholder="Enter mail to receive newsletters" formControlName="email" />
                            <div *ngIf="subscriptionForm. get('email')?.invalid && (subscriptionForm.get ('email')?.dirty || subscriptionForm.get('email') ?.touched)" >
                                <div *ngIf="subscriptionForm.get('email') ?.hasError('required')" class="text-danger">Email is required.</div>
                                <div *ngIf="subscriptionForm.get('email')?.hasError('pattern') && subscriptionForm.get('email')?.value && subscriptionForm.get('email')?.invalid" class="text-danger">Invalid email format.</div>
                            </div>
                            <button (click)="onSubmit()" aria-label="newsletters-button" [disabled]="subscriptionForm.invalid" class="border-0 px-3">
                                <i class="bi bi-arrow-right"></i>
                            </button>
                        </form>
                    </div>
                </div>
                <div class="star bottom-70">
                    <picture>
                      <source type="image/webp" srcset="../assets/images/star.webp">
                      <source type="image/png" srcset="../assets/images/star.png">
                      <img loading="lazy" src="../assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                    </picture>
                </div>
            </div>
            <div class="col-md-6 col-xl-5">
                <div class="row">
                    <div class="col-md-5 col-lg-6 quick-links">
                        <h3>Quick Links</h3>
                        <ul class="p-0 m-0">
                            <li routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"><a [routerLink]="['/']">Home</a></li>
                            <li routerLinkActive="active-linnk"><a [routerLink]="['/about-us']">About Us</a></li>
                            <li routerLinkActive="active-link"><a [routerLink]="['/cs-academy']">CS Academy</a></li>
                            <li routerLinkActive="active-link"><a [routerLink]="['/course-listing']">Courses </a></li>
                            <li routerLinkActive="active-link"><a [routerLink]="['/achievements']">Achievements</a></li>
                            <li routerLinkActive="active-link"><a [routerLink]="['/contact-us']">Contact us</a></li>
                        </ul>
                    </div>
                    <div class="col-md-7 col-lg-6 follow-us">
                        <h3>Follow us</h3>
                        <ul class="p-0 m-0 d-flex">
                            <li class="d-flex align-items-center justify-content-center rounded-circle"><a href="https://www.instagram.com/codespotorg/" target="_blank" aria-label="instagram"><img loading="lazy" src="../assets/images/course-listing/instagram.svg" alt="Instagram" width="20" height="20" class="img-fluid"></a></li>
                            <li class="d-flex align-items-center justify-content-center rounded-circle"><a href="https://www.linkedin.com/company/codespotorg" target="_blank" aria-label="linkedin"><img loading="lazy" src="../assets/images/course-listing/linkedin.svg" alt="Linkedin" width="20" height="20" class="img-fluid"></a></li>
                            <li class="d-flex align-items-center justify-content-center rounded-circle"><a href="https://www.facebook.com/codespotorg" target="_blank" aria-label="facebook"><img loading="lazy" src="../assets/images/course-listing/facebook.svg" alt="Facebook" width="20" height="20" class="img-fluid"> </a></li>
                            <li class="d-flex align-items-center justify-content-center rounded-circle"><a href="https://twitter.com/codespotorg" target="_blank" aria-label="twitter"><img loading="lazy" src="../assets/images/course-listing/twitter.svg" alt="Twitter" width="20" height="20" class="img-fluid"></a></li>
                        </ul>
                    </div>
                    <div class="col-12 position-relative foot-contact">
                        <div class="star top-70">
                            <picture>
                              <source type="image/webp" srcset="../assets/images/star.webp">
                              <source type="image/png" srcset="../assets/images/star.png">
                              <img loading="lazy" src="../assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                            </picture>
                        </div>
                        <h3>Contacts us</h3>
                        <p class="p-0">No. 3 Gem Colony, Near Siva Hospital, Ganapathy, Coimbatore, Tamil Nadu, India - 641 006.</p>
                        <a href="tel:8903820201">+91 89038 20201</a>
                        <a href="mailto:contact@codespotfoundation.org">contact&#64;codespotfoundation.org</a>
                    </div>
                    <div class="col-12 copy-sec">
                        <p class="p-0">Copyright © 2024 Codespot  | <a href="https://www.sparkouttech.com/" target="_blank">Sparkouttech</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-center rounded-circle backtop" (click)="backtotop()"><i class="bi bi-arrow-up-right"></i></div>
</section>
