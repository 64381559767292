import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnquiryService {

  constructor(private http:HttpClient) { }

  public createCsContact(params:any)
{
     return this.http.post(`${environment.API_BASE_URL}user/cs-contact`,params);

}
public createContactUs(params:any)
{
  return this.http.post(`${environment.API_BASE_URL}user/contact`,params);

 } 
 public createContact(params:any){
  return this.http.post(`${environment.API_BASE_URL}contact`,params);

 }
 public createCourseEnroll(params:any){
  return this.http.post(`${environment.API_BASE_URL}user/course-enroll`,params);

 }
}
