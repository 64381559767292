import { Component,OnInit} from '@angular/core';
import { FooterserviceService } from 'src/app/service/footerservice.service';
import  AOS  from 'aos';
import { FullscreenOverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
  isDarkmode:boolean=false;
  year:any;
  constructor(private footerservice:FooterserviceService){}
  ngOnInit(): void {
    AOS.init();
    this.footerservice.toggleLogoObservable.subscribe((response:boolean)=>{
      if(response){
          this.isDarkmode=response;
      }
      else{
          this.isDarkmode=response;
      }
    })
    let date=new Date();
    this.year=date.getFullYear();
  }
  scrollToTop() {
    window.scrollTo(0, 0);
  }
  
}
