import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSlickJsModule } from 'ngx-slickjs'
import { NgxLoaderModule } from '@tusharghoshbd/ngx-loader';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { NgxSpinnerModule } from "ngx-spinner";
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NotFoundComponent } from './not-found/not-found.component';
import { CourseListingComponent } from './course-listing/course-listing.component';
import { HeaderNewComponent } from './shared/header-new/header-new.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { FooterNewComponent } from './shared/footer-new/footer-new.component';
import { CsAcedemicComponent } from './cs-acedemic/cs-acedemic.component';
import { AchivementsComponent } from './achivements/achivements.component';
import { AchivementsDetailComponent } from './achivements-detail/achivements-detail.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeNewComponent } from './home-new/home-new.component';
import { CommonModule } from '@angular/common';
import { SafePipe } from './pipes/safe.pipe';
import { AboutUsComponent } from './about-us/about-us.component';
import { CurrencyPipe } from './pipes/currency.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ContactFormComponent,
    NewsletterComponent,
    NotFoundComponent,
    CourseListingComponent,
    HeaderNewComponent,
    CourseDetailComponent,
    FooterNewComponent,
    CsAcedemicComponent,
    AchivementsComponent,
    AchivementsDetailComponent,
    ContactUsComponent,
    HomeNewComponent,
    SafePipe,
    AboutUsComponent,
    CurrencyPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxLoaderModule,
    HttpClientModule,
    GalleryModule,
    LightboxModule,
    CarouselModule,
    CommonModule,
    NgxSpinnerModule.forRoot(),
    NgxSlickJsModule.forRoot({
      links: {
        jquery: "https://code.jquery.com/jquery-3.4.0.min.js",
        slickJs: "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js",
        slickCss: "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css",
        slickThemeCss: "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
        }
    }),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 2000,
      progressBar: true,
    }),
    ReactiveFormsModule,
    FormsModule,
    ToastrModule
],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
