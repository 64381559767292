import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HighlightService {
  constructor() { }
  private highlightSubject = new BehaviorSubject<boolean>(false);

  highlight$ = this.highlightSubject.asObservable();

  triggerHighlight() {
    this.highlightSubject.next(true);
  }

  clearHighlight() {
    this.highlightSubject.next(false);
  }
}
