<footer class="footer-top">
  <div class="f-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 align-content-between flex-wrap">
          <div class="footer-logo">
            <img src="assets/images/logo.svg" alt="logo" width="148" height="58" class="img-fluid" loading="lazy" />
          </div>
          <div class="f-description">
            <p>
              We equip students in under-resourced high schools with the skills,
              experiences, and connections that together create access to
              careers in technology. We equip students in under-resourced high
              schools with the skills, experiences, and connections that
              together create access.
            </p>

          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="newsletter-subscribe">
            <h3>
              Subscribe to our <span>Newsletter.</span>
            </h3>
            <div class="login_bar">
              <input
                type="email"
                placeholder="Enter mail to receive newsletters"
                class="login"
              />
              <span class="subscribe_button">Subscribe</span>
            </div>
          </div>
          <div class="social-links">
            <ul class="d-flex gap-6">
              <li>
                <a href="https://www.instagram.com/codespotorg/" target="_blank" aria-label="instagram"><i class="bi bi-instagram"></i></a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/codespotorg" target="_blank" aria-label="linkedin"><i class="bi bi-linkedin"></i></a>
              </li>
              <li>
                <a href="https://www.facebook.com/codespotorg" target="_blank" aria-label="facebook"><i class="bi bi-facebook"></i></a>
              </li>
              <li>
                <a href="https://twitter.com/codespotorg" target="_blank" aria-label="twitter"><i class="bi bi-twitter"></i></a>
              </li>
              <!-- <li>
                <a href="#"><i class="bi bi-github"></i></a>
              </li>
              <li>
                <a href="#"><i class="bi bi-google"></i></a>
              </li>
              <li>
                <a href="#"><i class="bi bi-pinterest"></i></a>
              </li>
              <li>
                <a href="#"><i class="bi bi-pinterest"></i></a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <!-- <div class="row d-lg-none">
          <div class="col-lg-12">
              <div class="copyright">
                  <p>Copyright © 2023 <span>Codespot  |  Sparkouttech</span></p>
              </div>
          </div>
      </div> -->
    </div>
  </div>
  <div class="footer-menu">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <ul class="d-flex align-items-center justify-content-between">
            <li><a [routerLink]="['/']">Home</a></li>
            <li><a href="#our-story">Our Story</a></li>
            <li><a href="#courses">Courses</a></li>
            <li><a href="#why-codespot">Why CodeSpot?</a></li>
            <li><a href="#contact">Contact us</a></li>
            <!-- <li><a href="#roadmap">Roadmap</a></li> -->
            <!-- <li><a href="#">Road map</a></li>
            <li><a href="#">Blog</a></li> -->
            <!-- <li><a href="#">Privacy Policy</a></li>
            <li><a href="#">Terms and Conditions</a></li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="copy-right">
    <div class="container">
      <div class="row">
        <div class="col-9">
          <p>Copyright © {{year}} <span>CodeSpot Foundation | <a href="https://www.sparkouttech.com/" target="_blank">Sparkouttech</a> </span></p>
        </div>
        <div class="col-3">
          <div class="d-flex justify-content-end scroll-top">
            <div class="scroller" (click)="scrollToTop()">
              Back to top <i class="bi bi-arrow-up-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
