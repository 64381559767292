import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {


  transform(value: number, currencyCode: string = '₹'): string {
    if (value !== null && value !== undefined) {
      return `${currencyCode} ${value.toString().replace(/,/g, '')}`;
    }
    return '';
  }

}
