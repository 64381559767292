import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private apiUrl = 'https://devapi.codespotfoundation.org/api/contact';
  // private Base_url=environment.Base_url
  constructor(private http:HttpClient) { }
  submitcontactform(formData: any){
      return this.http.post(this.apiUrl, formData);
  }
  
  /**
   * Subscribes a user to the newsletter using their email address.
   *
   * @param {{email: string}} payload - The payload containing the email address of the user.
   * @returns {Observable<any>} An observable of the HTTP response.
   */
  subscribeToNewsletter(payload:{email:string}){
    return this.http.post(`${environment.API_BASE_URL}user/subscribe`,payload);
  }
}
