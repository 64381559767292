import { Component, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CourseService } from '../services/course.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlumniService } from '../services/alumni.service';
import { TestimonialService } from '../services/testimonial.service';
import { DownloadSyllabusService } from '../services/download-syllabus.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EnquiryService } from '../services/enquiry.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HighlightService } from '../services/highlight.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css'],
})
export class CourseDetailComponent implements OnInit {
  downloadSyllabusForm!: FormGroup;
  syllabusOtpForm!: FormGroup;
  enquiryForm!: FormGroup;
  courseId!: string;
  slug! : string;
  isProcessing: boolean = false;
  syllabusOtPFormShow: boolean = false;
  downloadSyllabusFormShow: boolean = false;
  timeLeft: number = 30;
  interval: any;
  isLoading: boolean = false;
  timerText: string = '';
  isLinkDisabled: boolean = true;
  isLinkVisible: boolean = false;
  focusedField: string | null = null; 
  modalConfig: ModalOptions = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true,
  };
 
  videoId!: string ;
  currentVideoId!: any;
  @ViewChild('videoYT') player: any;

  listCourseValue: any;
  otpValue: any;
  listAlumniValue: any;
  listTestimonyValue: any;
  downloadSyllabusValue: any;
  enquiryValue: any;
  currentPage = 1;
  syllabusData!: any;
  resendButton: boolean = false;
  currentDeleteID: string = '';
  currentUrl!: any;
  showModal: boolean = false;
  isFormHighlighted: boolean = false;
  selctColorsecondary:string='#9CA3AF'

  private subscription!: Subscription;

  constructor(
    private courseService: CourseService,
    private activateRoute: ActivatedRoute,
    private alumniService: AlumniService,
    private testimonial: TestimonialService,
    private downloadSyllabus: DownloadSyllabusService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private enquiry: EnquiryService,
    private highlightService: HighlightService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    
   
  ) {}
  data: any;

  ngOnInit(): void {
    this.activateRoute.paramMap.subscribe((params) => {
      const slug = params.get('slug');
     
     
      if (slug) {
        this.getCourseDetails(slug);
      }
    });
    this.downloadSyllabusForm = this.fb.group({
      name: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
          ),
        ],
      ],
      phone_number: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(16),
          Validators.pattern(/^\d{10,16}$/),
        ],
      ],
    });
    this.enquiryForm = this.fb.group({
      name: ['', {
        validators: [Validators.required, Validators.minLength(3)],
        updateOn: 'blur'
      }],
      email: [
        '', {
          validators: [
            Validators.required,
            Validators.email,
            Validators.pattern(
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
            ),
          ],
          updateOn: 'blur'
        }
      ],
      phone_number: [
        '', {
          validators: [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(16),
            Validators.pattern(/^\d{10,16}$/),
          ],
          updateOn: 'blur'
        }
      ],
      medium: [''],
    });
    this.syllabusOtpForm = this.fb.group({
      otp: ['', Validators.required],
    });

    this.listAlumni();
    this.listTestimonial();
    // this.openModal(this.currentVideoId);
    // this.stopVideo(this.currentDeleteID)
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    if (firstScriptTag && firstScriptTag.parentNode) {
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      // Handle the case where the parent node is null
      console.error('Error: firstScriptTag.parentNode is null');
    }
    this.subscription = this.highlightService.highlight$.subscribe(
      (highlight) => {
        if (highlight) {
          this.isFormHighlighted = true;
          setTimeout(() => {
            this.isFormHighlighted = false;
            this.highlightService.clearHighlight();
          }, 3000); // Highlight for 3 seconds
        }
      }
    );
  
  }

   //easy to access form control value
  get f() {
    return this.enquiryForm.controls;
  }

  //for focussing the input field while form validation
  onFocus(fieldName: string){
    this.focusedField = fieldName;
  }

  onBlur() {
    this.focusedField = null;
  }

  /**
   * Gets you tube url
   * @param videoId
   */
  firstClick: any = 0;

  getYouTubeUrl(videoId: string) {
    this.currentVideoId = videoId;
    this.currentUrl = `https://www.youtube.com/embed/${videoId}`;
    //this.showModal = !this.showModal

    document.getElementById('videoYT')!.innerHTML = videoId;
    if (this.firstClick == 0) {
      setTimeout(() => {
        this.showModal = !this.showModal;
      }, 1900);
    } else {
      this.showModal = !this.showModal;
    }

    this.firstClick++;
  }

  /**
   * Stops video
   * @param data
   */
  stopVideo(data: any) {
    this.showModal = !this.showModal;
    this.currentUrl = '';
    // if (this.player) {
    //   this.player.nativeElement.contentWindow.postMessage('{"event":"pause"}', '*');
    // }
    // document.getElementById('videoYT')!.innerHTML = this.currentVideoId;
  }

  // Function to be called by the YouTube IFrame API (optional)
  onPlayerReady(event: any) {
    event.target.playVideo(); // You can autoplay here if desired
  }
  onIframeLoad() {
    const base64String =
      this.player.nativeElement.contentWindow.document.body.innerHTML;
    console.log(
      this.player.nativeElement.contentWindow.document.body.children[1]
        .currentSrc
    );
  }
  /**
   * after view checked
   */

  ngAfterViewChecked() {
    if (this.isLinkVisible) {
      const link = document.getElementById('resendLink');
      if (link) {
        link.onclick = (event) => {
          event.preventDefault();
          this.resendOtp();
        };
      }
    }
  }

  //ourAlumni owl carousel
  ourAlumni: OwlOptions = {
    loop: true,
    margin:30,
    autoplay: true,
    autoWidth:true,
    slideTransition: 'linear',
    autoplayTimeout: 0,
    autoplaySpeed: 6000,
    autoplayHoverPause:true,
    dots: false,
    // responsive: {
    //   0: {
    //     items: 1,
    //   },
    //   576: {
    //     items: 2,
    //   },
    //   740: {
    //     items: 4,
    //   },
    //   940: {
    //     items: 5,
    //   },
    // },
  };

  //video owl carousel
  customOptions: OwlOptions = {
    loop: true,
    margin: 20,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    nav: true,
    //navSpeed: 3000,
    navText: [
      '<i class="bi bi-arrow-left"></i>',
      '<i class="bi bi-arrow-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3,
      },
    },
  };

  //techStacks owl carousel
  techStacks: OwlOptions = {
    loop: false,
    rewind:true,
    margin: 100,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    autoWidth: false,
    dots: false,
    autoplay: true,
    nav: false,
    skip_validateItems: true,
    navSpeed: 3000,
    // navText: ['<i class="bi bi-arrow-left"></i>', '<i class="bi bi-arrow-right"></i>'],
    responsive: {
      0: {
        items: 2,
        margin: 20,
      },
      400: {
        items: 3,
        margin: 30,
      },
      570: {
        items: 4,
        margin: 50,
      },
      740: {
        items: 5,
        margin: 80,
      },
      940: {
        items: 6,
      },
    },
  };

  /**
   * Gets course details
   * @param id
   */
  public getCourseDetails(slug: string) {
    this.slug = slug;
    this.courseService.getCourseDetail(slug).subscribe({
      next: (response: any) => {
       
        
        this.listCourseValue = response.data;
        this.slug=this.listCourseValue.slug;
        this.courseId=this.listCourseValue._id;
       
        
      },
      error(error: any) {
        console.log(error);
      },
    });
  }

  /**
   * Lists alumni
   */
  public listAlumni() {
    this.alumniService.listAlumni().subscribe({
      next: (response: any) => {
        this.listAlumniValue = response.data;
      },
      error: (error: any) => {
        console.error('list alumni error', error.error.message);
      },
    });
  }

 
  
  /**
   * Removes content
   */
  removeContent() {
    console.log(this.player.nativeElement.contentWindow, 'Content');
    var iframeContent = document.getElementById('videoYT');
    let valueRemove = this.player.nativeElement?.contentWindow?.document;
    console;
    valueRemove.documentElement.innerHTML = '';
  }

  /**
   * Opens modal
   * @param data
   */
  openModal(data: any) {
    this.currentUrl = '';
    this.currentVideoId = data;
    this.getYouTubeUrl(this.currentVideoId);
    // this.modalConfig.show()
  }

  /**
   * Lists testimonial
   */
  public listTestimonial() {
    this.testimonial.listTestimonial(this.currentPage).subscribe({
      next: (response: any) => {
        this.listTestimonyValue = response.data.docs;
      },
      error: (error: any) => {},
    });
  }

  /**
   * Creates download syllabus
   */
  public createDownloadSyllabus() {
    this.isProcessing = true;
    this.syllabusOtPFormShow = false;
    const params = {
      name: this.downloadSyllabusForm.value.name,
      email: this.downloadSyllabusForm.value.email,
      phone_number: this.downloadSyllabusForm.value.phone_number,
      course_id: this.courseId,
    };

    this.downloadSyllabus.createDownloadSyllabus(params).subscribe({
      next: (response: any) => {
        this.isProcessing = false;
        this.startTimer();
        this.downloadSyllabusValue = response.data;
        this.syllabusData = response.data;
        this.syllabusOtPFormShow = true;

        this.toastr.success(response.message);
        this.downloadSyllabusForm.reset();
      },
      error: (err: any) => {
        this.isProcessing = false;
        this.toastr.error(err.error.message);
        this.downloadSyllabusForm.reset();
        this.syllabusOtPFormShow = false;
      },
    });
  }

  /**
   * Gets verify otp
   * @param id
   */
  public getVerifyOtp(data: any) {
    //data

    delete this.syllabusData.created_at;
    delete this.syllabusData.updated_at;
    const params = {
      ...this.syllabusData,
      otp: this.syllabusOtpForm.value.otp,
    };

    this.isProcessing = true;
    this.downloadSyllabus.getVerifyDownloadSyllabus(params).subscribe({
      next: (response: any) => {
        this.isProcessing = false;
        this.otpValue = response.data;
        document.getElementById('downloadSyllabus')?.click();
        if (this.listCourseValue.syllabus) {
          window.open(this.listCourseValue.syllabus, '_blank');
        }

        this.toastr.success('Syllabus Download Successfully');
        this.syllabusOtpForm.reset();
        this.syllabusOtPFormShow = false;
        this.downloadSyllabusFormShow = true;
      },

      error: (err: any) => {
        this.isProcessing = false;
        console.log(err);
      },
    });
  }

  /**
   * Creates course enroll
   */
  public createCourseEnroll() {
     
        
    this.isProcessing = true;
    const params = {
      name: this.enquiryForm.value.name,
      email: this.enquiryForm.value.email,
      phone_number: String(this.enquiryForm.value.phone_number),
      course_id: this.courseId,
    };

    this.enquiry.createCourseEnroll(params).subscribe({
      next: (response: any) => {
        
        
        this.isProcessing = false;
        this.enquiryValue = response.data;
        this.toastr.success('Enquiry Sent');

         this.enquiryForm.reset();
         this.enquiryForm.patchValue(
          {medium:''})

      },
      error: (err: any) => {
        this.isProcessing = false;
        this.toastr.error(err.error.message);
       
      },
    });
  }

  /**
   * Starts timer
   */

  startTimer() {
    this.resendButton = false;
    this.updateTimerText();
    this.interval = setInterval(() => {
      this.timeLeft--;
      this.updateTimerText();

      if (this.timeLeft <= 0) {
        this.resendButton = true;
        clearInterval(this.interval);
        this.isLinkVisible = true;
        this.timerText =
          'You can now <a href="#" id="resendLink">resend the OTP</a>';
      }
    }, 1000);
  }

  /**
   * Updates timer text
   */

  updateTimerText() {
    const minutes = Math.floor(this.timeLeft / 60);
    const seconds = this.timeLeft % 60;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    this.timerText = `Resend OTP in ${minutes}:${formattedSeconds}`;
  }
  /**
   * Resends otp
   */

  resendOtp() {
    this.syllabusOtPFormShow = false;
  }

  /**
   * Courses detail page top
   */
  courseDetailPageTop() {
    window.scrollTo(0, 0);
    this.highlightService.triggerHighlight();
  }

  /**
   * Scrolls to
   * @param targetId
   */
  scrollTo(targetId: string): void {
    document.getElementById(targetId)?.scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * Scrolls form to
   * @param targetId 
   */
  scrollFormTo(targetId: string): void {
    const element = document.getElementById(targetId);
    if (element) {
      const topOffset = 80; // Adjust this value as needed
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - topOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  /**
   * Determines whether select social on
   * @param event 
   */
  onSelectSocial(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const value = selectElement.value;
    this.selctColorsecondary = value ? '#000' : '#9CA3AF';
  }

}
