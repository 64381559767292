import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TestimonialService {

  constructor(private http:HttpClient) { }
  
  public listTestimonial(page:number)
  {
    return this.http.get(`${environment.API_BASE_URL}admin/testimonal?page=${page}`);
  }
  
}
