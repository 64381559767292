<app-header-new></app-header-new>
<section class="courselisting-banner achivements-banner">
    <div class="container">
        <div class="mt-md-4 row align-items-center justify-content-between">
            <div class="col-md-12">
                <div class="">
                    <div class="star top-70">
                      <picture>
                        <source type="image/webp" srcset="assets/images/star.webp">
                        <source type="image/png" srcset="assets/images/star.png">
                        <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                      </picture>
                    </div>
                    <h1 class="text-white"> <span class="border-0">Exploring Knowledge:</span> Insights, Stories <br> Resources for Lifelong Learners </h1>
                    <p class="text-white"> Welcome to our blog page where we delve into diverse topics and share insights. Discover compelling stories, useful resources, and enriching content for lifelong. </p>
                    <div class="star bottom-70">
                        <picture>
                          <source type="image/webp" srcset="assets/images/star.webp">
                          <source type="image/png" srcset="assets/images/star.png">
                          <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                        </picture>
                      </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="achivements-image">
    <div class="container">
        <picture>
            <source type="image/webp" srcset="assets/images/course-listing/achievements-image.webp">
            <source type="image/png" srcset="assets/images/course-listing/achievements-image.jpg">
            <img loading="lazy" src="assets/images/course-listing/achievements-image.jpg" alt="Achievements Banner" width="1296" height="673" class="w-100 img-fluid">
        </picture>
    </div>
</section>


<section class="ourawards-section achivements-section my-80">
    <div class="container">
        <div class="title">
            <div class="h4">Achivements</div>
            <h2> Accomplishments <span>Showcase </span>
            </h2>
            <div class="star top-70">
                <picture>
                  <source type="image/webp" srcset="assets/images/star.webp">
                  <source type="image/png" srcset="assets/images/star.png">
                  <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                </picture>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-4 mb-4 my-md-3">
                    <picture>
                        <source type="image/webp" srcset="assets/images/course-listing/ourawards-1.webp">
                        <source type="image/jpg" srcset="assets/images/course-listing/ourawards-1.jpg">
                        <img loading="lazy" src="assets/images/course-listing/ourawards-1.jpg" alt="Community Engagement" width="356" height="210" class="img-fluid">
                    </picture>
                <p>April 17, 2020</p>
                <div class="h4">Honouring exceptional for Community Engagement</div>
            </div>
            <div class="col-sm-6 col-md-4 mb-4 my-md-3">
                    <picture>
                        <source type="image/webp" srcset="assets/images/course-listing/ourawards-2.webp">
                        <source type="image/jpg" srcset="assets/images/course-listing/ourawards-2.jpg">
                        <img loading="lazy" src="assets/images/course-listing/ourawards-2.jpg" width="356" height="210" alt="Innovation Education">
                    </picture>
                <div>
                    <p>April 17, 2020</p>
                    <div class="h4">Outstanding achievement and innovation in education</div>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 mb-4 my-md-3">

                    <picture>
                        <source type="image/webp" srcset="assets/images/course-listing/ourawards-3.webp">
                        <source type="image/jpg" srcset="assets/images/course-listing/ourawards-3.jpg">
                        <img loading="lazy" src="assets/images/course-listing/ourawards-3.jpg" width="356" height="210" alt="Curriculum Developmen">
                    </picture>

                <p>April 17, 2020</p>
                <div class="h4">Achievement in Curriculum Development</div>
            </div>
            <div class="col-sm-6 col-md-4 mb-4 my-md-3">

                    <picture>
                        <source type="image/webp" srcset="assets/images/course-listing/ourawards-4.webp">
                        <source type="image/jpg" srcset="assets/images/course-listing/ourawards-4.jpg">
                        <img loading="lazy" src="assets/images/course-listing/ourawards-4.jpg" width="356" height="210" alt="Achievement Innovation">
                    </picture>

                <p>April 17, 2020</p>
                <div class="h4">Achievement and innovation in education</div>
            </div>
            <div class="col-sm-6 col-md-4 mb-4 my-md-3">

                    <picture>
                        <source type="image/webp" srcset="assets/images/course-listing/ourawards-5.webp">
                        <source type="image/jpg" srcset="assets/images/course-listing/ourawards-5.jpg">
                        <img src="assets/images/course-listing/ourawards-5.jpg" width="356" height="210" alt="Honouring Exceptional">
                    </picture>

                <p>April 17, 2020</p>
                <div class="h4">Honouring exceptional for Community Engagement</div>
            </div>
            <div class="col-sm-6 col-md-4 mb-4 my-md-3">

                    <picture>
                        <source type="image/webp" srcset="assets/images/course-listing/ourawards-6.webp">
                        <source type="image/jpg" srcset="assets/images/course-listing/ourawards-6.jpg">
                        <img src="assets/images/course-listing/ourawards-6.jpg" width="356" height="210" alt="Outstanding Achievement">
                    </picture>

                <p>April 17, 2020</p>
                <div class="h4">Outstanding achievement in Curriculum Development</div>
            </div>
            <div class="col-sm-6 col-md-4 mb-4 my-md-3">

                    <picture>
                        <source type="image/webp" srcset="assets/images/course-listing/ourawards-7.webp">
                        <source type="image/jpg" srcset="assets/images/course-listing/ourawards-7.jpg">
                        <img src="assets/images/course-listing/ourawards-7.jpg" width="356" height="210" alt="Outstanding Achievement">
                    </picture>

                <p>April 17, 2020</p>
                <div class="h4">Outstanding achievement and innovation in education</div>
            </div>
            <div class="col-sm-6 col-md-4 mb-4 my-md-3">

                    <picture>
                        <source type="image/webp" srcset="assets/images/course-listing/ourawards-8.webp">
                        <source type="image/jpg" srcset="assets/images/course-listing/ourawards-8.jpg">
                        <img src="assets/images/course-listing/ourawards-8.jpg" width="356" height="210" alt="Outstanding Achievement">
                    </picture>

                <p>April 17, 2020</p>
                <div class="h4">Outstanding achievement in Curriculum Development</div>
            </div>
            <div class="col-sm-6 col-md-4 mb-4 my-md-3">

                    <picture>
                        <source type="image/webp" srcset="assets/images/course-listing/ourawards-9.webp">
                        <source type="image/jpg" srcset="assets/images/course-listing/ourawards-9.jpg">
                        <img src="assets/images/course-listing/ourawards-9.jpg" width="356" height="210" alt="Community Engagement">
                    </picture>
                <p>April 17, 2020</p>
                <div class="h4">Exceptional for Community Engagement</div>
            </div>
        </div>
    </div>
</section>
<app-footer-new></app-footer-new>
